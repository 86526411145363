import { Checkbox, ScrollShadow, Autocomplete, AutocompleteItem } from "@heroui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import CheckableImage from "../../../../components/inputs/checkable-image";
import InnerImageZoom from 'react-inner-image-zoom';
import './zoom.css';

const SymbolSelector = ({symbols, setSymbols, legendImage}) => {

    const allSymbolsSelected = useMemo(() => {
      return Object.keys(symbols).length === Object.values(symbols).filter(s=>s.selected===true).length;
    }, [symbols]);

    const nbSelectedSymbols = useMemo(() => {
      return Object.values(symbols).filter(s=>s.selected===true).length;
    }, [symbols]);

    const handleCheckboxClick = useCallback((isSelected, id) => {
      setSymbols((prevState) => ({
        ...prevState,
        [id]: { ...prevState[id], selected: isSelected },
      }));
    }, [setSymbols]);

    const selectUnselect = useCallback((value) => {
      if (value) {
        setSymbols((prevState) => {
          return Object.values(prevState).reduce((obj, item, index) => {
            obj[item.id] = {
              ...item,
              selected: true
            };
            return obj;
          }, {});
        });
      } else {
        setSymbols((prevState) => Object.values(prevState).reduce((obj, item, index) => {
          obj[item.id] = {
            ...item,
            selected: false
          };
          return obj;
        }, {}));
      }
    }, [setSymbols]);

    const updateLabel = useCallback((value, id) => {
      setSymbols((prevState) => ({
        ...prevState,
        [id]: { ...prevState[id], name: value },
      }));
    }, [setSymbols]);

    const symbolDiv = useCallback((item) => {
      return (<div className="flex flex-row" key={item.id}>
        <div className="flex w-full gap-4 items-center">
          <CheckableImage
              src={item.imageUrl}
              alt={`Symbole`}
              isSelected={item.selected}
              onValueChange={(isSelected) => { handleCheckboxClick(isSelected, item.id) }}
              className="h-[5vw] w-[5vw]"
            />

            <Autocomplete
              key={item.id}
              aria-label="autocomplete"
              defaultItems={item.labels}
              allowsCustomValue={true}
              defaultInputValue={item.name}
              onInputChange={(value) => updateLabel(value, item.id)}
            >
              {(lab) => <AutocompleteItem key={lab.id} aria-label="autocomplete">{lab.value}</AutocompleteItem>}
            </Autocomplete>
        </div>
      </div>);
    }, [handleCheckboxClick, updateLabel]);

    const legendDiv = useMemo(() => {
      return <div className={`py-2 pr-6 flex flex-row justify-around overflow-hidden`}>
              <InnerImageZoom src={legendImage} zoomPreload={true} zoomType="click" zoomScale={1} className={`max-h-full`} moveType="drag"/>
          </div>
    }, [legendImage]);

    return (
      <div className="flex flex-row overflow-hidden">
        <div className={`flex flex-col px-4 w-full`}>
          <h3 className="text-center text-xl h3 flex-shrink-0">Légende</h3>
          {legendDiv}
        </div>
        <div className={`flex flex-col px-4 w-full`}>
          <h3 className="text-center text-xl h3 flex-shrink-0">Choisir les symboles</h3>
          <div className="mt-4 mx-auto flex flex-row justify-center gap-x-[10px] min-w-[50%] max-w-[400px] flex-shrink-0">
            <Checkbox color="danger" size="sm" defaultSelected={allSymbolsSelected} isSelected={allSymbolsSelected} onValueChange={(v) => selectUnselect(v)}>
              {allSymbolsSelected ? "Désélectionner tout" : "Sélectionner tout"}
            </Checkbox>
          </div>
          <ScrollShadow hideScrollBar className={`py-2 pr-6 w-full flex flex-row justify-around flex-grow`}>
            <div className={`flex flex-col gap-2`}>
              {Object.values(symbols).map(item => symbolDiv(item))}
            </div>
          </ScrollShadow>
          <div className="text-center h4 subtitle flex-shrink-0">{nbSelectedSymbols} {nbSelectedSymbols <= 1 ? ' symbole sélectionné' : 'symboles sélectionnés'}</div>
        </div>
      </div>
    );
  };

  

export default SymbolSelector;
