"use client";

import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Divider,
  cn,
  Spacer,
} from "@heroui/react";
import {Icon} from "@iconify/react";
import { DianeIcon } from "../../components/Icons";
import { scrollToServices } from ".";
import { useMsal } from "@azure/msal-react";
import { useEntraUser } from "../../EntraUserProvider";

// const menuItems = ["Services", "Solutions", "Qui sommes-nous", "Contact"];
const menuItems = ["Services", "Contact"];

const CenteredNavbar = React.forwardRef(
  ({classNames: {base, wrapper, ...otherClassNames} = {}, ...props}, ref) => {
    const { userRoles } = useEntraUser();

    const { instance } = useMsal();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const handleLogout = () => {
      // instance.logoutPopup({
      //     postLogoutRedirectUri: "/",
      //     mainWindowRedirectUri: "/"
      // });
      instance.logoutRedirect({});
  }
    return (
      <Navbar
        ref={ref}
        classNames={{
          base: cn(
            "max-w-fit mx-auto bg-default-foreground rounded-full",
            "px-4 pr-[18px] md:pr-1.5 py-[8px] top-12 shadow-[0_4px_15px_0_rgba(0,0,0,0.25)]",
            base,
          ),
          wrapper: cn("px-1 w-fit", wrapper),
          ...otherClassNames,
        }}
        height="40px"
        isMenuOpen={isMenuOpen}
        position="static"
        onMenuOpenChange={setIsMenuOpen}
        {...props}
      >
        <NavbarBrand>
          <div className="rounded-full ">
            <DianeIcon className="text-default-foreground m-2" size={34} />
          </div>
          <span className="ml-4 text-small font-medium text-background">DIANE</span>
        </NavbarBrand>

        <Spacer x={8} />

        <NavbarContent className="hidden md:flex" justify="center">
          <NavbarItem isActive className="data-[active='true']:font-medium[date-active='true'] cursor-pointer">
            <Link aria-current="page" className="text-background text-md" onPress={scrollToServices} size="sm">
              Services
            </Link>
          </NavbarItem>
          {/* <NavbarItem>
            <Link className="text-default-300 text-md" href="#" size="sm">
              Solutions
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="text-default-300 text-md" href="#" size="sm">
              Qui sommes-nous
            </Link>
          </NavbarItem> */}
          <NavbarItem>
            <Link className="text-default-300 text-md" href="/contact" size="sm">
              Contact
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="text-default-300 text-md" href="/cest_quoi_la_suite" size="sm">
              C'est quoi la suite ?
            </Link>
          </NavbarItem>
        </NavbarContent>

        <NavbarContent className="hidden md:flex" justify="end">
          <NavbarItem>
            <Button
              className="bg-background font-medium text-default-foreground"
              endContent={
                <Icon className="pointer-events-none" icon="solar:alt-arrow-right-linear" />
              }
              radius="full"
              onPress={handleLogout}
            >
              Déconnexion
            </Button>
          </NavbarItem>
        </NavbarContent>

        <NavbarMenuToggle className="text-default-400 md:hidden" />

        <NavbarMenu
          className="bottom-0 top-[initial] max-h-fit rounded-t-2xl bg-default-200/50 pb-6 pt-6 shadow-medium backdrop-blur-md backdrop-saturate-150"
          motionProps={{
            initial: {y: "100%"},
            animate: {y: 0},
            exit: {y: "100%"},
            transition: {type: "spring", bounce: 0, duration: 0.3},
          }}
        >

          <NavbarMenuItem  isActive className="data-[active='true']:font-medium[date-active='true'] cursor-pointer">
            <Link aria-current="page" className="mb-2 w-full text-default-500" onPress={scrollToServices} size="md">
              Services
            </Link>
            <Divider className="opacity-50" />
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link className="mb-2 w-full text-default-500" href="/contact" size="md">
              Contact
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link className="mb-2 w-full text-default-500" href="/cest_quoi_la_suite" size="md">
              C'est quoi la suite ?
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Button fullWidth onPress={() => setIsMenuOpen(false)} className="border-0 glass light">
              Fermer
            </Button>
          </NavbarMenuItem>
          <NavbarMenuItem className="mb-4">
            <Button fullWidth onPress={handleLogout} className="bg-foreground text-background">
              Déconnexion
            </Button>
          </NavbarMenuItem>
        </NavbarMenu>
      </Navbar>
    );
  },
);

CenteredNavbar.displayName = "CenteredNavbar";

export default CenteredNavbar;
