/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PDFEditor from "../../../../components/polygon-editor/pdf-editor";
import _ from 'underscore';
import { useDispatch, useSelector } from "react-redux";
import SideMenu, { Functions } from "./sideMenu";
import { CanvasContext, CanvasProvider } from "../../../../components/polygon-editor/contexts/canvas-context";
import { ZoneProvider } from "./provider";
import { DrawShapeProvider, useDrawShape } from "../../../../components/polygon-editor/contexts/draw-context";
import { ShapeType } from "../../../../components/polygon-editor/hooks/draw";
import { addZoneStore, changeZoneColorStore, removeZoneStore } from "../../../../store/features/Comptage/configuration";
import { setFillColor, setOpacity, setStrokeWidth } from "../../../../store/components/PolygonEditor/CanvasSlice";
import { fabric } from 'fabric';
import { LightColors } from "../color";
import { addObjectInLayer, setPagePointsCalibration } from "../../../../store/components/PolygonEditor/ImageSlice";
import YesNoAlert from "../../../../components/modals/AlertYesNo";
import { useOutletContext } from "react-router-dom";

function Zones () {
  const { page, setPage } = useOutletContext();
  fabric.Group.prototype.hasControls = false;
  fabric.Group.prototype.lockMovementX = false;
  fabric.Group.prototype.lockMovementY = false;
  fabric.Group.prototype.lockRotation = false;

  return (
    <CanvasProvider className="overflow-hidden h-[99vh]">
      <ZonesComponent
        page={page}
        setPage={setPage}
      />
    </CanvasProvider>
  );
};

export function ZonesComponent ({ page, setPage }) {
  const dispatch = useDispatch();
  const [activeFunction, setActiveFunction] = useState(Functions.NONE);
  const { angle, setMaxPoints } = useContext(CanvasContext);
  const nbZones = useSelector((state) => state.Comptage.Configuration.zones.filter(x =>x.page === page).length, _.isEqual);
  const scaleLayerId = useSelector((state) => {
    let scale = state.image.layers.find(x=>x.name === "scale_foreground_vpf");
    if (scale) {
      return scale.layerId;
    }
    else {
      return -1;
    }
  });
  const zoneLayerId = useSelector((state) => {
    let zone = state.image.layers.find(x=>x.name === "zone");
    if (zone) {
      return zone.layerId;
    }
    else {
      return -1;
    }
  });

  useEffect(() => {
    dispatch(setStrokeWidth(5));
  }, []);
  
  const addZone = useCallback((shape) => {
    dispatch(setFillColor(LightColors[(nbZones + 1) % LightColors.length].value));

    if (shape.isType("polyline")) {
      shape.selectable = false;
      shape.hasControls = false;
      shape.hasBorders = false;
      shape.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false
        });        
      shape.lockRotation = true;
      shape.lockMovementX = true;
      shape.lockMovementY = true;
      shape.lockScalingX = true;
      shape.lockScalingY = true;
      shape.layer = scaleLayerId;
      shape.calibration_line = true;
      setMaxPoints(Number.MAX_SAFE_INTEGER);

      let pts = shape.points;
      
      if (angle !== 0) {
        var [originX, originY] = [0, 0];

        pts = Array.from(pts).map(x=>{return {
            x: (x.x - originX) * Math.cos(-angle * Math.PI / 180) - (x.y - originY) * Math.sin(-angle * Math.PI / 180) + originX,
            y: (x.x - originX) * Math.sin(-angle * Math.PI / 180) + (x.y - originY) * Math.cos(-angle * Math.PI / 180) + originY
        }});
      }

      shape.stroke = "#000000";

      dispatch(addObjectInLayer({layerId: scaleLayerId, object: shape.id})); // Add the object's ID to Redux store
      dispatch(setPagePointsCalibration({
        id: shape.id,
        page: page,
        points: pts,
      }));
    } else{
      shape.selectable = true;
        shape.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            bl: false,
            br: false,
            tl: false,
            tr: false,
            mtr: false
          });        
        shape.lockRotation = true;
        shape.lockMovementX = true;
        shape.lockMovementY = true;
        shape.lockScalingX = true;
        shape.lockScalingY = true;
        shape.layer = zoneLayerId;

      let pts = (shape.isType("rect") ? [{
            x: shape.left,
            y: shape.top
        }, {
            x: shape.left + shape.width,
            y: shape.top
        }, {
            x: shape.left + shape.width,
            y: shape.top + shape.height
        }, {
            x: shape.left,
            y: shape.top + shape.height
        }] : (shape.isType("polygon") ? shape.points : []));

      
      if (angle !== 0) {
        // eslint-disable-next-line no-redeclare
        var [originX, originY] = [0, 0];

        pts = Array.from(pts).map(x=>{return {
            x: (x.x - originX) * Math.cos(-angle * Math.PI / 180) - (x.y - originY) * Math.sin(-angle * Math.PI / 180) + originX,
            y: (x.x - originX) * Math.sin(-angle * Math.PI / 180) + (x.y - originY) * Math.cos(-angle * Math.PI / 180) + originY
        }});
      }
      let zone = {
        "id": shape.id,
        "zone_id": shape.id,
        "page": page,
        "points": pts,
        "color": shape.fill,
        "name": `Zone${nbZones + 1}`
      };

      dispatch(addObjectInLayer({layerId: zoneLayerId, object: shape.id})); // Add the object's ID to Redux store
      dispatch(addZoneStore(zone));
    }
  }, [angle, dispatch, page, scaleLayerId, setMaxPoints, zoneLayerId, nbZones]);

  const removeZone = useCallback((shape) => {
    dispatch(removeZoneStore(shape.id));
  }, [dispatch]);

  return (
    <ZoneProvider
      addZone={addZone}
      removeZone={removeZone}
    >
      <DrawShapeProvider>
        <ZoneEditor
          page={page}
          setPage={setPage}
          activeFunction={activeFunction}
          setActiveFunction={setActiveFunction}
        />
      </DrawShapeProvider>
    </ZoneProvider>
  );
};

const ToolPanel = ({
  activeFunction,
  setActiveFunction,
  page
}) => {
  const dispatch = useDispatch();
  const [checkChangeName, setCheckChangeName] = useState(false);

  const currentFilePages = useSelector((state) => state.Comptage.Configuration.pages);
  const selectedPages = useMemo(() => {
    return currentFilePages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]);
  }, [currentFilePages]);

  const { canvasRef, setIsDrawing, setIsDeleting, setShapeType, getAllObjects, removeObjects, getObjectById } = useContext(CanvasContext);
  const { startDrawing, zoomObjects } = useDrawShape();
  const zones = useSelector((state) => state.Comptage.Configuration.zones);

  const addPolygonalZone = useCallback(() => {
    if (activeFunction === Functions.POLY) {
      setActiveFunction(Functions.NONE);
    } else {
      setActiveFunction(Functions.POLY);
      setIsDeleting(false);
      dispatch(setFillColor(LightColors[zones.length % LightColors.length].value));
      dispatch(setOpacity(.4));
      setShapeType(ShapeType.POLYGON);
      startDrawing();
  }
  }, [activeFunction, dispatch, setActiveFunction, setIsDeleting, setShapeType, startDrawing, zones.length]);

  const addRectangularZone = useCallback(() => {
    if (activeFunction === Functions.RECT) {
      setActiveFunction(Functions.NONE);
    } else {
      setActiveFunction(Functions.RECT);
      setIsDeleting(false);
      dispatch(setFillColor(LightColors[zones.length % LightColors.length].value));
      dispatch(setOpacity(.4));
      setShapeType(ShapeType.RECT);
      startDrawing();
    }
  }, [activeFunction, dispatch, setActiveFunction, setIsDeleting, setShapeType, startDrawing, zones.length]);

  const deleteZone = useCallback(() => {
    if (activeFunction === Functions.DELETE) {
      setActiveFunction(Functions.NONE);
    } else {
      setActiveFunction(Functions.DELETE);
      setIsDeleting(true);
    }
  }, [activeFunction, setActiveFunction, setIsDeleting]);
  
  const deleteAllZones = useCallback(() => {
    setActiveFunction(Functions.DELETE_ALL);
    YesNoAlert({
      title: "Supprimer",
      body: "Voulez-vous vraiment supprimer toutes les zones ?",
      onYes: () => {
        setIsDrawing(false);
        setIsDeleting(false);
        let objs = getAllObjects().filter(x=>!x.isBackground);
        objs.forEach(sh => {
          sh.definitiveDelete = true;
        });
        removeObjects(objs.map(x => x.id));
        setActiveFunction(Functions.NONE);
      },
      onNo: () => {
        setIsDrawing(false);
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      },
      onClose: () => {
        setIsDrawing(false);
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      }
    })
  }, [getAllObjects, removeObjects, setActiveFunction, setIsDeleting, setIsDrawing]);

  const zoomItem = useCallback((id) => {
    zoomObjects([id], 1.3);
  }, [zoomObjects]);

  const deleteItem = useCallback((id) => {
    let objs = getAllObjects().filter(x=>x.id === id);
    objs.forEach(sh => {
      sh.definitiveDelete = true;
    });
    removeObjects(objs.map(x => x.id));
  }, [getAllObjects, removeObjects]);

  const changeZoneColor = useCallback((id, newColor) => {
    let obj = getObjectById(id);
    if (obj) {
      obj.stroke = newColor;
      obj.fill = newColor;
    }
    canvasRef.current.renderAll();

    dispatch(changeZoneColorStore({id, newColor}));
  }, [canvasRef, dispatch, getObjectById]);

  return <div className="h-full top-0 absolute overflow-hidden right-0 z-50">
    <SideMenu
      activeFunction={activeFunction}
      setActiveFunction={setActiveFunction}
      addPolygonalZone={addPolygonalZone}
      addRectangularZone={addRectangularZone}
      deleteZone={deleteZone}
      deleteAllZones={deleteAllZones}
      zoomItem={zoomItem}
      deleteItem={deleteItem}
      changeZoneColor={changeZoneColor}
      page={page}
      selectedPages={selectedPages}
      checkChangeName={checkChangeName}
      setCheckChangeName={setCheckChangeName}
    />
  </div>
};

const ZoneEditor = ({ page, setPage, activeFunction, setActiveFunction }) => {
  const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
  const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
  const currentFileData = projectFiles.find(x =>x.name === currentFile);
  const currentFilePages = useSelector((state) => state.Comptage.Configuration.pages);
  const selectedPages = useMemo(() => {
    return currentFilePages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]);
  }, [currentFilePages]);

  const { canvasRef, setIsDrawing, setIsDeleting, setDrawMultipleShapes } = useContext(CanvasContext);
  const { insertPolygon } = useDrawShape();
  const zones = useSelector((state) => state.Comptage.Configuration.zones);
  const calibrationData = useSelector((state) => state.image.calibration?.find(c=>c.page === page) ?? undefined );

  const zoneLayerId = useSelector((state) => {
    let zone = state.image.layers.find(x=>x.name === "zone");
    if (zone) {
      return zone.layerId;
    }
    else {
      return -1;
    }
  });

  useEffect(() => {
    if ((activeFunction === Functions.POLY) || (activeFunction === Functions.RECT)) {
      setDrawMultipleShapes(true);
    } else if ((activeFunction === Functions.CALIBRATION) || (activeFunction === Functions.LINE_CALIBRATION)) {
      setDrawMultipleShapes(false);
    } else {
      setIsDrawing(false);
      if (canvasRef?.current) {
        canvasRef.current.defaultCursor = 'default'; // Reset cursor to default
        canvasRef.current.hovercursor = 'default';
      }
    }
    if (activeFunction !== Functions.DELETE) {
      setIsDeleting(false);
    }
  }, [activeFunction]);

  useEffect(() => {
    if ((activeFunction === Functions.CALIBRATION) || (activeFunction === Functions.LINE_CALIBRATION)) {
      setActiveFunction(Functions.NONE);
    }
  }, [calibrationData]);

  const restoreZones = useCallback((page) => {
    for(let zone of zones.filter(x=>x.page === page)) {
      let shape = insertPolygon(zone["points"], {
        id: zone["id"],
        zone_id: zone["zone_id"],
        stroke: zone["color"],
        fill: zone["color"],
        opacity: .4,
        selectable: true,
        strokeWidth: 2,
        objectCaching: false,
        visible: true,
        layer: zoneLayerId,
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true
      });
      
      shape.selectable = true;
      shape.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false
        });        
      shape.lockRotation = true;
      shape.lockMovementX = true;
      shape.lockMovementY = true;
      shape.lockScalingX = true;
      shape.lockScalingY = true;
    }
  }, [insertPolygon, zoneLayerId, zones.length]);

  const getPageImages = useCallback((p) => {
    return currentFilePages[p]["patches"];
  }, [currentFilePages]);

  return (
    <>
      <PDFEditor
        initialLayers={["0", "background", "zone"]}
        showScale={true}
        page={page}
        setPage={setPage}
        visiblePages={selectedPages}
        pageCount={currentFileData === undefined ? 1 : currentFileData.pages.length}
        getImages={getPageImages}
        restoreShapes={restoreZones}
        />
      <ToolPanel
        activeFunction={activeFunction}
        setActiveFunction={setActiveFunction}
        page={page}
      />
    </>
);
}

const MemoizedZones = React.memo(Zones);
export default MemoizedZones;