import { useCallback, useContext } from "react";
import { CanvasContext } from "../../../../components/polygon-editor/contexts/canvas-context";
import { CanvasEventContext } from "../../../../components/polygon-editor/contexts/canvas-event-context";

export const SymbolsProvider = ({ 
  addSymbol,
  removeSymbol,
  children
}) => {
    const onShapeAdded = useCallback((shape) => {
      if (shape && !shape.isBackground) {
        shape.bringToFront();
        addSymbol(shape);
      }
    }, [addSymbol]);
    
    const onShapeRemoved = useCallback((shape) => {
      if (shape && !shape.isBackground) {
        removeSymbol(shape);
      }
    }, [removeSymbol]);
    
    const onShapeSelected = useCallback((shape) => {
     
    }, []);
    
    const onShapeDeselected = useCallback((shape) => {

    }, []);
    
    const onShapeClicked = useCallback((shape) => {

    }, []);

    return (
      <CanvasEventContext.Provider
        value={{
            onShapeAdded,
            onShapeRemoved,
            onShapeSelected,
            onShapeDeselected,
            onShapeClicked
        }}
      >
        {children}
      </CanvasEventContext.Provider>
    );
  };