import React from "react";
import {Button} from "@heroui/react";
import {Icon} from "@iconify/react";

import { cn } from "../../../../utils/cn";
import VerticalSteps from "./vertical-steps";

import RowSteps from "./row-steps";
import MultistepNavigationButtons from "./multistep-navigation-buttons";
import Alert from "../../../../components/modals/Alert";

const stepperClasses = cn(
  // light
  "[--step-color:hsl(var(--heroui-secondary-400))]",
  "[--active-color:hsl(var(--heroui-secondary-400))]",
  "[--inactive-border-color:hsl(var(--heroui-secondary-200))]",
  "[--inactive-bar-color:hsl(var(--heroui-secondary-200))]",
  "[--inactive-color:hsl(var(--heroui-secondary-300))]",
  // dark
  "dark:[--step-color:rgba(255,255,255,0.1)]",
  "dark:[--active-color:hsl(var(--heroui-foreground-600))]",
  "dark:[--active-border-color:rgba(255,255,255,0.5)]",
  "dark:[--inactive-border-color:rgba(255,255,255,0.1)]",
  "dark:[--inactive-bar-color:rgba(255,255,255,0.1)]",
  "dark:[--inactive-color:rgba(255,255,255,0.2)]",
);

// background: -webkit-linear-gradient(0deg, #ffffff,#dedede,#ffe042,#ffe11f,#ffea00);/* Chrome 10-25, Safari 5.1-6 */                          background: linear-gradient(0deg, #ffffff,#dedede,#ffe042,#ffe11f,#ffea00);/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */                                             

const MultiStepSidebar = React.forwardRef(
  ({children, className, currentPage, onBack, onNext, onChangePage, allStatusGood, ...props}, ref) => {
    return (
      <div
        ref={ref}
        className={cn("flex h-full w-full gap-x-2", className)}
        {...props}
      >
        <div className="flex hidden h-full w-[344px] flex-shrink-0 flex-col items-start gap-y-8 rounded-large bg-gradient-to-b from-default-100 via-danger-100 to-secondary-100 px-8 py-6 shadow-small lg:flex">
          <Button
            className="bg-default-50 text-small font-medium text-default-500 shadow-lg"
            isDisabled={currentPage === 0}
            radius="full"
            variant="flat"
            onPress={onBack}
          >
            <Icon icon="solar:arrow-left-outline" width={18} />
            Retour
          </Button>
          <div>
            <div className="text-xl font-medium leading-7 text-default-foreground">
              Simulation
            </div>
            <div className="mt-1 text-base font-medium leading-6 text-default-500">
              Réalise le calcul d'éclairement à l'echelle de la ville 
            </div>
          </div>
          {/* Desktop Steps */}
          <VerticalSteps
            className={stepperClasses}
            color="secondary"
            currentStep={currentPage}
            getIsStepEnabled={(idx) => {
              if ( (idx !== 2) || (idx === 2 && allStatusGood !== undefined && allStatusGood === true)) {
                return true;
              }
              Alert("Fichiers luminaires", "Certains luminaires n'ont pas de fichier IES/LDT associé");
              return false;
            }}
            steps={[
              {
                title: "Choix de la zone de calculs",
                description: "Sélection de la zone sur laquelle le calcul sera réalisé",
              },
              {
                title: "Luminaires",
                description: "Aperçu des luminaires et de leurs fichiers photométriques associés",
              },
              // {
              //   title: "Voiries",
              //   description: "Aperçu des voiries contenues dans la zone de calculs",
              // },
              {
                title: "Résultat",
                description: "  ",
              },

            ]}
            onStepChange={onChangePage}
          />

        </div>
        <div className="flex h-full w-full flex-col items-center ">
          <div className="sticky top-0 z-10 w-full rounded-large bg-gradient-to-r from-default-100 via-danger-100 to-secondary-100 py-4 shadow-small md:max-w-xl lg:hidden">
            <div className="flex justify-center">
              {/* Mobile Steps */}
              <RowSteps
                className={cn("pl-6", stepperClasses)}
                currentStep={currentPage}
                steps={[
                  {
                    title: "Luminaires",
                  },
                  {
                    title: "Sélection",
                  },
                  // {
                  //   title: "Voirires",
                  // },
                  {
                    title: "Résultat",
                  },

                ]}
                onStepChange={onChangePage}
              />
            </div>
          </div>
          <div className="h-full w-full">
            {children}
            <MultistepNavigationButtons
              backButtonProps={{isDisabled: currentPage === 0}}
              className="lg:hidden"
              onBack={onBack}
              onNext={onNext}
              
            />

          </div>
        </div>
      </div>
    );
  },
);

MultiStepSidebar.displayName = "MultiStepSidebar";

export default MultiStepSidebar;
