import { Input } from '@heroui/react';
import React, { useCallback, useEffect, useState } from 'react';

const DelayedInput =  
  ({ externalValue, onInputChanged, delay=200, ref=null, ...props }) => {
  const [value, setValue] = useState(externalValue);
  const [timeoutId, setTimeoutId] = useState(null);

  // This useEffect causes the changes to disappear and then reappear when the redux store changes. If the user types while the text is disappearing, the text will be replaced.
  // But if we don't put it here, if the externalValue changes somewhere else and the component does not rerender, the value will not be updated.
  // useEffect(() => {
  //   setValue(externalValue)
  // }, [externalValue]);

  const handleValueChange = useCallback((newValue) => {
    setValue(newValue);
    
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      onInputChanged(newValue);
    }, delay); // Adjust delay as needed
    setTimeoutId(newTimeoutId);
  }, [timeoutId]);

  const handleBlur = useCallback((e) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Trigger your modify event here
    onInputChanged(value);
  }, [timeoutId, value]);

  return (
    <Input
      ref={ref}
      value={value}
      onValueChange={handleValueChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};

export default DelayedInput;