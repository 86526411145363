import { lazy } from "react";
import ProtectedRoute from "../components/login/protected-route";
import Intelia from "../pages/services/Intelia";

//routes services
const VEFTIDF = lazy(() => import("../pages/services/VEFTIDF"));
const VEFTIDFInstall = lazy(() => import("../pages/services/VEFTIDF_install"));
const C2C = lazy(() => import("../pages/services/C2C/index"));
const C2CAdmin = lazy(() => import("../pages/services/C2CAdmin"));

const Allysia = lazy(() => import("../pages/services/Allysia"));
const ApprobationUxello = lazy(() => import("../pages/services/ApprobationUxello"));
const SprinklerDB = lazy(() => import("../pages/services/SprinklerDB"));

const DevisRoutes = {
  path: "/",
  element: "",
  children: [
    {
      path: "/VEFTIDF",
      element: "",
      children: [
        {
          path: "/VEFTIDF/",
          element: (
            <ProtectedRoute roles={["Plateforme.Service8", "Plateforme.FullAccess"]}>
              <VEFTIDF />
            </ProtectedRoute>
          ),
        }, {
          path: "/VEFTIDF/admin",
          element: (
            <ProtectedRoute roles={["Plateforme.Service9", "Plateforme.FullAccess"]}>
              <VEFTIDFInstall />
            </ProtectedRoute>
          )
        }
      ]
      
    }, {
      path: "/C2C",
      element: "",
      children: [
        {
          path: "/C2C/",
          element: (
            <ProtectedRoute roles={["Plateforme.Service3", "Plateforme.FullAccess"]}>
              <C2C />
            </ProtectedRoute>
          ),
        },
        {
          path: "/C2C/admin",
          element: (
            <ProtectedRoute roles={["Plateforme.Service10", "Plateforme.FullAccess"]}>
              <C2CAdmin />
            </ProtectedRoute>
          ),
        }
      ]
    },
    {
      path: "/Allysia",
      element: (
        <ProtectedRoute roles={["Plateforme.Service19", "Plateforme.FullAccess"]}>
          <Allysia />
        </ProtectedRoute>
      ),
    },
    {
      path: "/ApprobationUxello",
      element: (
        <ProtectedRoute roles={["Plateforme.Service11", "Plateforme.FullAccess"]}>
          <ApprobationUxello />
        </ProtectedRoute>
      ),
    },
    {
      path: "/sprinkler_db",
      element: (
        <ProtectedRoute roles={["Plateforme.Service22", "Plateforme.FullAccess"]}>
          <SprinklerDB />
        </ProtectedRoute>
      ),
    },
    {
      path: "/Intelia",
      element: (
        <ProtectedRoute roles={["Plateforme.Service20", "Plateforme.FullAccess"]}>
          <Intelia />
        </ProtectedRoute>
      ),
    }
  ],
};
export default DevisRoutes;
