import { Button, Divider, Input } from "@heroui/react";
import { useState } from "react";
import Alert from "../../../components/modals/Alert";

export default function TicketForm({ ticketForm, setTicketForm, getTicket, getBPU, isGettingTicket }) {

    const [validationErrors, setValidationErrors] = useState({});

    const getValidationErrors = ( ticketForm ) => {
        let localValidationErrors = {};
        if (ticketForm.localTicketCode.length === 0) {
            localValidationErrors.localTicketCode = "Le numéro de DS est requis";
        }

        return localValidationErrors;
    }

    const handleInputChange = (name) => (e) => {
        setTicketForm((prev) => ({ ...prev, [name]: e.target.value }));
    };

    const handleInputClear = (name) => () => {
        setTicketForm((prev) => ({ ...prev, [name]: '' }));
    };


    const handleFetchTicket = async () => {
        const localValidationErrors = getValidationErrors(ticketForm);
        setValidationErrors(localValidationErrors);

        if (Object.keys(localValidationErrors).length > 0){
            Alert("Erreur", "Veuillez vérifier les champs du formulaire.");
            return;
        }

        const newTicket = await getTicket();
        if (!newTicket) return;
        console.log("New Ticket: ", newTicket);
    }

    return (
        <>
        <h2 className="text-2xl font-bold">Demande de Service</h2>
        <Divider />
        <div className="flex gap-4">
            {/* TicketCode Input */}
            <Input
                isRequired
                label="Numéro de DS"
                labelPlacement="outside"
                placeholder='X10400090086'
                value={ticketForm.localTicketCode}
                onChange={handleInputChange("localTicketCode")}
                onClear={handleInputClear("localTicketCode")}
                error={validationErrors?.localTicketCode}
                errorMessage={<span className="text-red-500 text-small">{validationErrors?.localTicketCode}</span>}
                size='md'
            />
        </div>
        <Button onPress={handleFetchTicket} variant='flat' isLoading={isGettingTicket}>
            {isGettingTicket ? "Recherche en cours ..." : "Récupérer la demande de service"}
        </Button>
        </>
    )
}