import { Icon } from "@iconify/react";
import { Button } from "@heroui/react";
import React, { useCallback, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import config from "../../../../config";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";

import EditableTable from "../../../../components/table/editable-table";
import { FilterTypeEnum } from "../../../../components/table/filters/filters-types";
import FiltersWrapper from "../../../../components/table/filters/filters-wrapper";
import DeleteModal from "../components/delete-modal";
import LeafletMap from "../map";
import { useSoluxMap } from "../side-bar/provider";

const statusColorMap = {
  good: "success",
  bad: "danger",
};
export default function FileLights() {
  const [filters, setFilters] = React.useState([]);
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const { launch_request } = useAzureFuncApp();
  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);
  const dispatch = useDispatch();

  const [showDeleteModal,setShowDeleteModal] = React.useState(false);
  const [wantDeleteFile, setWantDeleteFile] = React.useState(undefined);

  const lightsFiles= useSelector((state) => state.PYM.GeoData.city_lights_files);
  
  const { cityLightsData, setCityLightsData,roadsData, setRoadsData } = useSoluxMap();
  const handleOnValueChange = React.useCallback((item) => {
    const fichier = item.properties.fichier;
    let newItem = { ...item };
  
    let value = fichier == null ? "" : (typeof fichier === "string" ? fichier : (fichier.length > 0 ? fichier[0] : ""));
    let newStatus = (value === "" || value === "inconnu") ? "bad" : "good";
    newItem.properties = { ...newItem.properties, status: newStatus };

    return newItem;
  }, []); 

  const renderActionButton = React.useCallback((item) => {
    return (
      <div className="flex flex-row gap-1">
        <Button isIconOnly variant="light" onClick={() => { handleDeleteFile(item) }}>
          <Icon icon="solar:trash-bin-minimalistic-2-line-duotone" width={25} style={{ color: "#ff0000" }} />
        </Button>
      </div>
    )
  }, []);

  var initial_visible_columns = React.useMemo(() => { 
      return [
        {
          name: "Code",
          uid: "code",
          valueAccessor: (item) => item.properties.code,
          valueMutator: (item, value) => {
            let newItem = { ...item };
            newItem.properties = { ...newItem.properties, code: value };
            return newItem;
          },
          isEditable: false,
          type: 'text',
        },         
      {
          name: "X",
          uid: "x",
          valueAccessor: (item) => item.properties.x,
          valueMutator: (item, value) => {
            let newItem = { ...item };
            newItem.properties = { ...newItem.properties, x: value };
            return newItem;
          },
          isEditable: true,
          type: 'number',
        },
        {
          name: "Y",
          uid: "y",
          valueAccessor: (item) => item.properties.y,
          valueMutator: (item, value) => {
            let newItem = { ...item };
            newItem.properties = { ...newItem.properties, y: value };
            return newItem;
          },
          isEditable: true,
          type: 'number',
        },

        {
          name: "Marque",
          uid: "marque",
          valueAccessor: (item) => item.properties.marque,
          valueMutator: (item, value) => {
            let newItem = { ...item };
            newItem.properties = { ...newItem.properties, marque: value };
            return newItem;
          },
          isEditable: true,
          type: 'text',
        },
        {
          name: "Modèle",
          uid: "modele",
          valueAccessor: (item) => item.properties.modele,
          valueMutator: (item, value) => {
            let newItem = { ...item };
            newItem.properties = { ...newItem.properties, modele: value };
            return newItem;
          },
          isEditable: true,
          type: 'text',
        },
        {
          name: "Source",
          uid: "source",
          valueAccessor: (item) => item.properties.source,
          valueMutator: (item, value) => {
            let newItem = { ...item };
            newItem.properties = { ...newItem.properties, source: value };
            return newItem;
          },
          isEditable: true,
          type: 'text',
        },
        {
          name: "Fichier",
          uid: "fichier",
          valueAccessor: (item) => item.properties.fichier,
          valueMutator: (item, value) => {
            let newItem = { ...item };
            newItem.properties = { ...newItem.properties, fichier: value };
            return newItem;
          },
          isEditable: true,
          type: 'list',
          listItems: lightsFiles,
          onValueChange: (item) => handleOnValueChange(item)
        },
        {
          name: "Hauteur (m)",
          uid: "hauteur",
          valueAccessor: (item) => item.properties.hauteur,
          valueMutator: (item, value) => {
            let newItem = { ...item };
            newItem.properties = { ...newItem.properties, hauteur: value };
            return newItem;
          },
          isEditable: true,
          type: 'number',
        },
        {
          name: "Saillie (cm)",
          uid: "crosse_length",
          valueAccessor: (item) => item.properties.crosse_length,
          valueMutator: (item, value) => {
            let newItem = { ...item };
            newItem.properties = { ...newItem.properties, crosse_length: value };
            return newItem;
          },
          isEditable: true,
          type: 'number',
        },
        {
          name: "",
          uid: "action",
          isEditable: false,
          type: 'text',
          renderCell: renderActionButton
        }
  
      ];
    },[lightsFiles, handleOnValueChange, renderActionButton]);
    
    const handleSelectedLights = React.useCallback((idxSelected) =>{
      setFilters(() => {
        let newFilters = [];
  
        if (idxSelected.length > 0) {
          newFilters.push({
            key: "",
            values: idxSelected,
            type: FilterTypeEnum.IDInList
          });
        }
  
        return newFilters;
      });
    },[setFilters]);
      
  const filterDataItems =React.useMemo(() => {
        let manufacturers = cityLightsData.length === 0 ? [] : new Set(cityLightsData.map(item => item.properties.marque));
        let sources = cityLightsData.length === 0 ? [] : new Set(cityLightsData.map(item => item.properties.source));
        
        return [
          {
            type: FilterTypeEnum.TagGroup,
            key: "marque",
            title: "Marque",
            defaultOpen: true,
            options: Array.from(Array.from(manufacturers).map((manu) => {
              return {
                title: manu,
                value: manu,
                icon: "solar:lightbulb-bolt-line-duotone",
              }
              })
            ),
          },
          {
            type: FilterTypeEnum.TagGroup,
            key: "source",
            title: "Source",
            defaultOpen: true,
            options: Array.from(Array.from(sources).map((s) => {
              return {
                title: s,
                value: s,
                icon: "mage:light-bulb-electricity",
              }
              })
              ),
          },
        ];
      },
  [cityLightsData]);

  const deleteFile = useCallback(async (item) => {
    const payload = {
      functionName: "modifyLightsInput",
      filePath:projectInfos.project_path,
      action:"delete",
      item: item
    };
    setLoadingMessage("Suppression du point...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    setIsLoading(false);
    if (status_code === 200) {
      let newTableData = [...cityLightsData].filter(x => x.properties.code !== item.properties.code);
      setCityLightsData(newTableData);
    }
    setShowDeleteModal(false);
  }, [launch_request, projectInfos.project_path, setIsLoading, setLoadingMessage, cityLightsData, setCityLightsData]);


    const headerButtons = <>
          <Button isIconOnly onClick={() => { handleSaveLightFile() }} className='bg-[#80ed99]' ><Icon icon="fluent:save-24-regular" width={55} height={55} style={{ 'color': 'white' }} /></Button>
    </>

    async function handleSaveLightFile() {
      const payload = {
        functionName: "saveLights",
        projectPath: projectInfos.project_path || "",
        lights: cityLightsData,
      };
      console.log('payload',payload)
      setLoadingMessage("Sauvegarde des points lumineux ...");
      setIsLoading(true);
      let [status_code, result] = await launch_request(
        config.pymParseFile,
        payload
      );
      if (status_code === 200) {
        setIsLoading(false);
        return 
      }
      setIsLoading(false);
  
    }
  

  const handleDeleteFile = (item) => {
    setWantDeleteFile(item);
    setShowDeleteModal(true);
  }

  return (
    <div className="flex flex-row w-full h-full">
    <div className='flex flex-col'>
      <FiltersWrapper
        items={filterDataItems}
        setFilters={setFilters}
        showTitle={false}
        title="Voiries"
        className="overflow-y-auto"
      /> 
      <div className='max-h-[500px] min-h-[300px] w-full pl-[1.5rem] pb-[1.5rem]'>
        <LeafletMap 
          show_roads={false} 
          show_lights={true}
          show_selection_tool = {true}
          lightsData ={cityLightsData}          
          onSelection={handleSelectedLights}
          display_lights_mode='status'
        />
      </div>
    </div> 
        <EditableTable
            layout="fixed"
            columns={initial_visible_columns}
            data={cityLightsData}
            setData={(callback) => {
              let newData = callback(cityLightsData);
              setCityLightsData(rd => {
                return rd.map(r => {
                  const update = newData.find(u => u.id === r.id);
                  if (update) {
                      r = {...r, properties: { ...r.properties, ...update.properties }};
                  }
                  return r;
                });
              });
            }}
            pagination={true}
            // showTopRibbon={true}
            filters={filters}
            rowsPerPage={15} 
            headerButtons={headerButtons}
            />
      <DeleteModal isOpen={showDeleteModal} onConfirm={deleteFile} onDecline={() =>{setShowDeleteModal(false)}} itemToDelete={wantDeleteFile} buttonText="Êtes-vous sûr de vouloir supprimer ce point ?"/>

    </div>
  )
}