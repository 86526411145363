import { Icon } from '@iconify/react/dist/iconify.js';
import { Button } from "@heroui/react";
import React from 'react';

const UploadButton = ({ handleExcelExport, isLoading }) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.xlsm')) {
            handleExcelExport(file);
        } else {
            alert('Veuillez sélectionner un fichier .xlsm');
        }
    };

    return (
        <>
            <input
                type="file"
                accept=".xlsm"
                id="upload-input"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                />
            <Button
                onPress={() => document.getElementById('upload-input').click()}
                className="submit"
                variant='flat'
                isLoading={isLoading}
                startContent={ <Icon icon="gravity-ui:tag-dollar" className='scale-150'/> }
                >
                {isLoading ? 'Chargement des BPU (Cette opération peut prendre quelques minutes)' : 'Mettre à jour les BPU'}
            </Button>
        </>
    );
};

export default UploadButton;
