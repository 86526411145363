import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import styles from '../../../../components/polygon-editor/components/sideMenu/sideMenu.module.css';
import EditableTable from '../../../../components/table/editable-table';
import classNames from 'classnames';
import { Button, Input, Popover, PopoverContent, PopoverTrigger, Spacer, Tooltip } from "@heroui/react";
import {Icon} from "@iconify/react";
import { useSelector } from 'react-redux';
import { updateZones } from '../../../../store/features/Comptage/configuration';
import { HexColorPicker } from "react-colorful";
import { CanvasContext } from '../../../../components/polygon-editor/contexts/canvas-context';
import Calibration from '../../../../components/polygon-editor/calibration';

export const Functions = {
  NONE: 0,
  POLY: 1,
  RECT: 2,
  DELETE: 3,
  DELETE_ALL: 4,
  LINE_CALIBRATION: "line_calibration",
  CALIBRATION: "calibration"
};

export default function SideMenu({
  activeFunction,
  setActiveFunction,
  addPolygonalZone,
  addRectangularZone,
  deleteZone,
  deleteAllZones,
  zoomItem,
  deleteItem,
  changeZoneColor,
  page,
  selectedPages,
  checkChangeName,
  setCheckChangeName
}) {

  // State to manage whether the menu is opened or closed
  const [opened, setOpened] = useState(true);

  const zones = useSelector((state) => state.Comptage.Configuration.zones.filter(x =>x.page === page));
  const calibrationScale = useSelector((state) => state.image.calibration?.find(c=>c.page === page)?.scale ?? 0);
  const [data, setData] = useState(zones);
  const [filters, setFilters] = useState([]);
  const [checkChangeColor, setCheckChangeColor] = useState(false);
  const { setCurrentScale } = useContext(CanvasContext);
  const pagesNumbers = useMemo(() => {
    return [...selectedPages.map(p => p+1), 0];
  }, [selectedPages]);

  // State to manage whether the menu contents are visible or hidden
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (zones.length !== data.length || checkChangeColor || checkChangeName) {
      setCheckChangeColor(false);
      setCheckChangeName(false);
      setData(zones);
    }
  }, [zones.length, checkChangeColor, checkChangeName]);

  useEffect(() => {
    setCurrentScale(calibrationScale);
  }, [calibrationScale, setCurrentScale]);

  // Function to toggle the opened and visible states
  const toggleMenu = useCallback(() => {
    setOpened(!opened); // Toggle the opened state
    if (!visible) {
      setTimeout(() => setVisible(true), 300); // Delay showing menu contents for animation
    } else {
      setVisible(false); // Hide menu contents
    }
  }, [opened, visible]);

  const showCell = useCallback((item) => {
    return (<Tooltip color='danger' content="Voir sur le PDF" placement='top'>
        <span className="cursor-pointer"><Icon icon="tabler:zoom" onClick={() => {
          zoomItem(item.id);
        }} /></span>
    </Tooltip>)
  }, [zoomItem]);

  const colorCell = useCallback((item) => {
    return (
    <Popover placement='bottom' onClose={() => {
      setCheckChangeColor(true);
    }}>
      <PopoverTrigger>
        <Button isIconOnly className='shadow-lg rounded-full' style={{backgroundColor: item["color"]}}></Button>
      </PopoverTrigger>
      <PopoverContent>
        <HexColorPicker color={item["color"]} onChange={(newColor) => {
          changeZoneColor(item.id, newColor);
        }} />
      </PopoverContent>
    </Popover>
    );
  }, [setCheckChangeColor, changeZoneColor]);

  const deleteCell = useCallback((item) => {
    return (<Tooltip color='danger' content="Supprimer" placement='top'>
        <span className="cursor-pointer"><Icon onClick={()=>{
          deleteItem(item.id);
        }} icon="radix-icons:cross-2" color='danger'/></span>
    </Tooltip>)
  }, [deleteItem]);

  const columns = useMemo(() => [{
    uid: "show",
    name: "",
    sortable:false,
    renderCell: showCell
  }, {
    uid: "name",
    name: "Nom",
    type: "text",
    minwidth:200,
    width:200,
    isEditable : true,
    //renderCell: nameCell
  }, {
    uid: "color",
    name: "",
    sortable:false,
    renderCell: colorCell
  }, {
    uid: "delete",
    name: "",
    sortable:false,
    renderCell: deleteCell
  }], [colorCell, deleteCell, showCell]);

  return (
    <div className={classNames(styles.parent, opened ? styles.opened : styles.closed, "h-full")}>
      <div className='flex flex-row h-full'>
        {/* Button to toggle the menu */}
        <div className={styles.openButton} onClick={toggleMenu}>
          <div className={`${styles.arrowParent} bg-white/80`} style={{
            padding: "20px 0px 20px 5px",
            borderRadius: "10px 0px 0px 10px",
            marginTop: "50px",
            writingMode: "tb",
            width: "100%",
            backgroundColor: "#f2f2f2"
          }}>
            {/* Arrow icon to indicate open/close state */}
            <label className={`rotate-180 text-center cursor-pointer block ${opened ? "font-bold" : ""}`}>Zones</label>
          </div>
        </div>

        {/* Conditionally render menu contents based on the visible state */}
        <div style={{
          width: visible ? "300px" : "",
          padding: visible ? "" : "0",
          minWidth: visible ? "300px" : "",
          overflowY: "hidden",
          backgroundColor: "#f2f2f2"
        }} className='w-0 min-w-0 transition-all duration-700 linear h-full px-4 py-3'>
          <div className='flex flex-col h-full overflow-auto'>
            <div>
              <div className={`${styles.header} h4 pb-2`}>Ajouter des zones</div>
              <div className='grid grid-cols-2 gap-2'>
                <Tooltip placement='left' content="Sélection polygonale (Entrée ou double clic pour terminer)" color="danger">
                  <Button className='shadow-lg' onPress={addPolygonalZone} color={activeFunction === Functions.POLY ? "danger" : "default"}>
                    <Icon icon="geo:turf-explode" className='scale-150' />
                    Polygonale
                  </Button>
                </Tooltip>
                <Button className='shadow-lg p-1' onPress={addRectangularZone} color={activeFunction === Functions.RECT ? "danger" : "default"}>
                  <Icon icon="geo:turf-extent" className='scale-150' />
                  Rectangulaire
                </Button>
                <Button className='shadow-lg' onPress={deleteZone} color={activeFunction === Functions.DELETE ? "danger" : "default"}>
                  <Icon icon="jam:rubber" className='scale-[1.5] flex-5' />
                  <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer</span>
                </Button>
                <Button className='shadow-lg' onPress={deleteAllZones} color={activeFunction === Functions.DELETE_ALL ? "danger" : "default"}>
                  <Icon icon="material-symbols:delete-outline" className='scale-[1.5] flex-5' />
                  <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer tout</span>
                </Button>

              </div>
            </div>
            <Spacer y={4}/>
            <Calibration
              page={page}
              activeFunction={activeFunction}
              setActiveFunction={setActiveFunction}
              pagesNumbers={pagesNumbers}
            />
            <Spacer y={4}/>
              <div className={`${styles.header} h4`}>Zones</div>
              <div className='p-1 rounded-lg min-h-[300px] overflow-auto'>
                <EditableTable
                  data={data}
                  setData={setData}
                  filters={filters}
                  columns={columns}
                  showTopRibbon={false}
                  pagination={false}
                  removeWrapper
                  reduxTableSaveAction={updateZones}
                  className="overflow-y-auto"
                  tableBackground=""
                  classNames={{
                    td: "px-1",
                    th: "px-2"
                  }}
                />
              </div>
              <Spacer y={16}/>
          </div>
        </div>
      </div>
    </div>
  );
}