import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Input } from "@heroui/react";
import { useEffect, useState } from "react";

const DotInputNumber = ({ type, onValueChange, classNames, value, min, max, step, ...props }) => {
    let basicStyle = "bg-default-200/40 rounded-md w-8 flex-.5 align-middle text-center";
    let { updown } = classNames ?? {};
    const [textValue, setTextValue] = useState("");
    const regex = /^[0-9]*[\.,]?[0-9]*$/;

    useEffect(() => {
        setTextValue(value === undefined ? "" : value.toString());
    }, []);

    const handleChange = (e) => {
      const inputValue = e.target.value;
      // Check if the input value matches the regex
      if (regex.test(inputValue) || inputValue === "") {
        setTextValue(inputValue.replace(".", ","));
      }
    };

    function getDecimalPlaces(step) {
        const stepStr = step.toString().replace(",", ".");
        if (stepStr.includes('.')) {
            return stepStr.split('.')[1].length;
        }
        return 0;
    }

    function trimString(input) {
        if (input !== "") {
            // Remove leading zeros
            input = input.replace(/^[0]+/, '');

            // If the string starts with a comma or dot, prepend "0"
            if (/^[.,]/.test(input)) {
                input = "0" + input;
            }
        
            if (input.includes(',') || input.includes('.')) {
                // If the string has a comma, split the integer and decimal parts
                const [integerPart, decimalPart] = input.split(',');
                const trimmedDecimalPart = decimalPart.replace(/0+$/, ''); // Remove trailing zeros in the decimal part
                input = trimmedDecimalPart ? `${integerPart},${trimmedDecimalPart}` : integerPart;
            } else {
                // For cases without commas (pure numbers), remove trailing zeros after decimal point
                input = input.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, ''); // Remove trailing zeros after decimal point
            }

            if (input === "") {
                input = "0";
            }
        }

        return input;
    }
    
    const adjustValue = (delta) => {
        let newValue = parseFloat(textValue.replace(",", "."));
        if (newValue === undefined || Number.isNaN(newValue)) {
            newValue = min ?? 0;
        }
        newValue += delta;
        min = min ?? 0;
        if (min !== undefined && newValue < min) newValue = min;
        if (max !== undefined && newValue > max) newValue = max;
        let nbDecimals = getDecimalPlaces(step || 1);
        let newValueStr = trimString(newValue.toFixed(nbDecimals).replace(".", ","));
        setTextValue(newValueStr);
        onValueChange?.(newValue);
    };

    const onUpClick = () => adjustValue(step || 1);
    const onDownClick = () => adjustValue(-(step || 1));

    return <div className="w-full relative text-center align-middle">
        <Input
            type="text"
            value={textValue}
            onChange={handleChange}
            classNames={classNames}
            onBlur={()=>{adjustValue(0);}}
            {...props} />
        <div className="absolute right-0 top-0 flex flex-col gap-0.5 justify-center items-center cursor-pointer h-full">
            <Icon icon="uiw:up" className={`${basicStyle} ${updown}`} onClick={onUpClick}></Icon>
            <Icon icon="uiw:down" className={`${basicStyle} ${updown}`} onClick={onDownClick}></Icon>
        </div>
    </div>;
};

export default DotInputNumber;