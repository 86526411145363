import { Icon } from "@iconify/react";
import { Button } from "@heroui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import EditableTable from "../../../../components/table/editable-table";
import { FilterTypeEnum } from "../../../../components/table/filters/filters-types";
import FiltersWrapper from "../../../../components/table/filters/filters-wrapper";
import config from "../../../../config";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import { updateLightsFiles } from "../../../../store/features/PYM/GeoSlice";
import AddFileModal from './add-file-modal';
import DeleteModal from "../components/delete-modal";
import { filter } from "jszip";

export default function FileIesLdt() {
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const { launch_request } = useAzureFuncApp();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);

  const [fileList, setFileList] = React.useState([]);
  const [filters, setFilters] = useState([]);

  const [selectedFile, setSelectedFile] = React.useState(undefined);
  const [wantDeleteFile, setWantDeleteFile] = React.useState(undefined);

  const [showModal,setShowModal] = useState(false);
  const [showDeleteModal,setShowDeleteModal] = useState(false);

  useEffect(() => {
    getLightFiles().then((result) => {
      if (result !== undefined) {
        setFileList(result)
      }
    } 
  );
  }, [])

  const filterDataItems = useMemo(() => {
    let manufacturers = fileList.length === 0 ? [] : new Set(fileList.map(item => item.manufacturer));
    let temperatures = fileList.length === 0 ? [] : new Set(fileList.map(item => item.temperature));
    let powers = fileList.length === 0 ? [0,1] : fileList.map(item => parseFloat(item.power))
        return [
          {
            type: FilterTypeEnum.TagGroup,
            key: "manufacturer",
            title: "Marque",
            defaultOpen: true,
            options: Array.from(Array.from(manufacturers).map((manu) => {
              return {
                title: manu,
                value: manu,
                icon: "solar:lightbulb-bolt-line-duotone",
              }
              })
            ),
          },
          {
            type: FilterTypeEnum.TagGroup,
            key: "temperature",
            title: "Température",
            defaultOpen: true,
            options: Array.from(Array.from(temperatures).map( (temp) => {
              return {
                title: temp,
                value: temp,
                icon: "solar:temperature-line-duotone",
              }
              })
              ),
          },
          {
            title: "Puissance (W)",
            key :"power",
            defaultOpen:true,
            type: FilterTypeEnum.Range,
            showPips:false,
            range: {
              min: Math.min(...powers),
              defaultValue: [Math.min(...powers), Math.max(...powers)],
              max: Math.max(...powers),
              step: 1,
            }
          }
        ];
      },
  [fileList])

  var  initial_visible_columns = useMemo(() => { 
    return [
      {
        name: "Marque",
        uid: "manufacturer",
        valueAccessor: (item) => item.manufacturer,
        valueMutator: (item, value) => {
          return ;
        },

        isEditable: false,
        type: 'text',
      },
      {
        name: "Modèle",
        uid: "name",
        valueAccessor: (item) => item.name,
        valueMutator: (item, value) => {
          return ;
        },

        isEditable: false,
        type: 'text',

      },
      {
        name: "Température",
        uid: "temperature",
        valueAccessor: (item) => item.temperature,
        valueMutator: (item, value) => {
          return ;
        },

        isEditable: false,
        type: 'text',
      },
      {
        name: "Intensité (mA)",
        uid: "intensity",
        valueAccessor: (item) => item.intensity,
        valueMutator: (item, value) => {
          return ;
        },

        isEditable: false,
        type: 'text',
      },
      {
        name: "Puissance (W)",
        uid: "power",
        valueAccessor: (item) => parseFloat(item.power),
        valueMutator: (item, value) => {
          return ;
        },

        isEditable: false,
        type: 'number',
      },
      {
        name: "Flux lumineux (lm)",
        uid: "totalLuminousFlux",
        valueAccessor: (item) => parseFloat(item.totalLuminousFlux),
        valueMutator: (item, value) => {
          return ;
        },
        isEditable: false,
        type: 'number',
      },
      {
        name: "Nombre de lampes",
        uid: "lampNumber",
        valueAccessor: (item) => parseFloat(item.lampNumber),
        valueMutator: (item, value) => {
          return ;
        },
        isEditable: false,
        type: 'number',
      },
      {
        name: "Date / Personne en charge",
        uid: "issueDate",
        valueAccessor: (item) => item.issueDate,
        valueMutator: (item, value) => {
          return ;
        },

        isEditable: false,
        type: 'text',
      },

      {
        name: "",
        uid: "action",
        isEditable: false,
        type: 'text',
        renderCell: (item) => {
          return (
            <div className="flex flex-row gap-1">
              <Button isIconOnly variant="light" onClick={() => { handleDeleteFile(item) }}>
                <Icon icon="solar:trash-bin-minimalistic-2-line-duotone" width={25} style={{ color: "#ff0000" }} />
              </Button>
            </div>
          )
        }
      }

    ].filter(x => x.type !== "list" || (x.type === "list" && x.listItems.length > 0));
  }, []);

  async function handleConfirmModal(needMap,fileName,blobPath) {
    if(needMap){
      navigate('/solux-map/accueil', {
        state: { needPlot: true, plotPath:blobPath }
      });
      }
    else{
      const response = await fetch(blobPath);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}`
      link.click();
    }
  }

  const handleAddFile = (event) => {
    setShowModal(true);
  }

  const handleDeleteFile = (item) => {
    setWantDeleteFile(item);
    setShowDeleteModal(true);
  }

  const handleNotDeleteFile = (event) => {
    setShowDeleteModal(false);
  }

  async function getLightFiles() {
    const payload = {
      functionName: "getLightFiles",
      projectPath: projectInfos.project_path || "",
    };

    setLoadingMessage("Récupération des fichiers d'éclairage...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    setIsLoading(false);
    if (status_code === 200) {
      return result.filesList;
    }
  }

  async function addFilesInList(filenames) {
    const payload = {
      functionName: "addLightFiles",
      projectPath: projectInfos.project_path || "",
      blobPaths: filenames,
    };
    setLoadingMessage("Récupération des fichiers d'éclairage...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    setIsLoading(false);
    if (status_code === 200) {
      let files = result.filesList;
      if (selectedFile !== undefined) {
        setSelectedFile(undefined);
      }

      setFileList(fl => [...fl, ...files]);
      dispatch(updateLightsFiles([...fileList,...files].map(x=> x.filename)));
    }
  }

  async function deleteFile(item) {
    let blobPath = item.blobPath
    const payload = {
      functionName: "deleteLightFile",
      projectPath: projectInfos.project_path || "",
      blobPath: blobPath,
    };

    setLoadingMessage("Suppression du fichier...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    setIsLoading(false);
    if (status_code === 200) {
      if (selectedFile !== undefined && selectedFile.blobPath === blobPath) {
        setSelectedFile(undefined);
      }

      setFileList(fl => [...fl].filter(x => x.blobPath !== blobPath));
      dispatch(updateLightsFiles(fileList.filter(x => x.blobPath !== blobPath).map(x => x.filename)));

    }

    setShowDeleteModal(false);
  }

  return (
    <>
      <div className='flex flex-col'>
        <Button 
          className="m-4"
          onClick={handleAddFile}
        >
          Ajouter un fichier
        </Button>
        <FiltersWrapper
          items={filterDataItems}
          setFilters={setFilters}
          showTitle={false}
          title="Fichiers photométriques"
          className="overflow-y-auto"
        /> 
      </div> 
      <EditableTable
        pagination={true}
        columns={initial_visible_columns} 
        data={fileList}
        filters={filters}
        tableBackground='bg-white p-4'
        />
      <AddFileModal 
        isOpen={showModal}
        onConfirm = {handleConfirmModal}
        onDecline={()=>{setShowModal(false);}}
        blobPath={`${projectInfos.project_path}/inputs/light_files/`}
        addFilesInList={addFilesInList}/>
      <DeleteModal isOpen = {showDeleteModal} onConfirm = {deleteFile}  onDecline={handleNotDeleteFile} itemToDelete={wantDeleteFile} />
    </>)
}
