import { useDispatch, useSelector } from "react-redux";
import { CanvasContext } from "./contexts/canvas-context";
import { addLayer, setLayers } from "../../store/components/PolygonEditor/ImageSlice";
import ImageView from "./components/imagePreview/imageView";
import ReactPanZoom from "./components/pan-zoom-rotate-panel";
import React, { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useDrawShape } from "./contexts/draw-context";
import { v4 as uuidv4 } from 'uuid';
import CustomPagination from "../inputs/custom-pagination";

//https://github.com/n-mazaheri/image-editor


const PDFEditor = ({
  initialLayers = ["background"],
  page,
  setPage,
  toolsPanel=(<></>),
  visiblePages=undefined,
  pageCount=1,
  showScale=false,
  getImages=(page)=>{return [];},
  restoreShapes=(page)=>{},
  editorRef
}) => {
  const dispatch = useDispatch();
  const layernames = useSelector((state) => state.image.layers.map(x => x.name));
  const backgroundImages = useSelector((state) => {
    let background = state.image.layers.find(x=>x.name === "background");
    if (background) {
      return background.objects;
    }
    else {
      return [];
    }
  });
  const backgroundLayerId = useSelector((state) => {
    let background = state.image.layers.find(x=>x.name === "background");
    if (background) {
      return background.layerId;
    }
    else {
      return -1;
    }
  });

  const [oldPage, setOldPage] = useState(0);
  const { clearCanvas, currentScale, setDisplayScale, setAngle } = useContext(CanvasContext);
  const { insertImage, updateDisplayCalibration, zoomBackground } = useDrawShape();

  useImperativeHandle(editorRef, () => ({
    refreshPage() {
      if (page === 0) {
        return;
      }
      
      refreshPage();
    },
  }));


  useEffect(() => {
    zoomBackground();
  }, [JSON.stringify(backgroundImages)]);
  
  useEffect(() => {
    setDisplayScale(showScale);
    if (page < 1) {
      setPage(1);
    }
    dispatch(setLayers([]));
    if (initialLayers) {
      dispatch(addLayer("background"));
      if(showScale===true) {
        dispatch(addLayer("scale_foreground_vpf"));
      }

      for (let l of initialLayers) {
        dispatch(addLayer(l));
      }
    }
  }, []);

  const refreshPage = useCallback(() => {
    clearCanvas(false);
    setAngle(0);

    updateDisplayCalibration();
    const imagesForPage = getImages(page - 1);
    if (imagesForPage) {
      for (let data of imagesForPage) {
        const id = uuidv4(); // Generate unique ID for new shape

        insertImage(data.url, data.x, data.y, {
          originX: "left",
          originY: "top",
          scale: 1,
          layer: backgroundLayerId,
          selectable: false,
          fixed: true,
          visible: true,
          isBackground: true,
          id: id
        });
      }
    }
    if (initialLayers.every(x => layernames.includes(x))) {
      setOldPage(page);
      restoreShapes(page);
    }
  }, [backgroundLayerId, clearCanvas, getImages, initialLayers, insertImage, layernames, page, restoreShapes, setAngle, updateDisplayCalibration]);

  useEffect(() => {
    if (page !== 0 && oldPage !== page) {
      refreshPage();
    }

  }, [page, JSON.stringify(layernames), backgroundLayerId]);
  

  useEffect(() => {
    updateDisplayCalibration();
  }, [currentScale]);

  const pagination = useMemo(() => {
    return <div className="w-full bottom-2 absolute overflow-hidden flex justify-center">
            <CustomPagination
              isCompact
              showControls
              visiblePages={visiblePages}
              total={pageCount}
              onChange={setPage}
              initialPage={page}
            />
    </div>;
  }, [page, pageCount, visiblePages, setPage]);

  return (<>
    {/* Flex container to layout ImageView and SideMenu horizontally */}
    <div className="flex w-full h-full overflow-hidden">
      {/* Container for ImageView component */}
      <ImageView
        backgroundColor="#D3D3D3"
      />
    </div>

    <div className="top-2 left-2 absolute">
        <ReactPanZoom />
    </div>
    
    {pagination}

    {toolsPanel}
  </>);
}

// const MemoizedPDFEditor = React.memo(PDFEditor);
// export default MemoizedPDFEditor;
export default PDFEditor