import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Button, Link, Spacer} from "@heroui/react";
import {Icon} from "@iconify/react";
import "./home.css"

import CenteredNavbar from "./centered-navbar";
import ServiceCard from "../../components/cards/ServiceCard";
import GlassInput from "../../components/inputs/glass-input";
import { SearchIcon } from "../../components/Icons";
import WidgetNavigator from "../../components/widgets/navigator";
import { ContactWidget, FeedbackWidget, HelpWidget } from "../../components/widgets/widgets";

import useTools from "../../hooks/useTools";
import { SpinnerDiane } from "../../components/Loading/SpinnerDiane";
import { introSentences } from "./sentences";

// Fonction pour gérer le défilement
export const scrollToServices = () => {
  const servicesSection = document.querySelector(".service-container");
  if (servicesSection) {
    servicesSection.scrollIntoView({ behavior: "smooth" });
  }
};

const selectedSentenceIndex = Math.floor(Math.random() * introSentences.length);
const selectedSentence = introSentences[Math.max(Math.min(selectedSentenceIndex, introSentences.length - 1), 0)]

export default function HomePage() {

  let navigate = useNavigate();

  const [searchName, setSearchName] = useState('');

  const {
    loading,
    error,
    filteredPlanServices, 
    filteredPdfServices, 
    filteredDevisServices, 
    favoriteServices 
  } = useTools(searchName);

  const [scrollPosition, setPosition] = useState(0);
  const handleScroll = () => {
    const container = document.querySelector('main').firstChild;
    const position = container.scrollTop;
    setPosition(position);
  }
  useEffect(() => {
    const container = document.querySelector('main').firstChild;
    container.addEventListener("scroll", handleScroll);
    handleScroll();
  
    return () => container.removeEventListener("scroll", handleScroll);
  }, [])
  
  if (loading) {
    return (
      <SpinnerDiane spinnerText="Chargement des services..."/>
    );
  }

  if (error) {
    return (
      <div className="relative flex flex-col items-center min-h-screen w-full gap-9 overflow-y-auto bg-transparent p-4 md:gap-12 md:px-10 md:py-[34px]">
        <main className="flex flex-col items-center justify-center rounded-2xl px-3 md:rounded-3xl md:px-0">
          <section className="my-14 mt-8 flex flex-col items-center justify-center gap-6">
            <CenteredNavbar />
            <Spacer y={10} />
            <h1 className="text-center text-[clamp(2.125rem,1.142rem+3.659vw,4rem)] font-bold leading-none text-foreground">Plateforme DIANE</h1>
            <Spacer y={10} />
            <p className="text-center text-base text-default-600 sm:w-[466px] md:text-lg md:leading-6">
              Une erreur s'est produite lors du chargement des services. Si le problème persiste, merci de contacter le support DIANE.
            </p>
            <Link href="mailto:support.diane@vinci-energies.com">support.diane@vinci-energies.com</Link>
            </section>
          </main>
      </div>
    );
  }


  return (
    <>
      <div className="h-full w-full">
        <div className="relative flex flex-col items-center justify-center min-h-screen w-full gap-9 overflow-y-auto bg-transparent p-4 md:gap-12 md:px-10 md:py-[34px]">
            <main className="flex flex-col items-center justify-center rounded-2xl px-3 md:rounded-3xl md:px-0">
              <section className="my-14 mt-8 flex flex-col items-center justify-center gap-6">
                <CenteredNavbar />
                <Spacer y={10} />
                <h1 className="text-center text-[clamp(2.125rem,1.142rem+3.659vw,4rem)] font-bold leading-none text-foreground">Plateforme DIANE</h1>
                <p className="text-center text-base text-default-600 sm:w-[466px] md:text-lg md:leading-6">
                {selectedSentence}
                </p>
                <div className="flex flex-col items-center gap-3 sm:flex-row sm:gap-6">
                  <Button
                    className="w-[163px] bg-foreground font-medium text-background"
                    radius="full"
                    onPress={scrollToServices}
                  >
                    Tous les services
                  </Button>
                  <Button
                    className="w-[163px] border-1 glass light font-medium text-default-foreground"
                    endContent={
                      <span className="pointer-events-none flex h-[22px] w-[22px] items-center justify-center rounded-full">
                        <Icon
                          className="text-default-500 transition-transform group-data-[hover=true]:translate-x-0.5 [&>path]:stroke-[1.5]"
                          icon="solar:arrow-right-linear"
                          width={16}
                        />
                      </span>
                    }
                    radius="full"
                    variant="bordered"
                    onPress={() => {
                      navigate("/contact")
                    }}
                  >
                    Contact
                  </Button>
                </div>
              </section>
              <Spacer y={18} />
              <div className="w-[calc(100%-calc(theme(spacing.4)*2))] max-w-6xl
                    overflow-hidden rounded-2xl border-1 glass px-2 py-3 md:px-4 md:py-6
                    grid grid-cols-2 md:grid-cols-4 gap-4">
                {favoriteServices?.map(x=> (
                  <ServiceCard
                    key={x["name"]}
                    imageSrc={x["img"]}
                    title={x["name"]}
                    description={x["description"]}
                    href={x["destination"]}
                    textColor="#fff"
                    className="w-full min-w-0"
                    // allowFavorite={true}
                    isFavorite={x["isfavorite"]}
                  />
              ))} 
              </div>
            </main>
        </div>
        <div className="service-container px-[4vw] py-[20px]" id="services">
          <h1 className="text-center text-[clamp(2.125rem,1.142rem+3.659vw,4rem)] font-bold leading-none text-foreground">Services</h1>

          <div className="overflow-hidden rounded-2xl px-2 py-3 md:px-4 md:py-6 mb-[40px]">
            <div
              className="recherche-container pt-[20px] pb-[10px] mx-auto w-[55vh] relative"
            >
              <GlassInput
                label="Recherche"
                radius="lg"
                // color="#FFFFFFBB"
                type="search"
                placeholder="Nom du service..."
                startContent={
                  <SearchIcon className="text-black mb-0.5 dark:text-white/90 text-slate-400 pointer-events-none flex-shrink-0" />
                }
                onChange={e => setSearchName(e.target.value)}
              />
            </div>

            {(filteredPlanServices !== undefined && filteredPlanServices.length > 0) && <><h2 className="h2">Général</h2>
            <div className="container grid grid-cols-2 md:grid-cols-4 gap-4
                            mt-[2vh] w-full max-w-full glass p-8 rounded-xl">
              {filteredPlanServices?.map(x=> (
                  <ServiceCard
                    key={x["name"]}
                    imageSrc={x["img"]}
                    title={x["name"]}
                    description={x["description"]}
                    href={x["destination"]}
                    textColor="#fff"
                    className="w-full min-w-0"
                    // allowFavorite={true}
                    isFavorite={x["isfavorite"]}
                  />
              ))} 
            </div></>}

            {(filteredPdfServices !== undefined && filteredPdfServices.length > 0) && <><h2 className="mt-[50px] h2">Vos Services</h2>
            <div className="container grid grid-cols-2 md:grid-cols-4 gap-4
                            mt-[2vh] w-full max-w-full glass p-8 rounded-xl">
              {filteredPdfServices?.map(x=> (
                  <ServiceCard
                    key={x["name"]}
                    imageSrc={x["img"]}
                    title={x["name"]}
                    description={x["description"]}
                    href={x["destination"]}
                    textColor="#fff"
                    className="w-full min-w-0"
                    // allowFavorite={true}
                    isFavorite={x["isfavorite"]}
                  />
              ))} 
            </div></>}

            {(filteredDevisServices !== undefined && filteredDevisServices.length > 0) && <><h2 className="mt-[50px] h2">Tech</h2>
            <div className="container grid grid-cols-2 md:grid-cols-4 gap-4
                          mt-[2vh] w-full max-w-full glass p-8 rounded-xl">
              {filteredDevisServices?.map(x=> (
                  <ServiceCard
                    key={x["name"]}
                    imageSrc={x["img"]}
                    title={x["name"]}
                    description={x["description"]}
                    href={x["destination"]}
                    textColor="#fff"
                    className="w-full min-w-0"
                    // allowFavorite={true}
                    isFavorite={x["isfavorite"]}
                  />
              ))} 
            </div></>}
          </div>
        </div>
      </div>
      {scrollPosition === 0 && <div className="arrows scale-50 cursor-pointer" onClick={scrollToServices}></div>}
      <WidgetNavigator>
        {/* <HomeWidget /> */}
        {/* <SettingsWidget /> */}
        {/* <ContactWidget /> */}
        <FeedbackWidget />
        {/* <HelpWidget
          tutoVideoUrl={undefined}
        /> */}
      </WidgetNavigator>
    </>
  );
}
