import { helpUrls } from "../../../../config";
import {Link, useDisclosure} from "@heroui/react";
import FeedbackModal from "../../../../components/feedbacks/modal";

const Video = ({ videoUrl, title }) => {
    return <div className="flex flex-col">
    <p className="mb-4 font-bold">{title}</p>
    <div style={{
        position: 'relative',
        width: '100%',
        paddingBottom: '56.25%', // 16:9 aspect ratio
        height: 0,
        overflow: 'hidden'
    }}>

    <iframe
        src={videoUrl}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowFullScreen
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
        }}
    />
    </div>
  </div>;
};

export default function Tutorials () {
const {isOpen, onOpen, onOpenChange, onClose} = useDisclosure();

  return (
    <div className="flex flex-col w-full">
    <p className="mt-4 px-12">Les vidéos suivantes vous aideront à démarrer avec le service de dénombrement.</p>
    <div className="px-12">
        Si vous avez des questions, des suggestions ou des remarques, n'hésitez pas à nous en faire part via le&nbsp;
        <Link showAnchorIcon onPress={onOpen} href="#">formulaire de contact</Link>.
    </div>
    <div className="grid grid-cols-2 gap-x-24 gap-y-8 m-4 px-8 overflow-auto">
        <Video
            videoUrl={helpUrls.Comptage.find(x => x.title === "Création de projet").url}
            title="Étape 1 - Création de projet"
        />
        <Video
            videoUrl={helpUrls.Comptage.find(x => x.title === "Paramétrage").url}
            title="Étape 2 - Paramétrage"
        />
        <Video
            videoUrl={helpUrls.Comptage.find(x => x.title === "Dénombrement").url}
            title="Étape 3 - Dénombrement"
        />
        <Video
            videoUrl={helpUrls.Comptage.find(x => x.title === "Métré").url}
            title="Étape 4 - Métré"
        />
    </div>
    <FeedbackModal
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        onClose={onClose}
      />
    </div>
  );
};
