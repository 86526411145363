import React from "react";

import Home from "../pages/services/Pym/home";

// import Calculs from "../pages/services/Pym/Calculs/Calculs";
 import Calculs from "../pages/services/Pym/calculs/index";
import FileExplorer from "../pages/services/Pym/file-explorer";
import FileIesLdt from "../pages/services/Pym/file-ies-ldt";
import FileLights from "../pages/services/Pym/file-lights";
import FileRoadDefinition from "../pages/services/Pym/file-road-definition";
import Luminaire from "../pages/services/Pym/Luminaire";
import Voiries from "../pages/services/Pym/voiries";

import ProtectedRoute from "../components/login/protected-route";
import Definition from "../pages/services/Pym/definition";
import Optimisation from "../pages/services/Pym/optimisation";
import { SoluxMapProvider } from "../pages/services/Pym/side-bar/provider";

const PYM = React.lazy(() => import("../pages/services/Pym/index"));
const PymMap = React.lazy(() => import("../pages/services/Pym/side-bar/index"));
const Settings = React.lazy(() =>
  import("../pages/services/Pym/settings/settings")
);

const router = {
  path: "/",
  element: "",
  children: [
    {
      path: "/solux",
      element: (
        <ProtectedRoute roles={["Plateforme.Service5", "Plateforme.FullAccess"]}>
            <PYM />
        </ProtectedRoute>
      ),
    },
    {
      path: "/solux-map",
      element: (
        <ProtectedRoute roles={["Plateforme.Service5", "Plateforme.FullAccess"]}>
          <SoluxMapProvider>
            <PymMap />
          </SoluxMapProvider>
        </ProtectedRoute>
      ),
      children: [
        {
          path: "accueil",
          element: <Home />,
        },
        {
          path: "nomenclature",
          element: <div>coucou</div>,
        },
        {
          path: "calculs",
          element: <Calculs />,
        },
        {
          path: "voiries",
          element: <Voiries />,
        },

        {
          path: "optimisation",
          element: <Optimisation/>,
        },
        {
          path: "verification",
          element: <div></div>,
        },
        {
          path: "definition",
          element: <Definition/>,
        },
        {
          path: "fichiers",
          element: <FileExplorer/>,
        },
        {
          path: "fichiers-ies-ldt",
          element: <FileIesLdt/>,
        },
        {
          path: "fichiers-lights",
          element: <FileLights/>,
        },
        {
          path: "fichiers-troncons",
          element: <FileRoadDefinition/>,
        },
        {
          path: "settings",
          element: <Settings />,
        },
      ],
    },
  ],
};

export default router;
