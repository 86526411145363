import { Icon } from "@iconify/react";
import { Card, CardBody, CardHeader, Divider,Chip } from "@heroui/react";
import React from "react";
import { useSelector } from "react-redux";


export default function NomenclatureDrawer ({showModal,setShowModal,setDisplayLightsMode}){
    const source_color_table_link = useSelector((state) => state.PYM.ProjectInfos.sources_colors)

    return(
        <>
        {showModal && <div style={{
                position: 'absolute',
                top:'0',
                right:'0',
                marginRight:'4px',
                marginTop:'4px',
                zIndex: 999
            }}>
            <Card className="max-w-[300px]">
                <CardHeader>
                    <div className="mx-4 mt-3 flex justify-center gap-1">
                        <Icon icon="solar:documents-minimalistic-line-duotone" height={20} style={{ color: '#8ecae6' }} />
                        <b>Nomenclature</b> 
                    </div>
                    <Icon icon="solar:close-circle-line-duotone" height={20} className="cursor-pointer" style={{ color: '#8ecae6',
                    position: 'absolute',
                    top:'0',
                    right:'0',
                    marginRight:'4px',
                    marginTop:'4px',
                    }} 
                    onClick={()=>{
                        setDisplayLightsMode('source')
                        setShowModal(false)
                    }}
                    />
                </CardHeader>
                <Divider />
                <CardBody>
                    {
                        Object.entries(source_color_table_link).map(([key, value]) => (
                            <div >
                            <span
                                className="inline-block w-2 h-2 rounded-full mr-2"
                                style={{
                                    backgroundColor:value.color,
                                }}
                                >

                                </span>
                                <span>
                                    {`${key} : ${value.count}`}
                                </span>
                            </div>
                          ))}
                </CardBody>
                <Divider />
            </Card>       
     </div>}
     </>
    )
}