import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../../../config";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import RoadDisplayList from "./road-profil-display-list";
import LightsSelectionCard from "./lights-selection-card";
import {Tabs, Tab, Card, CardBody, CardHeader, Button, ResizablePanel} from "@heroui/react";
import ResultDisplay from "./result-display";
import { Icon } from "@iconify/react";
import RedirectionModal from "../components/redirection-modal";
import { Outlet, useNavigate, Navigate, useOutletContext } from "react-router-dom";
import OptimisiationChoiceModal from "./optimisation-choice-modal";
import PDFHighlighter from '../../../../components/file/PdfHighlighter';
import {RadioGroup, Radio} from "@heroui/react";

export default function Optimisation() {
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const { launch_request } = useAzureFuncApp();
  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);
  const projectGeoData = useSelector((state) => state.PYM.GeoData);
  const [roadsProfils, setRoadsProfils] = React.useState([]);
  const [isOptimised, setIsOptimised] = React.useState(false);
  const [selectedKey,setSelectedKey] = React.useState(0);
  const [showModal,setShowModal] = React.useState(false);
  const [lightsData,setLightsData] = React.useState([]);

  const roadProfil = React.useMemo(() => {
    return roadsProfils.find(x=>x.id == selectedKey) || roadsProfils[0];
  }, [selectedKey]);
  const folderSelected = React.useMemo(() => {
    return roadsProfils.find(x=>x.id == selectedKey);
  }, [selectedKey]);
  const [tabs,setTabs] = React.useState([
    {
        id:projectGeoData.city_lights_files[0],
        label:projectGeoData.city_lights_files[0],
        content:<ResultDisplay/>
    }
  ]);

  const [selectedIES,setSelectedIES] = React.useState([])

const navigate = useNavigate();
const [showModaOptiChoice,sethowModaOptiChoice] = React.useState(false);
const [missRoadProfile,setMissRoadProfile] = React.useState(false);

const [pageNumber, setPageNumber] = React.useState(0);
const [document, setDocument] = React.useState(null);
const [highlightedText, setHighlightedText] =React.useState("");

  async function getRoadsProfile() {
    const payload = {
      functionName: "getDataForOptimisation",
      projectPath: projectInfos.project_path || "",
    };
    console.log("payload", payload);
    setLoadingMessage("Récupération des profils de route...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    if (status_code === 200) {
      if(result.roadsList.length ===0 || result.lightsList.length ===0){
        setMissRoadProfile(result.roadsList.length ===0);
        setShowModal(true);
      }
  
      setIsLoading(false);
      return result
    }
    setIsLoading(false);
  }
 
  async function handleOptimiseRoad(needDistOpti){
    sethowModaOptiChoice(false);
    const payload = {
      functionName: "optimiseRoadsProfiles",
      projectPath: projectInfos.project_path || "",
      needDistOpti:needDistOpti,
      roadProfile:roadProfil,
      iesSelected:selectedIES
    };
    console.log("payload", payload);
    if(selectedIES.length === 0 || roadProfil === undefined){
      return
    }
    setLoadingMessage("Optimisation des tronçons...")
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymOptimisation,
      payload
    );
    if (status_code === 200) {
      setIsLoading(false);
      setIsOptimised(true)
      setDocument(result['pdf_path']);
      // setTabs((tabs) => {
      //   let newTabs = Array.from(Object.keys(result.result)).map((k) => ({
      //     id: k,
      //     label: k,
      //   }))

      //   return newTabs
      // })
    }  
    setIsLoading(false);
  }

  useEffect(() => {
    getRoadsProfile().then((result) => {
      setRoadsProfils(result.roadsList.map((x, idx)=>({...x, id: idx})));
      setLightsData(result.lightsList) ;
    });
  }, [])

    return(
        <div className="flex w-full h-full">
        <RoadDisplayList folderList={roadsProfils} className="lg:col-span-6 xl:col-span-4 flex flex-col py-4" folderSelected={folderSelected} selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
        <div className="flex flex-col w-full h-full">
        {!isOptimised &&
            <div className="flex flex-col h-full">
              <LightsSelectionCard lightsData ={lightsData} setSelectedIES={setSelectedIES}/>
              <div className="flex justify-end mt-2 mr-2 mb-2">
                <Button className='bg-[#80ed99]' onClick={()=>sethowModaOptiChoice(true)}><Icon icon={'solar:calculator-line-duotone'}/><p className="text-small font-semibold text-default-foreground">Optimiser</p></Button>
              </div>
            </div>
            }
            {isOptimised &&
              <div className=" w-full h-full">
                {/* <RadioGroup className="flex" label="Meilleurs fichiers photométriques :" onValueChange={setHighlightedText}>
                  {tabs.map((tab) => (
                    <Radio key={tab.id} value={tab.id}>{tab.label}</Radio>
                  ))}
                </RadioGroup> */}

                  {/* <Tabs aria-label="Dynamic tabs" items={tabs}>
                      {(item) => (
                        <Tab key={item.id} title={item.label}>
                      </Tab>
                      )}
                  </Tabs> */}
                    <PDFHighlighter className=" my-4 mx-2" pdfUrl={document} currentPage={pageNumber} searchQuery={highlightedText} clearMarkdown={true} />
              </div>  
            }
        </div>
        <RedirectionModal isOpen={showModal} onConfirm={()=>{
          setShowModal(false);
          let path = missRoadProfile ? `/solux-map/definition` : `/solux-map/fichiers-ies-ldt`
          navigate(path);
        }} 
        onDecline={
          ()=>{
          setShowModal(false);
            navigate(`/solux-map/accueil`)
          }
        }
        message ={<>Il semble qu’<b>aucun</b> {missRoadProfile ? "profil de route" : "fichier photométrique" } ne soit actuellement défini pour le <b>projet</b> en cours.</>
      } 
          buttonOkText = {`Ajouter des ${missRoadProfile ? 'profils' : 'fichiers'}`}
          /> 
       <OptimisiationChoiceModal isOpen={showModaOptiChoice} onConfirm={()=>handleOptimiseRoad(true)} onDecline={() => handleOptimiseRoad(false)}/>
        </div>
    )
}
