import LeafletMap from "../map";
import { useSoluxMap } from "../side-bar/provider";

export default function Home(){
      const { cityLightsData } = useSoluxMap();
    
    return (
        <LeafletMap
        show_lights={true}
        lightsData={cityLightsData}
        display_lights_mode="source"

        />
    )
}