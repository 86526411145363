import { useCallback, useContext } from "react";
import { CanvasContext } from "../../../../components/polygon-editor/contexts/canvas-context";
import { CanvasEventContext } from "../../../../components/polygon-editor/contexts/canvas-event-context";

export const useZones = () => {
  return {
    ...useContext(CanvasContext),
    ...useContext(CanvasEventContext)
  };
};

export const ZoneProvider = ({ 
  addZone,
  removeZone,
  children
}) => {
    const { canvasRef } = useContext(CanvasContext);

    const onShapeAdded = useCallback((shape) => {
      if (shape && !shape.isBackground) {
        shape.bringToFront();
        addZone(shape);
      }
    }, [addZone]);
    
    const onShapeRemoved = useCallback((shape) => {
      if (shape && !shape.isBackground) {
        removeZone(shape);
      }
    }, [removeZone]);
    
    const onShapeSelected = useCallback((shape) => {
      if (shape) {
        shape.forEach(sh => {
          if (sh && !sh.isBackground && !sh.isType("polyline")) {
            sh.stroke = "#FF0000";
            sh.strokeWidth = 5;
          }
        });
      }
    }, []);
    
    const onShapeDeselected = useCallback((shape) => {
      if (shape) {
        shape.forEach(sh => {
          if (sh && !sh.isBackground && !sh.isType("polyline")) {
            sh.stroke = sh.fill;
            sh.strokeWidth = 1;
          }
        });
      }
    }, []);
    
    const onShapeClicked = useCallback((shape) => {

    }, []);

    return (
      <CanvasEventContext.Provider
        value={{
            onShapeAdded,
            onShapeRemoved,
            onShapeSelected,
            onShapeDeselected,
            onShapeClicked
        }}
      >
        {children}
      </CanvasEventContext.Provider>
    );
  };