import { createSlice } from "@reduxjs/toolkit";

const Configuration = createSlice({
  name: "configuration",
  initialState: {
    current_file: "",
    pages: [],
    zones: [],
    symbols: [],
    linears: [],
    legend: {},
  },
  reducers: {
    setFileConfiguration: (state, action) => {
      const { current_file, pages, zones, symbols, linears, legend } = action.payload;

      let keep_segmentation_ready = (state.current_file === current_file || state.current_file === "");
      let pagesReady, pagesFailed, pagesOngoing;
      if (keep_segmentation_ready) {
        pagesReady = state.pages.filter(x => (x.segmentation_ready === "ready")).map(x => x.page);
        pagesFailed = state.pages.filter(x => (x.segmentation_ready === "failed")).map(x => x.page);
        pagesOngoing = state.pages.filter(x => (x.segmentation_ready === "ongoing")).map(x => x.page);
      }

      state.current_file = current_file;
      state.pages = pages;
      state.zones = zones;
      state.symbols = symbols;
      state.linears = linears;
      state.legend = legend;

      if (keep_segmentation_ready) {
        //set back segmentation_ready at a definitive state ("ready" or "failed") if it was overwritten by action.payload.pages (set to "ongoing" or "not_started").
        for (let page of pagesReady) {
          if (state.pages[page]) {state.pages[page].segmentation_ready = "ready";}
        }
        for (let page of pagesFailed) {
          if (state.pages[page]) {state.pages[page].segmentation_ready = "failed";}
        }
        //set back segmentation_ready at "ongoing" if it was set to "not_started" by action.payload.pages.
        for (let page of pagesOngoing) {
          if (state.pages[page]?.segmentation_ready === "not_started") {state.pages[page].segmentation_ready = "ongoing";}
        }
      }

    },
    updatePages: (state, action) => {
      const pages = action.payload;

      const pagesReady = state.pages.filter(x => (x.segmentation_ready === "ready")).map(x => x.page);
      const pagesFailed = state.pages.filter(x => (x.segmentation_ready === "failed")).map(x => x.page);
      const pagesOngoing = state.pages.filter(x => (x.segmentation_ready === "ongoing")).map(x => x.page);

      state.pages = pages;

      //set back segmentation_ready at a definitive state ("ready" or "failed") if it was overwritten by action.payload (set to "ongoing" or "not_started").
      for (let page of pagesReady) {
        if (state.pages[page]) {state.pages[page].segmentation_ready = "ready";}
      }
      for (let page of pagesFailed) {
        if (state.pages[page]) {state.pages[page].segmentation_ready = "failed";}
      }
      //set back segmentation_ready at "ongoing" if it was set to "not_started" by action.payload.
      for (let page of pagesOngoing) {
        if (state.pages[page]?.segmentation_ready === "not_started") {state.pages[page].segmentation_ready = "ongoing";}
      }
    },
    setCurrentFileSelectedPages: (state, action) => {
      const selectedPages = action.payload;
      for (let i=0;i<state.pages.length;i++) {
        state.pages[i].selected = selectedPages.includes(i);
      }
    },
    setSegmentationReady: (state, action) => {
      const { page, segmentation, filename } = action.payload;
      if ((state.current_file === "") || (state.current_file === filename)) {
        if (state.pages[page]) {
          state.pages[page].segmentation_ready = segmentation;
        } else {
          state.pages.push({"page": page, "segmentation_ready": segmentation});
        };
      }
    },
    addZoneStore: (state, action) => {
      state.zones = [...state.zones, action.payload];
    },
    removeZoneStore: (state, action) => {
      let id = action.payload;
      state.zones = state.zones.filter(x => x.id !== id);
    },
    updateZones: (state, action) => {
      let zones = action.payload;
      zones.forEach(z => {
        let newZ = state.zones.find(x=>x.id === z.id);
        if (newZ) {
          newZ.name = z.name;
        }
      });
    },
    changeZoneColorStore: (state, action) => {
      const { id, newColor } = action.payload;
      let zone = state.zones.find(x => x.id === id);
      if (zone) {
        zone.color = newColor;
      }
    },

    addLegendStore: (state, action) => {
      state.legend = action.payload;
    },

    removeLegendStore: (state, action) => {
      let id = action.payload;
      if ((state.legend.id) && (state.legend.id === id)) {
        state.legend = {};
      };
    },

    setSymbolsStore: (state, action) => {
      state.symbols = action.payload;
    },
    addSymbolStore: (state, action) => {
      state.symbols = [...state.symbols, action.payload];
    },
    removeSymbolStore: (state, action) => {
      let id = action.payload;
      state.symbols = state.symbols.filter(x => x.id !== id);
    },
    updateSymbols: (state, action) => {
      let symbol = action.payload;
      symbol.forEach(s => {
        let newS = state.symbols.find(x=>x.id === s.id);
        if (newS) {
          newS.name = s.name;
        }
      });
    },
    updateLinears: (state, action) => {
      let linear = action.payload;
      state.linears.forEach(l => {
        let newL = linear.find(x=>x.id === l.id);
        if (newL) {
          l.name = newL.name;
        }
      });
    },
    changeSymbolColorStore: (state, action) => {
      const { id, newColor } = action.payload;
      let symbol = state.symbols.find(x => x.id === id);
      if (symbol) {
        symbol.color = newColor;
      }
    },
    addLinearStore: (state, action) => {
      state.linears = [...state.linears, action.payload];
    },
    removeLinearStore: (state, action) => {
      let id = action.payload;
      state.linears = state.linears.filter(x => x.id !== id);
    },
    changeLinearColorStore: (state, action) => {
      const { id, newColor } = action.payload;
      let linear = state.linears.find(x => x.id === id);
      if (linear) {
        linear.color = newColor;
      }
    },
    setSymbolConfidenceRate: (state, action) => {
      const { crop_id, value } = action.payload;
      let s = state.symbols.find(x=>x.crop_id === crop_id);
      if (s) {
        s.confidence_rate = value;
      }
    },
    setSymbolPointSize: (state, action) => {
      const { crop_id, value } = action.payload;
      let s = state.symbols.find(x=>x.crop_id === crop_id);
      if (s) {
        s.point_size = value;
      }
    },
    setSymbolOpacity: (state, action) => {
      const { crop_id, value } = action.payload;
      let s = state.symbols.find(x=>x.crop_id === crop_id);
      if (s) {
        s.opacity = value;
      }
    },
    setLinearLineWidth: (state, action) => {
      const { linear_id, value } = action.payload;
      let l = state.linears.find(x=>x.linear_id === linear_id);
      if (l) {
        l.width = value;
      }
    },
    setLinearOpacity: (state, action) => {
      const { linear_id, value } = action.payload;
      let l = state.linears.find(x=>x.linear_id === linear_id);
      if (l) {
        l.opacity = value;
      }
    },
    switchSymbolVisibility: (state, action) => {
      const crop_id = action.payload;
      let s = state.symbols.find(x=>x.crop_id === crop_id);
      if (s) {
        s.isVisible = s.isVisible === false ? true : false;
      }
    },
    switchAllSymbolsVisibility: (state, action) => {
      const isVisible = action.payload;
      state.symbols.forEach(s => {
        s.isVisible = isVisible;
      });
    },
    switchLinearVisibility: (state, action) => {
      const linear_id = action.payload;
      let l = state.linears.find(x=>x.linear_id === linear_id);
      if (l) {
        l.isVisible = l.isVisible === false ? true : false;
      }
    }
  },
});

export const {
  setFileConfiguration, updatePages, setCurrentFileSelectedPages, setSegmentationReady,
  setSymbolsStore, addZoneStore, removeZoneStore, updateZones, changeZoneColorStore,
  addLegendStore, removeLegendStore, addSymbolStore, removeSymbolStore, updateSymbols, changeSymbolColorStore,
  addLinearStore, removeLinearStore, updateLinears, changeLinearColorStore,
  setSymbolConfidenceRate, setSymbolOpacity, setSymbolPointSize,
  setLinearLineWidth, setLinearOpacity,
  switchSymbolVisibility, switchAllSymbolsVisibility, switchLinearVisibility } = Configuration.actions;
export default Configuration.reducer;
