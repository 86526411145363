import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PDFEditor from "../../../../components/polygon-editor/pdf-editor";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "./sideMenu";
import { CanvasContext, CanvasProvider } from "../../../../components/polygon-editor/contexts/canvas-context";
import { CountProvider } from "./provider";
import { DrawShapeProvider, useDrawShape } from "../../../../components/polygon-editor/contexts/draw-context";
import { ShapeType } from "../../../../components/polygon-editor/hooks/draw";
import { setFillColor, setOpacity, setPointSize } from "../../../../store/components/PolygonEditor/CanvasSlice";
import { fabric } from 'fabric';
import { addCount, removeHits } from "../../../../store/features/Comptage/decompte";
import { setSegmentationReady, setSymbolConfidenceRate, setSymbolOpacity, setSymbolPointSize } from "../../../../store/features/Comptage/configuration";
import config from "../../../../config";

import { useOutletContext } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import { Functions } from "./functions";
import { addObjectInLayer } from "../../../../store/components/PolygonEditor/ImageSlice";
import YesNoAlert from "../../../../components/modals/AlertYesNo";
import Alert from "../../../../components/modals/Alert";

export const defaultConfidenceRate = [.6, 1];
export const defaultPointSize = 40;
export const defaultOpacity = .6;
const containerName = "comptagev2";

export default function Count ({
  children,
  sidemenus=undefined
}) {
  const {isLoading, setIsLoading, loadingMessage, setLoadingMessage, page, setPage } = useOutletContext();
  fabric.Group.prototype.hasControls = false;
  fabric.Group.prototype.lockMovementX = false;
  fabric.Group.prototype.lockMovementY = false;
  fabric.Group.prototype.lockRotation = false;

  return (
    <CanvasProvider className="overflow-hidden h-[99vh]">
      <CountComponent
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        loadingMessage={loadingMessage}
        setLoadingMessage={setLoadingMessage}
        sidemenus={sidemenus}
        page={page}
        setPage={setPage}
      >
        {children}
      </CountComponent>
    </CanvasProvider>
  );
};

export function CountComponent ({
    children,
    isLoading,
    setIsLoading,
    loadingMessage,
    setLoadingMessage,
    sidemenus,
    page,
    setPage
}) {
  const dispatch = useDispatch();
  const [activeFunction, setActiveFunction] = useState(Functions.NONE);
  const { angle } = useContext(CanvasContext);
  const [currentSymbol, setCurrentSymbol] = useState("all");
  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const countLayerId = useSelector((state) => {
    let count = state.image.layers.find(x=>x.name === "count");
    if (count) {
      return count.layerId;
    }
    else {
      return -1;
    }
  });

  const currentSymbolData = useMemo(() => {
    let csd = symbols.find(x => x.crop_id === currentSymbol);
    if (csd) {
      return csd;
    }else {
      return {
        crop_id: -1,
        name: "inconnu"
      }
    }
  }, [currentSymbol, symbols]);
  const [confidenceRate, setConfidenceRate] = useState(defaultConfidenceRate);
  const [inputPointSize, setInputPointSize] = useState(defaultPointSize);
  const [alpha, setAlpha] = useState(defaultOpacity);

  const addCountItem = (shape) => {
    
    shape.crop_id = currentSymbol;
    shape.confidence_rate = activeFunction === Functions.MANUAL ? 1 : shape.confidence_rate;
    dispatch(setOpacity(alpha));
    dispatch(setPointSize(inputPointSize));
    dispatch(addObjectInLayer({layerId: countLayerId, object: shape.id})); // Add the object's ID to Redux store
    dispatch(addCount({
      id: shape.id,
      crop_id: currentSymbol,
      name: currentSymbolData.name,
      confidence_rate: activeFunction === Functions.MANUAL ? 1 : shape.confidence_rate,
      angle: 0,
      manual: activeFunction === Functions.MANUAL,
      x: shape.left,
      y: shape.top,
      page: page,
      symbol: shape.isType("rect") ? "square" : "point"
    }));
  };

  const removeCountItem = (shape) => {
    dispatch(removeHits([shape.id]));
  };

  return (
    <CountProvider
      addCountItem={addCountItem}
      removeCountItem={removeCountItem}
    >
      <DrawShapeProvider>
        <CountEditor
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          loadingMessage={loadingMessage}
          setLoadingMessage={setLoadingMessage}
          page={page}
          setPage={setPage}
          activeFunction={activeFunction}
          setActiveFunction={setActiveFunction}
          currentSymbol={currentSymbol}
          setCurrentSymbol={setCurrentSymbol}
          currentSymbolData={currentSymbolData}
          confidenceRate={confidenceRate}
          setConfidenceRate={setConfidenceRate}
          alpha={alpha}
          setAlpha={setAlpha}
          inputPointSize={inputPointSize}
          setInputPointSize={setInputPointSize}
          sidemenus={sidemenus}
        >
          {children}
        </CountEditor>
      </DrawShapeProvider>
    </CountProvider>
  );
};

const CountEditor = ({
  children,
  isLoading, setIsLoading,
  loadingMessage, setLoadingMessage,
  page, setPage,
  activeFunction, setActiveFunction,
  currentSymbol, setCurrentSymbol, currentSymbolData,
  confidenceRate, setConfidenceRate,
  alpha, setAlpha,
  inputPointSize, setInputPointSize,
  sidemenus }) => {

  const { launch_request } = useAzureFuncApp();

  const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
  const projectName = useSelector((state) => state.Comptage.ProjectInfos.project_name);
  const user = useSelector((state) => state.Comptage.ProjectInfos.creator_name);
  const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
  const currentFilePages = useSelector((state) => state.Comptage.Configuration.pages);
  const currentFileData = projectFiles.find(x =>x.name === currentFile);
  const selectedPages = useSelector((state) => state.Comptage.Configuration.pages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]));
  const segmentationReady = useSelector((state) => state.Comptage.Configuration.pages[page-1]?.segmentation_ready);
  const { canvasRef, setIsDeleting, setShapeType, getAllObjects, removeObjects, setIsDrawing, rearrengeObjects } = useContext(CanvasContext);
  const { startDrawing, insertPolygon, insertPoint, insertSquarePoint, insertSvg } = useDrawShape();
  const zones = useSelector((state) => state.Comptage.Configuration.zones);
  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const symbolsVisibility = useSelector((state) => state.Comptage.Configuration.symbols.map(x => ({
    crop_id: x.crop_id,
    confidence_rate: x.confidence_rate ?? defaultConfidenceRate,
    isVisible: x.isVisible ?? true
  })));
  const { angle } = useContext(CanvasContext);

  const layers = useSelector((state) => state.image.layers);
  const zoneLayerId = useSelector((state) => {
    let zone = state.image.layers.find(x=>x.name === "zone");
    if (zone) {
      return zone.layerId;
    }
    else {
      return -1;
    }
  });
  const countLayerId = useSelector((state) => {
    let count = state.image.layers.find(x=>x.name === "count");
    if (count) {
      return count.layerId;
    }
    else {
      return -1;
    }
  });
  const countedItems = useSelector((state) => state.Comptage.Decompte.symbols);
  const dispatch = useDispatch();

  const [currentZones, setCurrentZones] = useState(zones.filter(x => x.page === page).map(x => x.id));
  
  useEffect(() => {
    setCurrentZones(zones.filter(x => x.page === page).map(x => x.id));
  }, [page]);

  const updateShapes = useCallback((filterCallback, property, value) => {
    getAllObjects().filter(filterCallback).forEach(sh => {
      sh.set(property, value);
    });
    canvasRef.current.renderAll();
  }, [canvasRef, getAllObjects]);

  useEffect(() => {
    setIsDrawing(false);

    setIsDeleting(false);
    if (canvasRef?.current) {
      canvasRef.current.defaultCursor = 'default'; // Reset cursor to default
    }
    setActiveFunction(Functions.NONE);

    if (currentSymbol === "all") {
      // setAlpha(defaultOpacity);
      // setConfidenceRate(defaultConfidenceRate);
      // setInputPointSize(defaultPointSize);
    } else {
      setAlpha(currentSymbolData["opacity"] ?? defaultOpacity);
      setConfidenceRate(currentSymbolData["confidence_rate"] ?? defaultConfidenceRate);
      setInputPointSize(currentSymbolData["point_size"] ?? defaultPointSize);
    }
  }, [currentSymbol]);

  useEffect(() => {
    if (currentSymbol !== "all") {
      dispatch(setSymbolOpacity({
        crop_id: currentSymbolData.crop_id,
        value: alpha
      }));
      updateShapes((sh) => sh.crop_id === currentSymbolData.crop_id && sh.crop_id !== undefined, "opacity", alpha);
    }
  }, [alpha]);

  useEffect(() => {
    if (currentSymbol !== "all") {
      dispatch(setSymbolConfidenceRate({
        crop_id: currentSymbolData.crop_id,
        value: confidenceRate
      }));
      updateShapes((sh) => sh.crop_id === currentSymbolData.crop_id && sh.crop_id !== undefined && sh.confidence_rate >= confidenceRate[0] && sh.confidence_rate <= confidenceRate[1], "visible", currentSymbolData.isVisible ?? true);
      updateShapes((sh) => sh.crop_id === currentSymbolData.crop_id && sh.crop_id !== undefined && (sh.confidence_rate < confidenceRate[0] || sh.confidence_rate > confidenceRate[1]), "visible", false);
    }
  }, [confidenceRate]);

  useEffect(() => {
    updateShapes((sh) => currentZones.includes(sh.zone_id) && sh.zone_id !== undefined, "visible", true);
    updateShapes((sh) => !currentZones.includes(sh.zone_id) && sh.zone_id !== undefined, "visible", false);
  }, [currentZones, updateShapes]);

  useEffect(() => {
    if (currentSymbol !== "all") {
      dispatch(setSymbolPointSize({
        crop_id: currentSymbolData.crop_id,
        value: inputPointSize
      }));
      dispatch(setPointSize(inputPointSize));
      updateShapes((sh) => sh.isType("point") && sh.crop_id === currentSymbolData.crop_id && sh.crop_id !== undefined, "radius", inputPointSize);
      updateShapes((sh) => sh.isType("sqpoint") && sh.crop_id === currentSymbolData.crop_id && sh.crop_id !== undefined, "width", inputPointSize*2);
      updateShapes((sh) => sh.isType("sqpoint") && sh.crop_id === currentSymbolData.crop_id && sh.crop_id !== undefined, "height", inputPointSize*2);
    }
  }, [inputPointSize]);

  useEffect(() => {
    if (activeFunction !== Functions.MANUAL) {
      setIsDrawing(false);
      if (canvasRef?.current) {
        canvasRef.current.defaultCursor = 'default'; // Reset cursor to default
      }
    }
    if (activeFunction !== Functions.DELETE_ITEM) {
      setIsDeleting(false);
    }
  }, [activeFunction]);

  useEffect(() => {
    for (let state of symbolsVisibility) {
      updateShapes((sh) => sh.crop_id === state.crop_id && sh.crop_id !== undefined && sh.confidence_rate >= state.confidence_rate[0] && sh.confidence_rate <= state.confidence_rate[1], "visible", state.isVisible);
      updateShapes((sh) => sh.crop_id === state.crop_id && sh.crop_id !== undefined && (sh.confidence_rate < state.confidence_rate[0] || sh.confidence_rate > state.confidence_rate[1]), "visible", false);
    }
  }, [symbolsVisibility]);

  useEffect(() => {
    if (page !== 0) {
      checkSegmentationReady(false);
    }
  }, [page]);

  const checkSegmentationReady = async (alert) => {
    let segmentation = segmentationReady;
    if (segmentationReady !== "ready" && segmentationReady !== "failed") {
      let payload = {
        "function": "check_segmentation_ready",
        "pdf_name": currentFile,
        "username": user,
        "project_name": projectName,
        "container_name": containerName,
        "page": page
      };

      let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);

      if (status_code === 200) {
          let segmentation = result.segmentation;
          dispatch(setSegmentationReady({ page: page - 1, segmentation: segmentation, filename: currentFile}));
      }
    }

    if (alert) {
      if (segmentation === "ready") {
        Alert("Fichier prêt",
            `La fonction de comptage automatique est à présent disponible pour la page ${page} du fichier ${currentFile}.`,
            {size: "lg"}
        );
      } else if (segmentation === "failed") {
        Alert("Fichier prêt",
          `La fonction de comptage automatique sur l'ensemble des symboles n'est pas disponible pour la page ${page} du fichier ${currentFile}. Vous pouvez cependant utiliser les autres fonctions disponibles et compter les symboles individuellement!`,
          {size: "lg"}
        );
      } else {
        Alert("Fichier en cours de préparation",
          `La fonction de comptage automatique sur l'ensemble des symboles est en cours de préparation pour la page ${page}. Cela peut prendre quelques minutes. N'hésitez pas à réactualiser de temps en temps si le temps d'attente est supérieur à 5 minutes.`,
          {size: "lg"}
        );
      }
    }
  };

  const restoreZones = (page) => {
    for(let zone of zones.filter(x=>x.page === page)) {
      insertPolygon(zone["points"], {
        id: zone["id"],
        zone_id: zone["zone_id"],
        stroke: zone["color"],
        fill: zone["color"],
        opacity: .2,
        strokeWidth: 2,
        objectCaching: false,
        visible: true,
        layer: zoneLayerId,
        fixed: true,
        selectable: false,
        deletable: false,
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true
      });
    }
  };
  const restoreCountItems = (page) => {
    for(let item of countedItems.filter(x=>x.page === page)) {
      let symbol = symbols.find(x=>x.crop_id === item.crop_id);

      if (symbol === undefined) {
        continue;
      }

      symbol = {...symbol, confidence_rate: symbol["confidence_rate"] ?? defaultConfidenceRate};
      const itemData = {
        id: item["id"],
        crop_id: item["crop_id"],
        radius: symbol["point_size"] ?? inputPointSize,
        stroke: symbol["color"],
        fill: symbol["color"],
        opacity: symbol["opacity"],
        selectable: true,
        confidence_rate: item["confidence_rate"],
        page: item["page"],
        strokeWidth: 2,
        visible: item["confidence_rate"] >= symbol["confidence_rate"][0] && item["confidence_rate"] <= symbol["confidence_rate"][1],
        layer: countLayerId,
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true
      };

      if (item.confidence_rate === 1) {
        insertSquarePoint(item.x, item.y, itemData);
      } else {
        insertPoint(item.x, item.y, itemData);
      }
    }
  };
  
  function drawAIHits(hits, recommended_thresholds) {
    const [originX, originY] = [0, 0];
    for(let item of hits.filter(x=>x.page === page)) {
      let symbol = symbols.find(x=>x.crop_id === item.crop_id);
      symbol = {...symbol, confidence_rate: symbol["confidence_rate"] ?? defaultConfidenceRate};
      const id = uuidv4();

      if (item["page"] === page) {
        const itemData = {
          id: id,
          crop_id: item["crop_id"],
          radius: symbol["point_size"] ?? inputPointSize,
          stroke: symbol["color"],
          fill: symbol["color"],
          opacity: symbol["opacity"],
          selectable: true,
          confidence_rate: item["confidence_rate"],
          page: item["page"],
          strokeWidth: 2,
          visible: item["confidence_rate"] >= symbol["confidence_rate"][0] && item["confidence_rate"] <= symbol["confidence_rate"][1],
          layer: countLayerId,
          lockRotation: true,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true
        };
        
        let x = (item.x - originX) * Math.cos(angle * Math.PI / 180) - (item.y - originY) * Math.sin(angle * Math.PI / 180) + originX;
        let y = (item.x - originX) * Math.sin(angle * Math.PI / 180) + (item.y - originY) * Math.cos(angle * Math.PI / 180) + originY;

        if (item.confidence_rate === 1) {
          insertSquarePoint(x, y, itemData);
        } else {
          insertPoint(x, y, itemData);
        }
      }

      dispatch(addCount({
        id: id,
        crop_id: item["crop_id"],
        name: symbol.name,
        confidence_rate: item.confidence_rate,
        angle: 0,
        manual: activeFunction === Functions.MANUAL,
        x: item.x,
        y: item.y,
        page: page,
        symbol: item.confidence_rate === 1 ? "square" : "circle",
        points: item["points"]
      }));
    }

    for (const [key, value] of Object.entries(recommended_thresholds)) {
      if (currentSymbol === key) {
        setConfidenceRate([value, 1]);
      }

      dispatch(setSymbolConfidenceRate({
        crop_id: key,
        value: [value, 1]
      }));
    }
  };

  const countManual = () => {
    if (activeFunction === Functions.MANUAL) {
      setActiveFunction(Functions.NONE);
    } else {
      setActiveFunction(Functions.MANUAL);
      setIsDeleting(false);
      let currentSymbolData = symbols.find(x => x.crop_id === currentSymbol);
      if (currentSymbolData) {
        dispatch(setFillColor(currentSymbolData.color));
        dispatch(setOpacity(currentSymbolData.opacity));
        setShapeType(ShapeType.SQUAREPOINT);
        startDrawing();
      }
      else {
        setActiveFunction(Functions.NONE);
      }
    }
  };

  const countAuto = async (mode, useRotations) => {
    setActiveFunction(mode === "image" ? Functions.AUTO_IMAGE : Functions.AUTO_VECTOR);
    setIsDeleting(false);
    
    setLoadingMessage(`Dénombrement du symbole ${currentSymbolData["name"]} en cours ...`);
    setIsLoading(true);

    let payload = {
        "function": "count_items",
        "pdf_name": currentFile,
        "username": user,
        "project_name": projectName,
        "container_name": containerName,
        "existing_hits": countedItems.filter(x=>x.page === page),
        "pages": [page],
        "symbols": [currentSymbol],
        "is_image_mode": mode === "image",
        "zones": zones.filter(x => currentZones.includes(x.zone_id)),
        "crops": symbols,
        "useRotations": useRotations ?? false
    };

    let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);

    if (status_code === 200) {
        let hits = result.hits;
        let recommended_thresholds = result.best_thresholds;
        
        drawAIHits(hits, recommended_thresholds);
    }
    
    setIsLoading(false);
    setActiveFunction(Functions.NONE);

  };

  const countAll = async () => {
    setIsDeleting(false);
    
    setLoadingMessage(`Dénombrement en cours ...`);
    setIsLoading(true);
    

    let payload = {
        "function": "classify_items",
        "pdf_name": currentFile,
        "username": user,
        "project_name": projectName,
        "container_name": containerName,
        "existing_hits": countedItems.filter(x=>x.page === page),
        "zones": zones.filter(x => currentZones.includes(x.zone_id)),
        "page_number": page,
    };

    let [status_code, result] = await launch_request(config.apiSegmentationUri, payload, {alertError: false});

    if (status_code === 200) {
        let hits = result.hits;
        let recommended_thresholds = result.best_thresholds;
        
        drawAIHits(hits, recommended_thresholds);
    }
    
    setIsLoading(false);
  };

  const deleteItem = () => {
    if (activeFunction === Functions.DELETE_ITEM) {
      setActiveFunction(Functions.NONE);
    } else {
      setActiveFunction(Functions.DELETE_ITEM);
      setIsDeleting(true);
    }
  };

  const deleteAllItems = () => {
    setActiveFunction(Functions.DELETE_ALL);
    YesNoAlert({
      title: "Supprimer",
      body: `Voulez-vous vraiment supprimer tous les éléments comptés ${currentSymbol === "all" ? "" : `du type ${currentSymbolData.name}`}?`,
      onYes: () => {
        setIsDeleting(false);
        const countLayer = layers.find(x => x.name === "count");
        if (countLayer) {
          let objs = [];
          if (currentSymbol === "all") {
            objs = getAllObjects().filter(x=>x.layer === countLayer.layerId);
          }
          else {
            objs = getAllObjects().filter(x=>x.layer === countLayer.layerId && x.crop_id === currentSymbol);
          }

          dispatch(removeHits(objs.map(x=>x.id)));

          objs.forEach(sh => {
            sh.definitiveDelete = true;
          });
          removeObjects(objs.map(x => x.id));
        }
        setActiveFunction(Functions.NONE);
      },
      onNo: () => {
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      },
      onClose: () => {
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      }
    });
  };

  return (<>
    <PDFEditor
        initialLayers={["0", "background", "zone", "count"]}
        page={page}
        setPage={setPage}
        toolsPanel={
          <div className="h-full top-0 absolute overflow-hidden right-0 z-50">
            <SideMenu
              setIsLoading={setIsLoading}
              setLoadingMessage={setLoadingMessage}
              activeFunction={activeFunction}
              setActiveFunction={setActiveFunction}
              countManual={countManual}
              countAuto={countAuto}
              countAll={countAll}
              checkSegmentationReady={checkSegmentationReady}
              deleteItem={deleteItem}
              deleteAllItems={deleteAllItems}
              page={page}
              currentSymbol={currentSymbol}
              currentSymbolData={currentSymbolData}
              setCurrentSymbol={setCurrentSymbol}
              currentZones={currentZones}
              setCurrentZones={setCurrentZones}
              confidenceRate={confidenceRate}
              setConfidenceRate={setConfidenceRate}
              inputPointSize={inputPointSize}
              setInputPointSize={setInputPointSize}
              alpha={alpha}
              setAlpha={setAlpha}
              sidemenus={sidemenus}
            />
          </div>}
        visiblePages={selectedPages}
        pageCount={currentFileData === undefined ? 1 : currentFileData.pages.length}
        getImages={(p) => {
          return currentFilePages[p]["patches"];
      } }
        restoreShapes={(page) => {
          restoreZones(page);
          restoreCountItems(page);
        }}
      />
      {children}
    </>);
}

