import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Listbox, ListboxItem, ScrollShadow, Spacer } from "@heroui/react";
import { Icon } from '@iconify/react/dist/iconify.js';
import YoutubeVideo from '../youtube';

// HelpModal component using forwardRef
const HelpModal = forwardRef(({
    isOpen, onOpen, onClose,
    videoUrl,
    ...props }, ref) => {
  const [selectedVideo, setSelectedVideo] = useState(Array.isArray(videoUrl) ? (videoUrl.length === 0 ? undefined : videoUrl[0].title) : videoUrl);
  const selectedVideoUrl = useMemo(() => {
    if (Array.isArray(videoUrl)) {
      return videoUrl.find(x => x.title === selectedVideo)?.url;
    }
    
    return videoUrl;
  }, [videoUrl, selectedVideo]);
  
  // State to store the current screen size
  const [screenSize, setScreenSize] = useState(getScreenSize());

  // Function to get the current screen size
  function getScreenSize() {
    if (window.innerWidth >= 1024) return 'lg';
    if (window.innerWidth >= 768) return 'md';
    return 'sm';
  }

  // Hook to update the screen size on window resize
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Expose open and close methods to the parent via ref
  useImperativeHandle(ref, () => ({
    openModal: onOpen,
    closeModal: onClose,
  }));

  const onVideoSelect = (keys) => {
    setSelectedVideo(Array.from(keys)[0]);
  };

  const renderCategories = useCallback((videoUrls) => {
    return (
      <Listbox
        disallowEmptySelection
        hideSelectedIcon
        aria-label="Categories"
        classNames={{
          base: "flex-row lg:flex-col p-0",
          list: "gap-2 flex-row lg:flex-col",
        }}
        defaultSelectedKeys={[selectedVideo]}
        selectionMode="single"
        variant="flat"
        onSelectionChange={onVideoSelect}
      >
        {videoUrls.map((item) => (
          <ListboxItem
            key={item.title}
            className={[
              "h-[50px]",
              "gap-3",
              "py-2",
              "bg-default-200/50",
              "text-medium",
              "text-default-500",
              "data-[hover=true]:bg-default-400/40",
              "data-[selected=true]:bg-default-400/50",
              "data-[selected=true]:text-white",
              "data-[selected=true]:focus:bg-default-400/40",
            ]}
            startContent={<Icon className="text-default-400" icon={item.icon} width={20} />}
            textValue={item.title}
          >
            <span className="flex w-[100px]">{item.title}</span>
          </ListboxItem>
        ))}
      </Listbox>
    );
  }, [selectedVideo]);

  const renderMenuVideos = useCallback((videosUrls) => {
      return <div className="relative flex flex-col lg:flex-row gap-4 justify-center">
        {/* Category (Web) */}
        <div className="col-span-1 border-r-1 border-white/10">
        <ScrollShadow hideScrollBar className='max-h-[315px] max-w-[560px] flex flex-row lg:flex-col' orientation={screenSize === "lg" ? "vertical" : "horizontal"}>
          {renderCategories(videosUrls)}
        </ScrollShadow>
        </div>

        {/* Scrollable Items */}
        <div className="col-span-3 mx-auto">
          {selectedVideoUrl && <YoutubeVideo videoUrl={selectedVideoUrl} />}
        </div>
      </div>;
  }, [renderCategories, selectedVideoUrl]);

  
  const renderUniqueVideo = useCallback((videoUrl) => {
    return <>
        <p className="text-small font-normal text-default-500">
            Voici le tutoriel vidéo du service
        </p>
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
            <YoutubeVideo videoUrl={videoUrl} />
        </div>
      </>;
}, []);

  const renderVideo = useCallback(() => {
    if (typeof videoUrl === "string") {
      return renderUniqueVideo(videoUrl);
    } else if (Array.isArray(videoUrl)) {
      return renderMenuVideos(videoUrl);
    } else {
        return <div style={{ padding: '20px', backgroundColor: '#f0f0f0', textAlign: 'center' }}>
          <p className="text-small font-normal text-default-500">Nous travaillons sur le tutoriel vidéo. Restez à l'affût, il arrive très bientôt !</p>
        </div>;
    }
  }, [renderMenuVideos, renderUniqueVideo, videoUrl]);

  return (
    <>
      {/* Modal Component */}
      <Modal isOpen={isOpen} isDismissable={true} isKeyboardDismissDisabled={false} onClose={onClose} aria-labelledby="modal-title" className='w-fit max-w-fit'>
        <ModalContent>
          <ModalHeader>
            <Icon
              className="flex-none text-default-500"
              icon="solar:help-bold-duotone"
              width={24}
            />
            <Spacer x={2} />
            <h1 className="text-xl">
                Aide
            </h1>
          </ModalHeader>
          <ModalBody>
            {/* YouTube Video Embed */}
            {renderVideo()}
          </ModalBody>
          <ModalFooter>
            <Button auto flat color="error" onPress={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

export default HelpModal;


