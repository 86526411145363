import React, {forwardRef} from "react";
import {Textarea} from "@heroui/react";

const styles = {
  label: "text-black/50 dark:text-white/90",
  input: [
    "bg-transparent",
    "text-black/90 dark:text-white/90",
    "placeholder:text-default-700/50 dark:placeholder:text-white/60",
  ],
  base: "bg-transparent",
  inputWrapper: [
    "shadow-xl",
    "bg-white/35",
    "border-white/25",
    "dark:bg-default/60",
    "backdrop-blur-xl",
    "hover:bg-default-200/70",
    "focus-within:!bg-default-200/50",
    "dark:hover:bg-default/70",
    "dark:focus-within:!bg-default/60",
    "!cursor-text",
  ],
};

const GlassTextarea = forwardRef((props, ref) => {
    return (
        <Textarea
            {...props}
            classNames={styles}
        />
    );
});

export default GlassTextarea;