"use client";

import React from "react";
import {Button, Image} from "@heroui/react";
import {Icon} from "@iconify/react";
import { useMsal } from "@azure/msal-react";
import { graphScope } from "../../auth-config";
import { DianeTransitionIAIcon } from "../../components/Icons";

export default function LoginPage() {
    const { instance } = useMsal();

    const buttonClasses = "bg-foreground/10 dark:bg-foreground/20";

    const handleLogin = () => {
        instance.clearCache();
        instance.loginPopup(graphScope).catch(e => {
            console.error(e);
        });
    };

    return (
        <div className="flex h-screen w-screen items-center justify-center p-2 sm:p-4 lg:p-8 bg-hero-section-centered-navbar" style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
        }}>
            <div className="flex w-full max-w-sm flex-col gap-6 rounded-large bg-background/60 px-8 pb-2 pt-16 shadow-small backdrop-blur-md backdrop-saturate-150 dark:bg-default-100/50">
                {/* <Image src="/logo/diane/color.png" className="max-w-[40%] mx-auto" /> */}
                <DianeTransitionIAIcon className="max-w-[40%] mx-auto" />
                
                <div className="flex flex-col gap-2 mt-4">
                <Button className={buttonClasses} startContent={<Icon icon="logos:microsoft-icon" width={24} />} onClick={handleLogin}>
                    Se connecter avec Microsoft
                </Button>
                </div>
                <label className="text-right text-sm">Version 1.0.1</label>
            </div>
        </div>
    );
}
