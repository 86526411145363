import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import _ from 'underscore';
import PDFEditor from "../../../../components/polygon-editor/pdf-editor";
import SymbolSelector from "./symbolSelector";
import { useDispatch, useSelector } from "react-redux";
import SideMenu, { Functions } from "./sideMenu";
import { CanvasContext, CanvasProvider } from "../../../../components/polygon-editor/contexts/canvas-context";
import { SymbolsProvider } from "./provider";
import { DrawShapeProvider, useDrawShape } from "../../../../components/polygon-editor/contexts/draw-context";
import { ShapeType } from "../../../../components/polygon-editor/hooks/draw";
import { addLegendStore, removeLegendStore, addLinearStore, addSymbolStore, changeLinearColorStore, changeSymbolColorStore, removeLinearStore, removeSymbolStore, updateSymbols } from "../../../../store/features/Comptage/configuration";
import { removeSymbolHits, removeAllMetre } from "../../../../store/features/Comptage/decompte";
import { setFillColor, setOpacity, setStrokeWidth } from "../../../../store/components/PolygonEditor/CanvasSlice";
import { fabric } from 'fabric';
import Colors from "../color";
import { v4 as uuidv4 } from 'uuid';
import { addObjectInLayer } from "../../../../store/components/PolygonEditor/ImageSlice";
import YesNoAlert, { DianeYesNoModal } from "../../../../components/modals/AlertYesNo";
import Alert from "../../../../components/modals/Alert";
import { defaultConfidenceRate, defaultOpacity, defaultPointSize } from "../count";
import useExtract from "../../../../components/polygon-editor/hooks/extract";
import { Image, useDisclosure } from "@heroui/react";

import { useOutletContext } from "react-router-dom";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import config from "../../../../config";

const containerName = "comptagev2";

const Symbols = () => {
  const {isLoading, setIsLoading, loadingMessage, setLoadingMessage, page, setPage} = useOutletContext();

  fabric.Group.prototype.hasControls = false;
  fabric.Group.prototype.lockMovementX = false;
  fabric.Group.prototype.lockMovementY = false;
  fabric.Group.prototype.lockRotation = false;

  return (
    <CanvasProvider className="overflow-hidden h-[99vh]">
      <SymbolsComponent
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        loadingMessage={loadingMessage}
        setLoadingMessage={setLoadingMessage}
        page={page}
        setPage={setPage}
      />
    </CanvasProvider>
  );
};

const SymbolsComponent = ({
    isLoading,
    setIsLoading,
    loadingMessage,
    setLoadingMessage,
    page,
    setPage
}) => {
  const dispatch = useDispatch();
  const [activeFunction, setActiveFunction] = useState(Functions.NONE);
  const { angle, removeObjects } = useContext(CanvasContext);
  const nbSymbols = useSelector((state) => state.Comptage.Configuration.symbols.filter(x =>x.page === page).length, _.isEqual);
  const pages = useSelector((state) => state.Comptage.Configuration.pages);

  const symbolLayerId = useSelector((state) => {
    let symbol = state.image.layers.find(x=>x.name === "symbol");
    if (symbol) {
      return symbol.layerId;
    }
    else {
      return -1;
    }
  });

  const pageDims = useMemo(() => {
    const currentPage = pages.find(x => x.page === page-1);
    if (currentPage) {
      return {width: currentPage.width, height: currentPage.height};
    } else {
      return undefined;
    }
  }, [page, pages]);

  useEffect(() => {
      dispatch(setStrokeWidth(1));
    }, []);

  const addSymbol = useCallback((shape) => {
    dispatch(setFillColor(Colors[(nbSymbols + 1) % Colors.length].value));
    let isFullLegend = (activeFunction === Functions.LEGEND);
    shape.selectable = true;
    shape.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: false,
      br: false,
      tl: false,
      tr: false,
      mtr: false
    });        
    shape.lockRotation = true;
    shape.lockMovementX = true;
    shape.lockMovementY = true;
    shape.lockScalingX = true;
    shape.lockScalingY = true;
    shape.layer = symbolLayerId;

    let pts = (shape.isType("rect") ? [{
          x: shape.left,
          y: shape.top
      }, {
          x: shape.left + shape.width,
          y: shape.top
      }, {
          x: shape.left + shape.width,
          y: shape.top + shape.height
      }, {
          x: shape.left,
          y: shape.top + shape.height
      }] : (shape.isType("polygon") ? shape.points : []));

    
    if (angle !== 0) {
      var [originX, originY] = [0, 0];

      pts = Array.from(pts).map(x=>{return {
          x: (x.x - originX) * Math.cos(-angle * Math.PI / 180) - (x.y - originY) * Math.sin(-angle * Math.PI / 180) + originX,
          y: (x.x - originX) * Math.sin(-angle * Math.PI / 180) + (x.y - originY) * Math.cos(-angle * Math.PI / 180) + originY
      }});
    }

    const isInDoc = pts.every(pt => {
      if (pageDims) {
        return (pt.x >= 0) && (pt.x <= pageDims.width) && (pt.y >= 0) && (pt.y <= pageDims.height);
      } else {
        return true;
      }
    });

    if (isInDoc || isFullLegend) {
          let symbol = {
            "id": shape.id,
            "crop_id": shape.id,
            "page": page,
            "points": pts,
            "color": shape.fill,
            "confidence_rate": defaultConfidenceRate,
            "isVisible": true,
            "point_size": defaultPointSize,
            "opacity": defaultOpacity,
            "name": `Symbole${nbSymbols + 1}`
          };
      
          dispatch(addObjectInLayer({layerId: symbolLayerId, object: shape.id})); // Add the object's ID to Redux store
          
          if (isFullLegend) {
            symbol["angle"] = angle;
            dispatch(addLegendStore(symbol));
          } else {
            dispatch(addSymbolStore(symbol));
          };
    } else {
      shape.definitiveDelete = true;
      removeObjects([shape.id]);
      Alert("Symbole hors du plan", "Ce symbole n'est pas entièrement dans le plan. Il ne sera pas pris en compte.");
    }

  }, [dispatch, nbSymbols, activeFunction, symbolLayerId, angle, pageDims, page, removeObjects]);

  const removeSymbol = useCallback((shape) => {
    dispatch(removeSymbolStore(shape.id));
    dispatch(removeLegendStore(shape.id));
    dispatch(removeSymbolHits(shape.id));
  }, [dispatch]);

    return <SymbolsProvider
      addSymbol={addSymbol}
      removeSymbol={removeSymbol}
    >
      <DrawShapeProvider>
        <SymbolEditor
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          loadingMessage={loadingMessage}
          setLoadingMessage={setLoadingMessage}
          page={page}
          setPage={setPage}
          activeFunction={activeFunction}
          setActiveFunction={setActiveFunction}
        />
      </DrawShapeProvider>
    </SymbolsProvider>;
};

const ToolPanel = ({
  activeFunction,
  setActiveFunction,
  setLoadingMessage,
  onOpen,
  page,
  setIsLoading,
  candidateSymbols,
  setCandidateSymbols
}) => {
  const { launch_request } = useAzureFuncApp();
  const dispatch = useDispatch();

  const [checkChangeName, setCheckChangeName] = useState(false);

  const projectName = useSelector((state) => state.Comptage.ProjectInfos.project_name);
  const user = useSelector((state) => state.Comptage.ProjectInfos.creator_name);
  const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
  const { angle } = useContext(CanvasContext);

  const { canvasRef, setIsDeleting, setShapeType, getAllObjects, removeObjects, getObjectById } = useContext(CanvasContext);
  const { startDrawing, insertPolygon, zoomObjects } = useDrawShape();
  const { extractContentWithinShape } = useExtract();
  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const legend = useSelector((state) => state.Comptage.Configuration.legend);
  const linears = useSelector((state) => state.Comptage.Configuration.linears);

  const symbolLayerId = useSelector((state) => {
    let symbol = state.image.layers.find(x=>x.name === "symbol");
    if (symbol) {
      return symbol.layerId;
    }
    else {
      return -1;
    }
  });
  
  const backgroundLayerId = useSelector((state) => {
    let background = state.image.layers.find(x=>x.name === "background");
    if (background) {
      return background.layerId;
    }
    else {
      return -1;
    }
  });
  
  const addLegend = useCallback(() => {
    if (legend?.id) {
      let obj = getObjectById(legend?.id);
      if (obj) {
        obj.definitiveDelete = true;
        removeObjects([obj.id]);
        setCandidateSymbols({});
      }
    };
    setActiveFunction(Functions.LEGEND);
    setIsDeleting(false);
    dispatch(setFillColor("#dfdfdf"));
    dispatch(setOpacity(.4));
    setShapeType(ShapeType.RECT);
    startDrawing();
  }, [dispatch, getObjectById, legend?.id, removeObjects, setActiveFunction, setCandidateSymbols, setIsDeleting, setShapeType, startDrawing]);

  const extractLegend = useCallback(async () => {
    if (legend?.points) {
      setLoadingMessage("Extraction de la légende en cours...");
      setIsLoading(true);

      if (legend?.id) {
        let obj = getObjectById(legend?.id);
        if (obj) {obj.visible = false};
      };
      let legendItems = Object.values(candidateSymbols);

      // Only get the candidate symbols the first time we click on "Extraire légende"
      if ((Object.keys(candidateSymbols).length === 0) || (angle !== legend?.angle)) {
        if (angle !== legend?.angle) {
          dispatch(addLegendStore({...legend, "angle": angle}));
        };

        let payload = {
          "function": "extract_legend",
          "username": user,
          "project_name": projectName,
          "container_name": containerName,
          "pdf_name": currentFile,
          "page_number": page,
          "legend_position": legend.points,
          "legend_id": legend.id
        };

        let [status_code, result] = await launch_request(config.apiSegmentationUri, payload, {alertError: false});

        if (status_code === 200) {
          legendItems = result.legend_items;
        }
        else {
          setIsLoading(false);
          return;
        }
      }

      setIsLoading(false);
      onOpen();
      const currentZoom = canvasRef.current.getZoom();
      var [originX, originY] = [0, 0];

      legendItems.forEach((item, index) => {

        //Check if this item is already in symbols
        let symbol = symbols.find(s => ((JSON.stringify(s.points) === JSON.stringify(item.points)) && (page === s.page)));
        let shape;
        if (symbol) {
          item.id = symbol.id;
          item.name = symbol.name;
          shape = getObjectById(symbol.id);
          shape.visible = true;
          item.selected = true;
        } else {
          let points = (
            angle === 0 ? item["points"]
                        : item["points"].map(point => {
                            return {
                              "x" : (point.x - originX) * Math.cos(angle * Math.PI / 180) - (point.y - originY) * Math.sin(angle * Math.PI / 180) + originX,
                              "y" : (point.x - originX) * Math.sin(angle * Math.PI / 180) + (point.y - originY) * Math.cos(angle * Math.PI / 180) + originY
                            };
                          })
          );

          shape = insertPolygon(points, {
            stroke: Colors[index % Colors.length].value,
            fill: Colors[index % Colors.length].value,
            opacity: .4,
            selectable: false,
            strokeWidth: 2,
            objectCaching: false,
            visible: false,
            layer: symbolLayerId,
            lockRotation: true,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true
          });
      
          shape.setControlsVisibility({
              mt: false,
              mb: false,
              ml: false,
              mr: false,
              bl: false,
              br: false,
              tl: false,
              tr: false,
              mtr: false
            });

          item.id = shape.id;
          item.name = item.labels[0].value;
          item.selected = false;
        };
        
        if (index === 0) {
          canvasRef?.current.setZoom(1);
          const symbolZoom = Math.min(canvasRef?.current.width / (shape.width * 1.05), canvasRef?.current.height / (shape.height * 1.05)) / 10;
          canvasRef?.current.setZoom(symbolZoom);
        };
    
        item.imageUrl = extractContentWithinShape(shape, [backgroundLayerId]);
      });
      canvasRef?.current.setZoom(currentZoom);
      setCandidateSymbols(legendItems.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {}));

      setIsLoading(false);
    } else {
      Alert("Veuillez indiquer l'emplacement de la légende (détourer légende)");
    };
  }, [angle, backgroundLayerId, candidateSymbols, canvasRef, currentFile, dispatch, extractContentWithinShape, getObjectById, insertPolygon, launch_request, legend, onOpen, page, projectName, setCandidateSymbols, setIsLoading, setLoadingMessage, symbolLayerId, symbols, user]);

  const addPolygonalSymbol = useCallback(() => {
    if (activeFunction === Functions.POLY) {
      setActiveFunction(Functions.NONE);
    } else {
      setActiveFunction(Functions.POLY);
      setIsDeleting(false);
      dispatch(setFillColor(Colors[symbols.length % Colors.length].value));
      dispatch(setOpacity(.4));
      setShapeType(ShapeType.POLYGON);
      startDrawing();
    }
  }, [activeFunction, dispatch, setActiveFunction, setIsDeleting, setShapeType, startDrawing, symbols.length]);
  const addRectangularSymbol = useCallback(() => {
    if (activeFunction === Functions.RECT) {
      setActiveFunction(Functions.NONE);
    } else {
      setActiveFunction(Functions.RECT);
      setIsDeleting(false);
      dispatch(setFillColor(Colors[symbols.length % Colors.length].value));
      dispatch(setOpacity(.4));
      setShapeType(ShapeType.RECT);
      startDrawing();
    }
  }, [activeFunction, dispatch, setActiveFunction, setIsDeleting, setShapeType, startDrawing, symbols.length]);

  const deleteSymbol = useCallback(() => {
    if (activeFunction === Functions.DELETE) {
      setActiveFunction(Functions.NONE);
    } else {
      setActiveFunction(Functions.DELETE);
      setIsDeleting(true);
    }
  }, [activeFunction, setActiveFunction, setIsDeleting]);
  
  const deleteAllSymbols = useCallback(() => {
    setActiveFunction(Functions.DELETE_ALL);
    YesNoAlert({
      title: "Supprimer",
      body: "Voulez-vous vraiment supprimer tous les symboles ?",
      onYes: () => {
        setIsDeleting(false);
        let objs = getAllObjects().filter(x=>!x.isBackground);
        objs.forEach(sh => {
          sh.definitiveDelete = true;
        });
        removeObjects(objs.map(x => x.id));
        setActiveFunction(Functions.NONE);
      },
      onNo: () => {
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      },
      onClose: () => {
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      }
    });
  }, [getAllObjects, removeObjects, setActiveFunction, setIsDeleting]);

  const zoomItem = useCallback((id) => {
    zoomObjects([id], 1.3);
  }, [zoomObjects]);

  const deleteItem = useCallback((id) => {
    let objs = getAllObjects().filter(x=>x.id === id);
    objs.forEach(sh => {
      sh.definitiveDelete = true;
    });
    removeObjects(objs.map(x => x.id));
  }, [getAllObjects, removeObjects]);

  const changeSymbolColor = useCallback((id, newColor) => {
    let obj = getObjectById(id);
    if (obj) {
      obj.stroke = newColor;
      obj.fill = newColor;
    }
    canvasRef.current.renderAll();

    dispatch(changeSymbolColorStore({id, newColor}));
  }, [canvasRef, dispatch, getObjectById]);

  const addLinear = useCallback(() => {
    let id = uuidv4();
    dispatch(addLinearStore({
      id,
      linear_id: id,
      name: `Linéaire${linears.length + 1}`,
      color: Colors[linears.length % Colors.length].value,
      isVisible: true
    }));
  }, [dispatch, linears.length]);

  const removeLinear = useCallback((id) => {
    dispatch(removeLinearStore(id));
    dispatch(removeAllMetre(id));
  }, [dispatch]);

  const changeLinearColor = useCallback((id, newColor) => {
    dispatch(changeLinearColorStore({id, newColor}));
  }, [dispatch]);

  return <div className="h-full top-0 absolute overflow-hidden right-0 z-50">
    <SideMenu
      activeFunction={activeFunction}
      addLegend={addLegend}
      extractLegend={extractLegend}
      addPolygonalSymbol={addPolygonalSymbol}
      addRectangularSymbol={addRectangularSymbol}
      deleteSymbol={deleteSymbol}
      deleteAllSymbols={deleteAllSymbols}
      zoomItem={zoomItem}
      deleteItem={deleteItem}
      changeSymbolColor={changeSymbolColor}
      page={page}
      addLinear={addLinear}
      removeLinear={removeLinear}
      changeLinearColor={changeLinearColor}
      checkChangeName={checkChangeName}
      setCheckChangeName={setCheckChangeName}
    />
  </div>;
};

const LegendModal = ({
  isOpen,
  onOpenChange,
  onClose,
  page,
  candidateSymbols,
  setCandidateSymbols
}) => {
  const dispatch = useDispatch();
  const { canvasRef, setIsDrawing, setIsDeleting, setShapeType, setDrawMultipleShapes, getAllObjects, removeObjects, getObjectById } = useContext(CanvasContext);
  const { extractContentWithinShape } = useExtract();

  const legend = useSelector((state) => state.Comptage.Configuration.legend);
  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const [legendUrl, setLegendUrl] = useState("/images/pdf/undefined.png");
  const [checkChangeName, setCheckChangeName] = useState(false);
  
  const symbolLayerId = useSelector((state) => {
    let symbol = state.image.layers.find(x=>x.name === "symbol");
    if (symbol) {
      return symbol.layerId;
    }
    else {
      return -1;
    }
  });

  const backgroundLayerId = useSelector((state) => {
    let background = state.image.layers.find(x=>x.name === "background");
    if (background) {
      return background.layerId;
    }
    else {
      return -1;
    }
  });

  const selectedSymbols = useMemo(() => {
    return Object.values((candidateSymbols ?? {})).filter(x => x.id !== undefined && x.selected === true).map(x => x.id);
  }, [candidateSymbols]);

  const makeLegendVisible = useCallback(() => {
    if (legend?.id) {
      let obj = getObjectById(legend.id);
      if (obj) {obj.visible = true};
    }
    onClose();
  }, [getObjectById, legend.id, onClose]);

  const saveSelectedSymbols = useCallback(() => {
    Object.values(candidateSymbols).forEach(s => {
      if (s.selected) {
        let shape = getObjectById(s.id);
        let symbol = {...s, 
          "crop_id": s.id,
          "page": page,
          "color": shape.fill,
          "confidence_rate": defaultConfidenceRate,
          "isVisible": true,
          "point_size": defaultPointSize,
          "opacity": defaultOpacity};
        delete symbol["selected"];
        delete symbol["imageUrl"];
        if (!symbols.some(sym => sym.id === s.id)) { // Add symbol if it is not already in symbols.
          dispatch(addObjectInLayer({layerId: symbolLayerId, object: shape.id}));
          dispatch(addSymbolStore(symbol));
        } else {
          dispatch(updateSymbols([symbol]))
          setCheckChangeName(true);
        }
      } else {
        dispatch(removeSymbolStore(s.id)); // If the symbol was previously in the store but is now unselected, remove it.
      }
    });
    onClose();
  }, [candidateSymbols, dispatch, getObjectById, onClose, page, symbolLayerId, symbols]);

  // Makes symbols that are selected (with extractLegend) visible
  useEffect(() => {
    Object.values(candidateSymbols).forEach(s => {
      let shape = getObjectById(s.id);
      if (shape) {
        if (selectedSymbols.includes(s.id)) {
          shape.visible = true;
          shape.selectable = true;
        } else {
          shape.visible = false;
          shape.selectable = false;
        };
        canvasRef.current.renderAll();
      };
    });

    if (legend?.id) {
      const currentZoom = canvasRef.current.getZoom();
      canvasRef?.current.setZoom(1);

      let shape = getObjectById(legend.id);
      if (shape) {
        const legendZoom = Math.min(canvasRef?.current.width / (shape.width * 1.05), canvasRef?.current.height / (shape.height * 1.05)) * 2;
        canvasRef?.current.setZoom(legendZoom);
        let imageUrl = extractContentWithinShape(shape, [backgroundLayerId, symbolLayerId]);
        setLegendUrl(imageUrl);
        canvasRef?.current.setZoom(currentZoom);
      }};
  }, [selectedSymbols.length, candidateSymbols.length, legend?.id, legend?.angle]);


  return <DianeYesNoModal
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    title={<div>Symboles détectés</div>}
    body={ <SymbolSelector
              symbols={candidateSymbols}
              setSymbols={setCandidateSymbols}
              legendImage={legendUrl}
            />
          }
    onClose={makeLegendVisible}
    onYes={saveSelectedSymbols}
    onNo={onClose}
    yesText={"Valider"}
    noText={"Annuler"}         
    scrollBehavior="inside"
    isDismissable={false}
    classNames={{
      base: "min-w-[80vw] min-h-[80vh]"
    }}
  />
};

const SymbolEditor = ({ setIsLoading, setLoadingMessage, page, setPage, activeFunction, setActiveFunction }) => {

  const {isOpen, onOpen, onOpenChange, onClose} = useDisclosure();

  const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
  const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
  const currentFileData = projectFiles.find(x =>x.name === currentFile);
  const currentFilePages = useSelector((state) => state.Comptage.Configuration.pages);
  const selectedPages = useMemo(() => {
    return currentFilePages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]);
  }, [currentFilePages]);
  const { canvasRef, setIsDrawing, setIsDeleting, setDrawMultipleShapes } = useContext(CanvasContext);
  const { insertPolygon } = useDrawShape();
  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const legend = useSelector((state) => state.Comptage.Configuration.legend);

  const symbolLayerId = useSelector((state) => {
    let symbol = state.image.layers.find(x=>x.name === "symbol");
    if (symbol) {
      return symbol.layerId;
    }
    else {
      return -1;
    }
  });
  const [candidateSymbols, setCandidateSymbols] = useState({});

  useEffect(() => {
      if ((activeFunction === Functions.POLY) || (activeFunction === Functions.RECT)) {
        setDrawMultipleShapes(true);
      } else if (activeFunction === Functions.LEGEND) {
        setDrawMultipleShapes(false);
      } else {
        setIsDrawing(false);
        if (canvasRef?.current) {
          canvasRef.current.defaultCursor = 'default'; // Reset cursor to default
        }
      }
      if (activeFunction !== Functions.DELETE) {
        setIsDeleting(false);
      }
    }, [activeFunction]);

  const restoreSymbols = useCallback((page) => {
    for(let symbol of [...symbols.filter(x=>x.page === page), ...((legend?.page === page) ? [legend] : [])]) {
      let shape = insertPolygon(symbol["points"], {
        id: symbol["id"],
        crop_id: symbol["crop_id"],
        stroke: symbol["color"],
        fill: symbol["color"],
        opacity: .4,
        selectable: true,
        strokeWidth: 2,
        objectCaching: false,
        visible: true,
        layer: symbolLayerId,
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true
      });
 
      shape.selectable = true;
      shape.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false
        });        
      shape.lockRotation = true;
      shape.lockMovementX = true;
      shape.lockMovementY = true;
      shape.lockScalingX = true;
      shape.lockScalingY = true;
    }
  }, [insertPolygon, legend, symbolLayerId, symbols.length]);
  
  const getPageImages = useCallback((p) => {
    return currentFilePages[p]["patches"];
  }, [currentFilePages]);
  return  <>
      <PDFEditor
        initialLayers={["0", "background", "symbol"]}
        page={page}
        setPage={setPage}
        showScale={false}
        visiblePages={selectedPages}
        pageCount={currentFileData === undefined ? 1 : currentFileData.pages.length}
        getImages={getPageImages}
        restoreShapes={restoreSymbols}
      />
      <ToolPanel
        activeFunction={activeFunction}
        setActiveFunction={setActiveFunction}
        setLoadingMessage={setLoadingMessage}
        onOpen={onOpen}
        page={page}
        setIsLoading={setIsLoading}
        candidateSymbols={candidateSymbols}
        setCandidateSymbols={setCandidateSymbols} />
      <LegendModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onClose={onClose}
        page={page}
        candidateSymbols={candidateSymbols}
        setCandidateSymbols={setCandidateSymbols} />
    </>;
}

const MemoizedSymbols = React.memo(Symbols);
export default MemoizedSymbols;