import React, { useEffect, useRef, useState } from 'react';
import * as pdfjs from "pdfjs-dist";
import { cleanMarkdownText } from '../../utils/string';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.5.136/pdf.worker.min.mjs`;

const PDFHighlighter = ({ pdfUrl, searchQuery, currentPage, clearMarkdown=false }) => {
    const [localPdfUrl, setLocalPdfUrl] = useState(null);
    const iframeRef = useRef(null);
    const isInitialPageSet = useRef(false);
    const isViewerInitialized = useRef(false);
    
    const fetchPdf = async () => {
      try {
        const response = await fetch(pdfUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setLocalPdfUrl(url);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    useEffect(() => {
        if (pdfUrl) {
            fetchPdf();
        }
    
        return () => {
          if (localPdfUrl) {
            URL.revokeObjectURL(localPdfUrl);
          }
        };
    }, [pdfUrl]);

    const initializeViewer = (iframe) => {
        if (!localPdfUrl || isViewerInitialized.current) return;

        iframe.src = `/pdfjs/web/viewer.html?file=${encodeURIComponent(localPdfUrl)}`;

        const handleInitialLoad = () => {
            const iframeWindow = iframe.contentWindow;
            
            iframeWindow.PDFViewerApplication.initializedPromise.then(() => {
                isViewerInitialized.current = true;
                
                // Set initial page if needed
                if (!isInitialPageSet.current && currentPage > 1) {
                    iframeWindow.PDFViewerApplication.pdfViewer.currentPageNumber = currentPage;
                    isInitialPageSet.current = true;
                }

                // Perform initial search if query exists
                if (searchQuery) {
                    performSearch(iframeWindow);
                }
            });
        };

        iframe.addEventListener('load', handleInitialLoad, { once: true });
    };

    const performSearch = (iframeWindow) => {
        if (!iframeWindow?.PDFViewerApplication?.pdfViewer) return;
        let cleanedQuery = searchQuery;
        if (clearMarkdown) {
            const queries = Array.isArray(searchQuery) ? searchQuery : [searchQuery];
            cleanedQuery = queries.map(cleanMarkdownText)
        }
        
        // Cancel any existing search
        iframeWindow.PDFViewerApplication.pdfViewer.eventBus.dispatch('findbarclose', {});
        
        // Start new search if query exists
        if (cleanedQuery) {
            setTimeout(() => {
                iframeWindow.PDFViewerApplication.pdfViewer.eventBus.dispatch('find', {
                    caseSensitive: false,
                    findPrevious: false,
                    highlightAll: true,
                    phraseSearch: true,
                    query: cleanedQuery
                });
            }, 100); // Small delay to ensure previous search is cleared
        }
    };

    // Initialize viewer when URL is available
    useEffect(() => {
        if (iframeRef.current && localPdfUrl) {
            initializeViewer(iframeRef.current);
        }
    }, [localPdfUrl]);

    // Handle search query changes
    useEffect(() => {
        const iframeWindow = iframeRef.current?.contentWindow;
        if (isViewerInitialized.current && iframeWindow) {
            performSearch(iframeWindow);
        }
    }, [searchQuery]);

    // Handle page changes
    useEffect(() => {
        const viewer = iframeRef.current?.contentWindow?.PDFViewerApplication?.pdfViewer;
        if (viewer && currentPage && viewer.currentPageNumber !== currentPage) {
            viewer.currentPageNumber = currentPage;
        }
    }, [currentPage]);

    return (
        <iframe
            ref={iframeRef}
            title="PDF Viewer"
            width="100%"
            height="100%"
            style={{
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                backgroundColor: 'lightgrey',
            }}
        />
    );
};

export default PDFHighlighter;