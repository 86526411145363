import React from "react";
import { Card, CardBody, Divider } from "@heroui/react";

export default function TicketDetailCard({ ticket }) {
    if (!ticket) return null;

    const getDateString = (ticket) => {
        if (!ticket || !ticket?.ticketStartDateTime) return null;
        const interventionDate = new Date(ticket?.ticketStartDateTime);
        const formattedDate = interventionDate.toLocaleDateString("fr-FR", {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric",
        });
        const now = new Date();
        const diffDays = Math.floor((now - interventionDate) / (1000 * 3600 * 24));
        const relativeTime =
            diffDays < 7
            ? `il y a ${diffDays} jour${diffDays > 1 ? "s" : ""}`
            : `il y a ${Math.floor(diffDays / 7)} semaine${Math.floor(diffDays / 7) > 1 ? "s" : ""}`;
        const interventionText = `${formattedDate} (${relativeTime})`;
        return interventionText;
    }

    const getDurationString = (ticket) => {
        if (!ticket) return null;
        if (!ticket?.ticketStartDateTime || !ticket?.ticketClosingRealDate) return "Non renseigné";
        const startDate = new Date(ticket?.ticketStartDateTime);
        const closingDate = new Date(ticket?.ticketClosingRealDate);
        const durationMs = closingDate - startDate;
        const durationMinutes = Math.floor(durationMs / 60000);
        const durationHours = Math.floor(durationMinutes / 60);
        const durationRemaining = durationMinutes % 60;
        const startTime = ticket?.ticketStartDateTime ? new Date(ticket?.ticketStartDateTime).toLocaleTimeString("fr-FR") : "Non renseigné";
        const endTime = ticket?.ticketClosingRealDate ? new Date(ticket?.ticketClosingRealDate).toLocaleTimeString("fr-FR") : "Non renseigné";
        return durationMinutes >= 0 ? `${durationHours}h ${durationRemaining}min (${startTime} - ${endTime})` : "Durée négative";
    }

    const emptyStr = "Non renseigné";
    const subjectStr = ticket?.ticketSubject || emptyStr;
    const contractCodeStr = ticket?.contractCodexId || emptyStr;
    const dateStr = getDateString(ticket) || emptyStr;
    const contractNameStr = ticket?.contractCodexName || emptyStr;
    const ticketCodeStr = ticket?.ticketCode || emptyStr;
    const durationStr = getDurationString(ticket) || emptyStr;
    const technician = ticket?.plannedTechnician || emptyStr;
    const siteLocation = ticket?.siteName || emptyStr;


    return (
        <Card className="bg-white w-full p-4 gap-2 shadow-md border border-gray-200">
        <h3 className="font-bold">
            Détails de la demande de service
        </h3>
        <Divider />
        <CardBody>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <CellValue label="Sujet" value={subjectStr} />
            <CellValue label="Code Contrat" value={contractCodeStr} />
            <CellValue label="Date" value={dateStr} />
            <CellValue label="Nom du contrat" value={contractNameStr} />
            <CellValue label="Code DS" value={ticketCodeStr} />
            <CellValue label="Durée" value={durationStr} />
            <CellValue label="Site" value={siteLocation} />
            <CellValue label="Intervenant" value={technician} />
            </div>
        </CardBody>
        </Card>
    );
}


const CellValue = React.forwardRef(({ label, value, children, ...props }, ref) => (
    <div
        ref={ref}
        className="flex flex-col md:flex-row items-start md:items-center gap-1 border-b border-gray-100 pb-2"
        {...props}
    >
        <span className="text-sm text-gray-500 w-full md:w-1/3 truncate">{label}</span>
        <span className="text-sm font-medium text-gray-800 w-full md:w-2/3 truncate">{value || children}</span>
    </div>
));
  