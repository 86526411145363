import * as turf from "@turf/turf";

export const isPointInPolygon = function (polygon, point) {
    //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
    let odd = false;
    //For each edge (In this case for each point of the polygon and the previous one)
    for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
        //If a line from the point into infinity crosses this edge
        if (((polygon[i].y > point.y) !== (polygon[j].y > point.y)) // One point needs to be above, one below our y coordinate
            // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
            && (point.x < ((polygon[j].x - polygon[i].x) * (point.y - polygon[i].y) / (polygon[j].y - polygon[i].y) + polygon[i].x))) {
            // Invert odd
            odd = !odd;
        }
        j = i;

    }
    //If the number of crossings was odd, the point is in the polygon
    return odd;
};

export const getPolylineLength = function (points) {
    return points.reduce((length, point, i) => {
        if (i === 0) return length;
        const dx = point.x - points[i - 1].x;
        const dy = point.y - points[i - 1].y;
        return length + Math.sqrt(dx * dx + dy * dy);
    }, 0);
};

export const intersectionLengthLinePolygon = function (linePoints, polygonPoints) {
    // Create a LineString from the array of points
    let line = turf.lineString(linePoints.map(x => [x.x, x.y]));

    // Ensure the polygon is closed (first point == last point)
    let polygonCoords = polygonPoints.map(x => [x.x, x.y]);
    polygonCoords.push([polygonPoints[0].x, polygonPoints[0].y]);  // Closing the polygon

    // Create a Polygon from the array of points
    let polygon = turf.polygon([polygonCoords]);

    // Check if the line is fully within the polygon
    if (turf.booleanWithin(line, polygon)) {
        // If the line is fully inside the polygon, just calculate the length of the line
        let totalLength = getPolylineLength(linePoints);
        return totalLength;
    }

    // Get all segments (straight lines) of the multiline
    let lineSegments = [];
    for (let i = 0; i < linePoints.length - 1; i++) { 
        lineSegments.push([linePoints[i], linePoints[i + 1]]); 
    }

    // Cut every segment in subsegments (each subsegment is either fully in the polygon or fully outside of it)
    let splitLine = [];
    lineSegments.forEach(segment => {
        let segmentLine = turf.lineString(segment.map(x => [x.x, x.y]));

        // Intersections between the segment and the polygon
        let intersectionPoints = turf.lineIntersect(segmentLine, polygon).features.map(point => {
            let coords = point.geometry.coordinates;
            return {"x": coords[0], "y": coords[1]}
        });

        // Add the extremities
        intersectionPoints.push(...segment);

        // Sort by x increasing (all points are on the same line, so we can get them in order this way). If the line is vertical (x constant), sort by y increasing.
        if (intersectionPoints[0].x === intersectionPoints[1].x) {
            intersectionPoints.sort((a, b) => a.y - b.y);
        } else {
            intersectionPoints.sort((a, b) => a.x - b.x);
        }
        
        // Get the list of subsegments
        for (let i = 0; i < intersectionPoints.length - 1; i++) { 
            splitLine.push([intersectionPoints[i], intersectionPoints[i + 1]]); 
        }
    })

    // Keep segments that are in the polygon, by checking wether the middle point is in the polygon
    let intersectingLines = splitLine.filter(segment => {
        let middlePoint = {"x" : (segment[0].x + segment[1].x)/2, "y": (segment[0].y + segment[1].y)/2};
        return isPointInPolygon(polygonPoints, middlePoint);
    });

    // Return the sum of the lengths of all subsegments in the polygon
    if (intersectingLines.length > 0) {
        let totalLength = intersectingLines
            .map(i => getPolylineLength(i))
            .reduce((partialSum, a) => partialSum + a, 0);

        return totalLength;
    } else {
        return 0;
    }
};