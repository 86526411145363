
export const cleanMarkdownText = (markdownText) => {
    if (!markdownText) return "";
    return markdownText
        .replace(/<figure>[\s\S]*?<\/figure>/g, '')
        .replace(/<!--[\s\S]*?-->/g, '')
        .replace(/\[([^\]]+)\]\([^)]+\)/g, '$1')
        .replace(/!\[[^\]]*\]\([^)]+\)/g, '')
        .replace(/\\([\\`*{}[\]()#+\-.!_>])/g, '$1')
        .replace(/^#+\s+/gm, '')
        .replace(/^\s*\\-\s+/gm, '')
        .replace(/^\s*-\s+/gm, '')
        .replace(/[_*~`]/g, '')
        .replace(/ {2}\n/g, ' ')
        .replace(/^>\s/gm, '')
        .replace(/\s+/g, ' ')
        .trim();
    };