import React from "react";
import {
  Image,
  Tooltip,
} from "@heroui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import './widgets.css';

const Widget = ({
    tooltip="",
    tooltipPlacement="left",
    icon=undefined,
    id=undefined,
    action=undefined,
    classNames={
        button: "",
        icon: "",
    }}) => {
    return (<Tooltip placement={tooltipPlacement} content={tooltip}>
      <div className={`${classNames.button !== undefined ? classNames.button : ""}`} id={id} onClick={action ? action : (() => {})}>
        <Icon icon={icon} className={`text-black w-full scale-150 ${classNames.icon !== undefined ? classNames.icon : ""}`} />
      </div>
    </Tooltip>);
}

export default Widget;