import { Spacer } from "@heroui/react";
import MaintenanceLogo from "../../assets/maintenance";

const messages = [
  <>Notre équipe est à pied d'œuvre pour vous offrir une meilleure expérience très bientôt !<br />Merci de votre compréhension et à très vite !</>,
  <>Notre service est temporairement en maintenance. Nous travaillons activement pour améliorer votre expérience.<br />Merci pour votre patience et votre compréhension !</>,
  <>Notre équipe travaille sur des améliorations importantes. Le service est temporairement indisponible, mais nous ferons en sorte de revenir en ligne le plus vite possible.<br />Merci de votre compréhension !</>,
  <>Afin d'améliorer nos performances, nous effectuons actuellement une maintenance sur le service. Nous serons de retour très bientôt !<br />Merci de votre patience.</>,
  <>Le service est temporairement hors ligne pour maintenance. Notre équipe travaille pour vous offrir des améliorations.<br />Merci de votre compréhension et à très vite !</>
];
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
const index = getRandomInt(messages.length);

const PageMaintenance = function () {
  return (
    <div className="page-content w-full h-full flex flex-row max-md:flex-col items-center justify-center p-12">
      <MaintenanceLogo className="h-full flex-1 min-w-[50%]" /> 
      <div className="">
        <h1 className="h2 !text-7xl">Service en maintenance</h1>
        <Spacer y={4} />
        <h1 className="h3 subtitle !text-3xl">{messages[index]}</h1>
      </div>
    </div>
  );
};

export default PageMaintenance;
