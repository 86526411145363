import React, { useEffect, useMemo, useState, useRef } from "react";
import FileLoader from "../../../../components/file/FileLoader";
import EditableTable from "../../../../components/table/editable-table";
import { useDispatch, useSelector } from "react-redux";

import {Icon} from "@iconify/react";
import { Button, Chip, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tooltip, useDisclosure } from "@heroui/react";
import { ActualizeIcon, DeleteIcon } from "../../../../components/Icons";
import { changeStatus, changeSegmentationStatus, setSasPdf, deleteFile, setCurrentFile, addProjectFile } from "../../../../store/features/Comptage/project-info-slice";
import config from "../../../../config";
import { setFileConfiguration, setSegmentationReady, setSymbolsStore } from "../../../../store/features/Comptage/configuration";
import { clearFiles, setFiles } from "../../../../store/features/PDF/FileSlice";
import { setHits } from "../../../../store/features/Comptage/decompte";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import useAzureBlobs from "../../../../hooks/azure/useAzureBlobs";
import { setCalibration } from "../../../../store/components/PolygonEditor/ImageSlice";
import { useOutletContext } from "react-router-dom";
import YesNoAlert from "../../../../components/modals/AlertYesNo";
import Alert from "../../../../components/modals/Alert";
import { useEntraUser } from "../../../../EntraUserProvider";

const statusColorMap = {
    ready: {
        name: "Prêt",
        color:"success"
    },
    inprogress: {
        name: "En cours de préparation",
        color:"warning"
    },
    unready: {
        name: "Non préparé",
        color:"danger"
    }
  };


const containerName = "comptagev2";
let changingCurrentFile=false;

export default function Files () {
    const {isLoading, setIsLoading, loadingMessage, setLoadingMessage} = useOutletContext();
    const {isOpen, onOpen, onOpenChange: defaultOnOpenChange, onClose} = useDisclosure();
    const { launch_request } = useAzureFuncApp();
    const { downloadBlob } = useAzureBlobs();
    const { userData } = useEntraUser();
    const isAdmin = useMemo(() => {
        return userData.companyName === "Diane - 10426" || userData.companyName === "Diane";
      }, [userData]);

    const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
    const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
    const projectName = useSelector((state) => state.Comptage.ProjectInfos.project_name);
    const projectPages = useSelector((state) => state.Comptage.Configuration.pages);
    const user = useSelector((state) => state.Comptage.ProjectInfos.creator_name);
    const zones = useSelector((state) => state.Comptage.Configuration.zones);
    const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
    const linears = useSelector((state) => state.Comptage.Configuration.linears);
    const legend = useSelector((state) => state.Comptage.Configuration.legend);
    const hits = useSelector((state) => state.Comptage.Decompte.symbols);
    const metres = useSelector((state) => state.Comptage.Decompte.metres);
    const calibration = useSelector((state) => state.image.calibration);
    const dispatch = useDispatch();
    const projectPagesRef = useRef(projectPages);
    
    // Need to use a ref to get the current value of projectPages at the end of addFile and actualizeFile
    useEffect(() => {
        projectPagesRef.current = projectPages;
    }, [projectPages]);

    useEffect(() => {
        projectFiles.forEach(async (file) => {
            if ((file.segmentationStatus !== "ready") && (file.pages.length > 0)) {
                checkSegmentationStatus(file);
            }
        })
    }, [projectFiles.length])

    const checkSegmentationStatus = async (file) => {
        let segmentations = await Promise.all(file.pages.filter(p => p.selected).map(async p => {
            let payload = {
                "function": "check_segmentation_ready",
                "pdf_name": file.name,
                "username": user,
                "project_name": projectName,
                "container_name": containerName,
                "page": p.page + 1
                };
        
                let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
        
                if (status_code === 200) {
                    let segmentation = result.segmentation;
                    return segmentation;
                } else {
                    console.error("Error in response:", status_code);
                }
        }));
        let segmentationStatus = segmentations.every(s => (s === "ready" || s === "failed")) ? "ready" : "ongoing";
        if (segmentationStatus === "ready") {
            dispatch(changeSegmentationStatus({
                filename: file.name,
                segmentationStatus: segmentationStatus
            }));
        }
    };

    const data = useMemo(() => {
        return projectFiles.map((x, i) => {
            return {
                id: i,
                photo: undefined,
                name: x.name,
                pages: x.pages === undefined ? 0 : x.pages.length,
                status: x.status !== "ready" ? x.status :
                        x.segmentationStatus !== "ready" ? "inprogress" :
                        "ready",
                segmentationStatus: x.segmentationStatus,
                is_current: x.is_current,
                action: undefined
            };
        });
    }, [projectFiles]);

    const [filters, setFilters] = useState([]);

    useEffect(() => {
        if (!changingCurrentFile) {
            prepareFirstFile();
        }

        async function prepareFirstFile() {
            if (data.every(d => d.status === "unready") && data.length > 0) {
                changingCurrentFile = true;
                await actualizeFile(data[0].name);
                changingCurrentFile = false;
            }
        }
    }, [data.length]);

    const onChangeCurrentFile = async (filename) => {
        setLoadingMessage("Changement du fichier courant...");
        setIsLoading(true);

        // Set current file
        let payload = {
            'function': 'set_current',
            'username': user,
            'project_name': projectName,
            'pdf_name': filename,
            'container_name': containerName
        };
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            dispatch(setCurrentFile(filename));

            dispatch(setFileConfiguration({
                current_file: result.filename,
                pages: result.pages,
                zones: result.zones,
                symbols: result.symbols,
                linears: result.linears,
                legend: result.legend
            }));

            dispatch(setCalibration(result.calibration));

            dispatch(setHits({
                hits: result.hits,
                metres: result.metres
            }));
        }

        setIsLoading(false);
    };

    const prepareSegmentation = async (page, filename) => {
        dispatch(setSegmentationReady({ page: page-1, segmentation: "ongoing", filename: filename}))
        dispatch(changeSegmentationStatus({
            filename: filename,
            segmentationStatus: "ongoing"
        }));

        let payload = {
            "function": "segment_items",
            "username": user,
            "project_name": projectName,
            "container_name": containerName,
            "pdf_name": filename,
            "page_number": page
        };

        let [status_code, result] = await launch_request(config.apiSegmentationUri, payload, {alertError : false});
        
        let segmentationReady;
        if (status_code === 200) {
            segmentationReady = result.segmentation;
        } else if (status_code === 504 || status_code === 502) {
            segmentationReady = "ongoing";
        } else {
            segmentationReady = "failed";
        }

        if (segmentationReady !== "ongoing") {
            dispatch(setSegmentationReady({ page: page-1, segmentation: segmentationReady, filename: filename}));
            dispatch(changeSegmentationStatus({
                filename: filename,
                segmentationStatus: "ready"
            }));
        }

        if (segmentationReady === "ready") {
            Alert("Fichier prêt",
                `La fonction de comptage automatique est à présent disponible pour la page ${page} du fichier ${filename} (projet ${projectName.replace(/&/g, " ")}).`,
                {size: "lg"}
                );
        } else if (segmentationReady === "failed") {
            Alert("Fichier prêt",
                `La fonction de comptage automatique sur l'ensemble des symboles n'est pas disponible pour la page ${page} du fichier ${filename} (projet ${projectName.replace(/&/g, " ")}). Vous pouvez cependant utiliser les autres fonctions disponibles et compter les symboles individuellement!`,
                {size: "lg"}
                );
        };
    };


    const actualizeFile = async (filename) => {
        dispatch(changeStatus({
            filename: filename,
            status: "inprogress"
        }));

        setLoadingMessage("Préparation du fichier...");
        setIsLoading(true);

        prepareSegmentation(1, filename);

        let payload = {
            "function": "pdf2img",
            "container_name": containerName,
            "project_name": projectName,
            "username": user,
            "file": filename,
            "all_pages": false,
            "selected_pages_numbers": []
        };
    
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            let url_pdf = result.url_pdf;
            let previews = result.previews;
            let pages = result.pages;
            dispatch(setSasPdf({
                filename: filename,
                url: url_pdf,
                previews: previews
            }));
            dispatch(changeStatus({
                filename: filename,
                status: "ready",
                pages: pages
            }));

            if (result.set_current) {
                await onChangeCurrentFile(filename);
            }
        } else {
            
            dispatch(changeStatus({
                filename: filename,
                status: "unready"
            }));
        }

        setIsLoading(false);

        if (!["ready", "failed"].includes(projectPagesRef.current[0]?.segmentation_ready)) {
            Alert("Fichier en cours de préparation",
                <div>
                 <p>La fonction de comptage automatique sur l'ensemble des symboles est en cours de préparation pour la page 1. Cela peut prendre quelques minutes.</p>
                 <p>Vous pouvez cependant déjà tracer les zones dans lesquelles dénombrer, calibrer votre plan et détourer vos symboles!</p>
                </div>,
                {size: "lg"}
            );
        }
    };

    const onDeleteFile = async (filename) => {
        setLoadingMessage("Suppression du fichier...");
        setIsLoading(true);

        // Delete file
        let payload = {
            'function': 'delete_file',
            'username': user,
            'project_name': projectName,
            'pdf_name': filename,
            'container_name': containerName
        };
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            dispatch(deleteFile(filename));

            if (filename === currentFile) {
                dispatch(setCurrentFile(undefined));
            }
        }

        setIsLoading(false);
    };
    
    const columns = [
        {
            uid: 'photo',
            name: '',
            isEditable: false,
            type: 'image',
            height: 50,
            sortable: false
        },
        {
            uid: 'name',
            name: 'Nom du fichier',
            isEditable: false,
            type: 'text',
        },
        {
            uid: 'pages',
            name: 'Nombre de pages',
            isEditable: false,
            type: 'number',
            sortable: false
        },
        {
            uid: 'is_current',
            name: '',
            isEditable: false,
            type: 'bool',
            renderCell: (item) => {
                if (item.is_current) {
                    return (<><span className="bg-green-600 rounded-[50%] inline-block w-4 h-4 text-center align-middle leading-4">&#x2713;</span> Sélectionné</>);
                } else {
                    return (<Button 
                                isDisabled={item.status !== "ready"} 
                                onPress={() => {
                                    YesNoAlert({
                                        title: "Changement de fichier",
                                        body: `Souhaitez vous sauvegarder l'état actuel du fichier ${currentFile} et aller au fichier ${item.name}?`,
                                        onYes: async () => {
                                            await handleSaveClick();
                                            onChangeCurrentFile(item.name);
                                        },
                                        yesText: "Sauvegarder et continuer"
                                    })}}>
                                    Sélectionner
                                </Button>);
                }
            }
        },
        {
            uid: 'status',
            name: 'Etat',
            isEditable: false,
            type: 'text',
            renderCell: (item) => {
                let status = item.status !== "ready" ? item.status :
                            item.segmentationStatus !== "ready" ? "inprogress" :
                            "ready"
                return ( <Chip color={statusColorMap[status].color}>{statusColorMap[status].name}</Chip>);
            }
        },
        {
            uid: 'action',
            name: '',
            isEditable: false,
            type: 'text',
            sortable: false,
            renderCell: (item) => {
                return (<div className="flex flex-row justify-center gap-3">
                    {(item.status === "unready") && <Tooltip color="danger" content="Actualiser le fichier">
                        <span
                        className="text-lg text-danger cursor-pointer active:opacity-50"
                        onClick={() => { actualizeFile(item.name); }}
                        >
                        <ActualizeIcon style={{ color: 'black' }}/>
                        </span>
                    </Tooltip>}
                    <Tooltip color="danger" content="Supprimer le fichier">
                        <span
                        className="text-lg text-danger cursor-pointer active:opacity-50"
                        onClick={() => {
                            YesNoAlert({
                                title: "Supprimer",
                                body: "Voulez-vous vrament supprimer le fichier ?",
                                onYes: () => {
                                    onDeleteFile(item.name);
                                }
                            })
                        }}
                        >
                            <DeleteIcon />
                        </span>
                    </Tooltip>
                </div>)
            }
        }
    ];

    const addFile = async (filename, arrayBuffer, totalPages, index) => {
        dispatch(addProjectFile({
            name: filename,
            pages: [],
            is_current: false,
            status: "inprogress"
        }));

        setLoadingMessage("Préparation du fichier...");
        setIsLoading(true);
        onClose();

        prepareSegmentation(1, filename);

        dispatch(setFiles([]));

        let payload = {
            "function": "pdf2img",
            "container_name": containerName,
            "project_name": projectName,
            "username": user,
            "file": filename,
            "all_pages": false,
            "selected_pages_numbers": []
        };
    
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);
    
        if (status_code === 200) {
            let url_pdf = result.url_pdf;
            let previews = result.previews;
            let pages = result.pages;
            dispatch(setSasPdf({
                filename: filename,
                url: url_pdf,
                previews: previews
            }));
            dispatch(changeStatus({
                filename: filename,
                status: "ready",
                pages: pages
            }));

            if (result.set_current) {
                await onChangeCurrentFile(filename);
            }
        }

        setIsLoading(false);

        if (!["ready", "failed"].includes(projectPagesRef.current[0]?.segmentation_ready)) {
            Alert("Fichier en cours de préparation",
                <div>
                 <p>La fonction de comptage automatique sur l'ensemble des symboles est en cours de préparation pour la page 1. Cela peut prendre quelques minutes.</p>
                 <p>Vous pouvez cependant déjà tracer les zones dans lesquelles dénombrer, calibrer votre plan et détourer vos symboles!</p>
                </div>,
                {size: "lg"}
            );
        }
    };

    const onOpenChange = (isOpen) => {
        dispatch(clearFiles());
        defaultOnOpenChange(isOpen);
    };

    const handleSaveClick = async () => {
        setLoadingMessage("Sauvegarde en cours...");
        setIsLoading(true);
        
        // save selected pages for all files
        let pFile = projectFiles.find((pf) => pf.name === currentFile);
        let selectedPages = [];
        if (pFile !== undefined) {
            selectedPages = pFile.pages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]);
        }

        // Set current file
        let payload = {
            'function': 'save_all',
            'username': user,
            'project_name': projectName,
            'pdf_name': currentFile,
            'container_name': containerName,
            'selected_pages_numbers': selectedPages,
            'zones': zones,
            'legend' : legend,
            'symbols': symbols,
            'linears': linears,
            'hits': hits,
            'metres': metres,
            'calibration': calibration,
        };
        let [status_code, result] = await launch_request(config.apiCountItemsUri, payload);

        if (status_code === 200) {
            let symbols = result.symbols;
            dispatch(setSymbolsStore(symbols));
        }
        
        setIsLoading(false);
    };


    const exportNomenclature = async () => {
        setLoadingMessage("Export de nomenclature en cours...");
        setIsLoading(true);
    
          try {
            const payload = {
              "function": "nomenclature",
              "username": user,
              "project_name": projectName,
              "container_name": containerName,
              "is_excel": true
            };
    
            let [status_code, result] = await launch_request(
              config.apiCountItemsUri,
              payload
            );
    
            if (status_code === 200) {
                if (result.excel_blob_path) {
                    await downloadBlob(
                        containerName,
                        result.excel_blob_path,
                        `Nomenclatures_${projectName.replace(/&/g, "_")}.xlsx`,
                        "Diane"
                      );
                } else {
                    Alert("Aucune nomenclature", "Aucun symbole ni linéaire n'a été compté.")
                }
                
            } else {
              console.error("Error in response:", status_code);
            }
          } catch (error) {
            console.error("Error submitting form:", error);
          }
        setIsLoading(false);
      };
    
    const exportCount = async (printZones) => {
        setLoadingMessage("Export de synthèse en cours...");
        setIsLoading(true);
    
        try {
            const payload = {
                "function": "print_as_pdf",
                "username": user,
                "project_name": projectName,
                "container_name": containerName,
                "print_zones": printZones,
                "visible_symbols_only": false
            };

            let [status_code, result] = await launch_request(
                config.apiCountItemsUri,
                payload
            );

            if (status_code === 200) {
                let extension = result.pdf_blob_path.split(".").pop()
                await downloadBlob(
                    containerName,
                    result.pdf_blob_path,
                    `Comptage_${projectName.replace(/&/g, "_")}.${extension}`,
                    "Diane"
                );
            } else {
                console.error("Error in response:", status_code);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
        setIsLoading(false);
      };

    return (
        <div className="flex flex-col justify-start items-end w-full max-h-full gap-5 px-5 py-5">
            <div className="flex flex-row gap-4">
                <Tooltip
                    content={<>
                        <p>Télécharger un fichier Excel résumant le décompte d'éléments et la mesure de linéaires.</p>
                        <p>Les éléments et linéaires sont regroupés par fichier, page et zone.</p>
                        </>}
                    placement="top"
                >
                    <Button onPress={() => {
                                    YesNoAlert({
                                        title: "Nomenclature",
                                        body: `Souhaitez vous sauvegarder l'état actuel du fichier ${currentFile} et télécharger les nomenclatures?`,
                                        onYes: async () => {
                                            await handleSaveClick();
                                            exportNomenclature();
                                        },
                                        yesText: "Sauvegarder et continuer"
                                    })}}
                        className="glass validation" endContent={<Icon icon="file-icons:microsoft-excel" className='scale-[1.5] ml-2'/>}>
                        Télécharger les nomenclatures au format Excel
                    </Button>
                </Tooltip>
                
                <Tooltip
                content="Télécharger chaque PDF avec les éléments comptés en surbrillance."
                placement="top"
                >
                    <Button onPress={() => {
                                    YesNoAlert({
                                        title: "Synthèse",
                                        body: `Souhaitez vous sauvegarder l'état actuel du fichier ${currentFile} et télécharger la synthèse du comptage?`,
                                        onYes: async () => {
                                            YesNoAlert({
                                                title: "Affichage des zones",
                                                body: "Souhaitez-vous afficher les zones sur le document de synthèse?",
                                                onYes: async () => {
                                                    await handleSaveClick();
                                                    exportCount(true);
                                                },
                                                onNo: async () => {
                                                    await handleSaveClick();
                                                    exportCount(false);
                                                },
                                                yesText: "Oui",
                                                noText: "Non"
                                            })
                                        },
                                        yesText: "Sauvegarder et continuer"
                                    })}}
                            className="glass validation"
                            endContent={<Icon icon="bi:file-pdf" className='scale-[1.5] ml-2'/>}
                    >
                        Télécharger la synthèse du comptage
                    </Button>
                </Tooltip>

                <Button className="min-w-[10%] flex flex-row justify-between" onPress={isAdmin ? () => { YesNoAlert({
                    title: "Mode administrateur",
                    body: `Vous êtes en mode administrateur. Êtes-vous sûr de continuer ?`,
                    onYes: onOpen
                })} : onOpen}>
                    <Icon icon="ic:baseline-plus" />
                    <span>Ajouter</span>
                    <span></span>
                </Button>
            </div>
            <div className="flex w-full overflow-hidden">
                <EditableTable className={"overflow-auto"}
                    columns={columns}
                    data={data}
                    filters={filters}
                    showTopRibbon={false}
                    isHeaderSticky={true}
                />
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} className="max-w-fit">
                <ModalContent>
                {(o) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1"></ModalHeader>
                    <ModalBody>
                        <FileLoader
                            title="Ajoutez un fichier"
                            multiple={false}
                            containerName={containerName}
                            fileType="pdf"
                            className="min-w-[450px]"
                            blobNamePrefix={`${user}/${projectName}/`}
                            onFileLoaded={addFile}
                            backgroundColor="#EFEFEF"
                        />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    );
};
