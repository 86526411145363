import React, { useEffect, useMemo, useState } from 'react';
import styles from '../../../../components/polygon-editor/components/sideMenu/sideMenu.module.css';
import EditableTable from '../../../../components/table/editable-table';
import classNames from 'classnames';
import { Button, Image, Select, SelectItem, Slider, Spacer, Switch, Tooltip } from "@heroui/react";
import {Icon} from "@iconify/react";
import { useDispatch, useSelector } from 'react-redux';
import { switchLinearVisibility, updateLinears } from '../../../../store/features/Comptage/configuration';
import { getPolylineLength, intersectionLengthLinePolygon, isPointInPolygon } from '../../../../utils/geometry';
import '../styles.css'

export const Functions = {
  NONE: 0,
  METRE: 1,
  MANUAL: 2,
  DELETE: 4,
  DELETE_ALL: 5,
};

export default function SideMenu({
  activeFunction,
  drawManual,
  deleteMetre,
  deleteAllMetres,
  page,
  currentLinear,
  currentLinearData,
  setCurrentLinear,
  inputLineWidth,
  setInputLineWidth,
  alpha,
  setAlpha,
}) {

  const dispatch = useDispatch();
  // State to manage whether the menu is opened or closed
  const [opened, setOpened] = useState(true);

  const linears = useSelector((state) => state.Comptage.Configuration.linears);
  const zones = useSelector((state) => state.Comptage.Configuration.zones.filter(x =>x.page === page));
  const metreItems = useSelector((state) => state.Comptage.Decompte.metres.filter(x =>x.page === page));
  const currentCalibration = useSelector((state) => state.image.calibration?.find(x => x.page === page) ?? {scale: 1});
  const sortedLinears = useSelector((state) => {
      let unsortedLinears =  state.Comptage.Configuration.linears;
      return [...unsortedLinears].sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    })

  const data = useMemo(() => {
    if (currentLinear === "all") {
      return linears.map((x, idx) => {
        return {
          id: idx,
          linear_id: x.linear_id,
          linear: x.name,
          isVisible: x.isVisible,
          color: x.color,
          totalLength: `${(metreItems
                      .filter(i => i.linear_id === x.linear_id)
                      .map(i => getPolylineLength(i.points))
                      .reduce((partialSum, a) => partialSum + a, 0) * currentCalibration.scale).toFixed(2)} m`,
        };
      });
    } else {
      const itemsInZones = zones.map((x, idx) => {
        return {
          id: idx,
          zone: x.name,
          totalLength: metreItems
                      .filter(i => i.linear_id === currentLinear)
                      .map(i => intersectionLengthLinePolygon(i.points, x.points))
                      .reduce((partialSum, a) => partialSum + a, 0),
        };
      });

      return itemsInZones.concat([{
        id: zones.length,
        zone: "Aucune zone",
        totalLength: metreItems.filter(i => i.linear_id === currentLinear).map(i => getPolylineLength(i.points)).reduce((partialSum, a) => partialSum + a, 0) - itemsInZones.map(x => x.totalLength).reduce((partialSum, a) => partialSum + a, 0)
      }]).map(x=>({id: x.id, zone: x.zone, totalLength: `${(x.totalLength * currentCalibration.scale).toFixed(2)} m`}));
    }
  }, [metreItems.length, currentLinear, zones.length, linears]);

  const [filters, setFilters] = useState([]);

  // Function to toggle the opened and visible states
  const toggleMenu = () => {
    setOpened(!opened); // Toggle the opened state
  };

  const changeVisibilityItem = (item) => {
    dispatch(switchLinearVisibility(item.linear_id));
  };

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  };

  const columnsAll = useMemo(() => [{
    uid: "visibility",
    name: "",
    type: "image",
    isEditable: false,
    minWidth: "50px",
    width: "50px",
    maxWidth: "50px",
    sortable:false,
    renderCell: (item) => {
      return (<Icon
          onClick={() => changeVisibilityItem(item)}
          className="scale-[1.3] cursor-pointer rounded-lg"
          style={{
            backgroundColor: item.isVisible ? `rgba(${hexToRgb(item.color)}, 0.5)` : "transparent"
          }}
          icon={(item.isVisible === undefined || item.isVisible) ? "codicon:eye" : "codicon:eye-closed"}
        />);
    }
  }, {
    uid: "linear",
    name: "Linéaire",
    type: "text",
    isEditable: false,
    renderCell: (item) => {
      return (<p className="text-bold w-[100px] text-sm capitalize text-black cursor-pointer long-word zoomable-text"
                  key={item} 
                  onClick={() => setCurrentLinear(item.linear_id)}
              >
                {item.linear}
              </p>)
    }
  }, {
    uid: "totalLength",
    name: "Longueur",
    type: "int",
    isEditable: false
  }], []);

  const columnsItem = useMemo(() => [{
    uid: "zone",
    name: "Zone",
    type: "text",
    isEditable: false
  }, {
    uid: "totalLength",
    name: "Longueur",
    type: "int",
    isEditable: false
  }], []);

  return (
    <div className={classNames(styles.parent, opened ? styles.opened : styles.closed, "h-full")}>
      <div className='flex flex-row h-full'>
        {/* Button to toggle the menu */}
        <div className={styles.openButton}>
          <div className={`${styles.arrowParent} bg-white/80`} style={{
            padding: "20px 0px 20px 5px",
            borderRadius: "10px 0px 0px 10px",
            marginTop: "50px",
            writingMode: "tb",
            width: "100%",
            backgroundColor: "#f2f2f2"
          }} onClick={toggleMenu}>
            {/* Arrow icon to indicate open/close state */}
            <label className={`rotate-180 text-center cursor-pointer block ${opened ? "font-bold" : ""}`}>Métré</label>
          </div>
        </div>

        {/* Conditionally render menu contents based on the visible state */}
        <div style={{
          width:    opened ? "300px" : "",
          padding:  opened ? "" : "0",
          minWidth: opened ? "300px" : "",
          overflowY: "hidden",
          backgroundColor: "#f2f2f2"
        }} className='w-0 min-w-0 transition-all duration-700 linear h-full px-4 py-3'>
          <div className='flex flex-col h-full overflow-y-auto overflow-x-hidden' style={{
            display: opened ? "" : "none"
          }}>
            <div className='flex flex-row items-end gap-2'>
              <div className='flex flex-grow overflow-x-hidden pb-2'>
                <Select
                  labelPlacement="outside"
                  label="Linéaire"
                  classNames={{
                    trigger: "!bg-default-300",
                    label: "top-[66%]"
                  }}
                  className='shadow-md rounded-xl'
                  color='default'
                  selectionMode='single'
                  disallowEmptySelection={true}
                  defaultSelectedKeys={["all"]}
                  selectedKeys={[currentLinear]}
                  multiple={false}
                  onSelectionChange={(key) => {
                    setCurrentLinear(Array.from(key)[0]);
                  }}>
                  <SelectItem key="all">Tous</SelectItem>
                  {sortedLinears.map((s) => (
                    <SelectItem key={s.linear_id}>
                      {s.name}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              {currentLinear !== "all" && 
                <Button isIconOnly onPress={() => setCurrentLinear("all")} className='shadow-lg mb-2'>
                  <Icon icon="lucide:filter-x" className='scale-[1.5]' />
                </Button>
              }
            </div>
            <Spacer y={4}/>
            {currentLinear === "all" && <div className='flex flex-col overflow-hidden'>
              <div>
                <div className='flex flex-row mb-1 gap-2'>
                  <Button className='shadow-lg flex flex-grow' onPress={deleteMetre} color={activeFunction === Functions.DELETE ? "danger" : "default"}>
                    <Icon icon="jam:rubber" className='scale-[1.5] flex-5' />
                    <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer</span>
                  </Button>
                  <Button className='shadow-lg flex flex-grow' onPress={deleteAllMetres} color={activeFunction === Functions.DELETE_ALL ? "danger" : "default"}>
                    <Icon icon="material-symbols:delete-outline" className='scale-[1.5] flex-5' />
                    <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer tout</span>
                  </Button>
                </div>
              </div>
              <Spacer y={4} />
              <div className='flex flex-col overflow-hidden'>
                <div className={`${styles.header} h4`}>Linéaires</div>
                <div className='p-1 rounded-lg min-h-[300px] overflow-auto'>
                  <EditableTable
                    data={data}
                    filters={filters}
                    columns={columnsAll}
                    showTopRibbon={true}
                    groupable={false}
                    showColumns={false}
                    showTotal={false}
                    pagination={false}
                    removeWrapper
                    reduxTableSaveAction={updateLinears}
                    className="overflow-y-auto overflow-x-hidden"
                    emptyContent={"Aucun linéaire"}
                    tableBackground=""
                  />
                </div>
                <Spacer y={16}/>
              </div>
            </div>}
            {currentLinear !== "all" && <div>
              <div className={`flex w-full justify-center mb-4`}>
                <Button
                    isIconOnly
                    onPress={() => dispatch(switchLinearVisibility(currentLinear))}
                    radius="sm"
                    style={{
                      backgroundColor: (currentLinearData === undefined || currentLinearData.isVisible) ? `rgba(${hexToRgb(currentLinearData.color)}, ${currentLinearData.opacity ?? 1})` : "transparent"
                    }}
                    className={(currentLinearData === undefined || currentLinearData.isVisible) ? "text-slate-300" : "black"}
                    >
                    <Icon 
                      className={`scale-[1.5]`}
                      icon={(currentLinearData === undefined || currentLinearData.isVisible) ? "codicon:eye" : "codicon:eye-closed"}
                    />
                </Button>
              </div>
              <div className='flex flex-col'>
                <div className={`${styles.header} h4 pb-2`}>Mesurer le linéaire</div>
                <Tooltip placement='left' content="Tracé du linéaire (Entrée ou double clic pour terminer)" color="danger">
                  <Button className='shadow-lg w-full' onPress={drawManual} color={activeFunction === Functions.MANUAL ? "danger" : "default"}>
                    <Icon icon="material-symbols:point-scan" className='scale-150' />Manuel
                  </Button>
                </Tooltip>
                <Spacer y={2} />
                <div className='flex flex-row mb-1 gap-2 w-full'>
                  <Button className='shadow-lg flex flex-grow' onPress={deleteMetre} color={activeFunction === Functions.DELETE ? "danger" : "default"}>
                    <Icon icon="jam:rubber" className='scale-[1.5] flex-5' />
                    <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer</span>
                  </Button>
                  <Button className='shadow-lg flex flex-grow' onPress={deleteAllMetres} color={activeFunction === Functions.DELETE_ALL ? "danger" : "default"}>
                    <Icon icon="material-symbols:delete-outline" className='scale-[1.5] flex-5' />
                    <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer tout</span>
                  </Button>
                </div>
              </div>
              <Spacer y={2} />
              <div className='flex flex-col w-full'>
                <div className={`${styles.header} h4 pb-2`}>Paramètres</div>
                <div className='w-full glass p-2 rounded-lg'>
                  <Slider 
                    label="Epaisseur des lignes" 
                    step={1} 
                    maxValue={50} 
                    minValue={5} 
                    defaultValue={inputLineWidth}
                    value={inputLineWidth}
                    className="max-w-md"
                    onChange={setInputLineWidth}
                  />
                  <Slider 
                    label="Opacité des lignes" 
                    step={0.01} 
                    maxValue={1} 
                    minValue={0} 
                    defaultValue={alpha}
                    value={alpha}
                    className="max-w-md"
                    onChange={setAlpha}
                  />
                </div>
              </div>
              <Spacer y={4}/>
              <div className='flex flex-col'>
                <div className={`${styles.header} h4 pb-2`}>{currentLinearData.name}</div>
                <div className="rounded-lg min-h-[100px] overflow-auto glass">
                  <EditableTable
                    data={data}
                    filters={filters}
                    columns={columnsItem}
                    showTopRibbon={false}
                    pagination={false}
                    removeWrapper
                    isHeaderSticky={true}
                    reduxTableSaveAction={updateLinears}
                    className="overflow-y-auto"
                    emptyContent={"Aucun symbole"}
                    tableBackground=""
                  />
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
