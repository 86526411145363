import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from '../../../../components/polygon-editor/components/sideMenu/sideMenu.module.css';
import EditableTable from '../../../../components/table/editable-table';
import classNames from 'classnames';
import { Button, Chip, Image, Select, SelectItem, Slider, Spacer, Switch, Tooltip } from "@heroui/react";
import {Icon} from "@iconify/react";
import { ActualizeIcon } from "../../../../components/Icons";
import { useDispatch, useSelector } from 'react-redux';
import { switchSymbolVisibility, switchAllSymbolsVisibility, updateSymbols } from '../../../../store/features/Comptage/configuration';
import { isPointInPolygon } from '../../../../utils/geometry';
import { Functions } from './functions';
import YesNoAlert from '../../../../components/modals/AlertYesNo';
import '../styles.css'

export default function SideMenu({
  setIsLoading,
  setLoadingMessage,
  activeFunction,
  setActiveFunction,
  countManual,
  countAuto,
  countAll,
  checkSegmentationReady,
  deleteItem,
  deleteAllItems,
  page,
  currentSymbol,
  currentSymbolData,
  setCurrentSymbol,
  currentZones,
  setCurrentZones,
  confidenceRate,
  setConfidenceRate,
  inputPointSize,
  setInputPointSize,
  alpha,
  setAlpha,
  sidemenus
}) {
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(true);
  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const zones = useSelector((state) => state.Comptage.Configuration.zones.filter(x =>x.page === page));
  const countedItems = useSelector((state) => state.Comptage.Decompte.symbols.filter(x =>x.page === page));
  const segmentationReady = useSelector((state) => state.Comptage.Configuration.pages[page-1]?.segmentation_ready);
  const [allSymbolsVisible, setAllSymbolsVisible] = useState(true);
  const sortedSymbols = useSelector((state) => {
    let unsortedSymbols =  state.Comptage.Configuration.symbols;
    return [...unsortedSymbols].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
  })

  useEffect(() => {
    setAllSymbolsVisible(Object.values(symbols).every(s => s.isVisible === true));
  }, [symbols]);


  const data = useMemo(() => {
    if (currentSymbol === "all") {
      return symbols.map((x, idx) => {
        return {
          id: idx,
          photo: x.src,
          symbol: x.name,
          crop_id: x.crop_id,
          isVisible: x.isVisible,
          color: x.color,
          quantity: countedItems
                      .filter(i => x.confidence_rate === undefined || i.confidence_rate >= x.confidence_rate[0])
                      .filter(i => x.confidence_rate === undefined || i.confidence_rate <= x.confidence_rate[1])
                      .filter(i => i.crop_id === x.crop_id)
                      .length,
        };
      });
    } else {
      const itemsInZones = zones.map((x, idx) => {
        let items = countedItems
          .filter(i => i.confidence_rate >= confidenceRate[0])
          .filter(i => i.confidence_rate <= confidenceRate[1])
          .filter(i => i.crop_id === currentSymbol)
          .filter(i => isPointInPolygon(x.points, i));
        return {
          id: idx,
          zone: x.name,
          items: items,
          quantity: items.length,
        };
      });
      return itemsInZones.concat([{
        id: zones.length,
        zone: "Aucune zone",
        quantity: countedItems
                    .filter(i => i.confidence_rate >= confidenceRate[0])
                    .filter(i => i.confidence_rate <= confidenceRate[1])
                    .filter(i => i.crop_id === currentSymbol).length - new Set(itemsInZones.map(x => x.items).flat()).size
      }]);
    }
  }, [countedItems.length, currentSymbol, symbols, confidenceRate, zones.length]);

  const [filters, setFilters] = useState([]);

  const changeVisibilityItem = useCallback((item) => {
    dispatch(switchSymbolVisibility(item.crop_id));
  }, [dispatch]);

  const changeVisibilityAllItems = useCallback(() => {
    dispatch(switchAllSymbolsVisibility(!allSymbolsVisible));
  }, [dispatch, allSymbolsVisible]);

  const hexToRgb = useCallback((hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }, []);

  const visibilityCell = useCallback((item) => {
    return (
    <Icon
        onClick={() => changeVisibilityItem(item)}
        className={`scale-[1.3] cursor-pointer rounded-lg`}
        style={{
          backgroundColor: item.isVisible ? `rgba(${hexToRgb(item.color)}, 0.5)` : "transparent"
        }}
        icon={(item.isVisible === undefined || item.isVisible) ? "codicon:eye" : "codicon:eye-closed"}
        />
        );
  }, [changeVisibilityItem, hexToRgb]);

  const photoCell = useCallback((item) => {
    return <Image className="rounded-none cursor-pointer zoomable-image"
                  src={item.photo}
                  width={40}
                  onClick={() => setCurrentSymbol(item.crop_id)}
                  
            />

  }, [setCurrentSymbol]);

  const symbolCell = useCallback((item) => {
    return (<p className="w-[100px] text-sm capitalize text-black cursor-pointer long-word zoomable-text"
                key={item} 
                onClick={() => setCurrentSymbol(item.crop_id)}
            >
              {item.symbol}
            </p>)
  }, [setCurrentSymbol]);

  const columnsAll = useMemo(() => [{
    uid: "visibility",
    name: "",
    type: "image",
    isEditable: false,
    minWidth: "50px",
    width: "50px",
    maxWidth: "50px",
    sortable:false,
    renderCell: visibilityCell
  }, {
    uid: "photo",
    name: "Symbole",
    type: "image",
    isEditable: false,
    sortable:false,
    renderCell: photoCell
  }, {
    uid: "symbol",
    name: "Nom",
    type: "text",
    isEditable: false,
    renderCell: symbolCell
  }, {
    uid: "quantity",
    name: "Quantité",
    type: "int",
    isEditable: false
  }], [visibilityCell, photoCell, symbolCell]);

  const columnsItem = useMemo(() => [{
    uid: "zone",
    name: "Zone",
    type: "text",
    isEditable: false
  }, {
    uid: "quantity",
    name: "Quantité",
    type: "int",
    isEditable: false
  }], []);

  const [sidePanels, setSidePanels] = useState([]);

  useEffect(() => {
    let panels = [];

    if (sidemenus) {
      panels.push(...sidemenus.map(sm => ({
        ...sm,
        element: React.cloneElement(sm.element, {
          activeFunction: activeFunction,
          setActiveFunction: setActiveFunction,
          page: page,
          setIsLoading: setIsLoading,
          setLoadingMessage: setLoadingMessage
        })})
      ));
    }

    panels = panels.map((x, idx) => ({...x, id: idx, opened: false}));

    setSidePanels(panels);
  }, []);


  // Function to toggle the opened and visible states
  const toggleMenu = (id) => {
    setOpened(id === -1);
    setSidePanels(sp => {
      return sp.map(x => ({...x, opened: x.id === id ? !x.opened : false}));
    });
  };

  const handleSelectedItemClick = (e) => {
    let key = e.target.getAttribute("data-key");
    if (key === "none") {
      setCurrentZones([]);
    }
    else if (key === "all") {
      setCurrentZones(zones.map(x => x.zone_id));
    }
    else {
      if (currentZones.find(x => x === key)) {
        setCurrentZones([...currentZones.filter(x => x !== key)]);
      }
      else {
        setCurrentZones([...currentZones, key]);
      }
    }
  };

  return (
    <div className={classNames(styles.parent, sidePanels.some(x=>x.opened) || opened ? styles.opened : styles.closed, "h-full")}>
      <div className='flex flex-row h-full max-h-full'>
        {/* Button to toggle the menu */}
        <div className={styles.openButton}>
          <div className={` ${styles.arrowParent} bg-white/80`} style={{
              padding: "20px 0px 20px 5px",
              borderRadius: "10px 0px 0px 10px",
              marginTop: "50px",
              writingMode: "tb",
              width: "100%",
            backgroundColor: "#f2f2f2"
          }} onClick={() => { toggleMenu(opened ? undefined : -1); }}>
            {/* Arrow icon to indicate open/close state */}
            <label className={`rotate-180 text-center cursor-pointer block ${opened ? "font-bold" : ""}`}>Compte</label>
          </div>
          {sidePanels.map(item => (<div className={`${styles.arrowParent} bg-white/80`} style={{
            padding: "20px 0px 20px 5px",
            borderRadius: "10px 0px 0px 10px",
            marginTop: "10px",
            writingMode: "tb",
            width: "100%",
            backgroundColor: "#f2f2f2"
          }} onClick={() => { toggleMenu(item.id); }}>
            {/* Arrow icon to indicate open/close state */}
            <label className={`rotate-180 text-center cursor-pointer block ${item.opened ? "font-bold" : ""}`}>{item.title}</label>
          </div>))}
        </div>

        <div style={{
            width:    opened ? "300px" : "",
            padding:  opened ? "" : "0",
            minWidth: opened ? "300px" : "",
            backgroundColor: "#f2f2f2"
          }} className='w-0 min-w-0 transition-all duration-700 linear h-full max-h-full px-4 py-3 overflow-y-auto'>
            <div className='flex flex-col h-full' style={{
              display: opened ? "" : "none"
            }}>
              <div className='flex flex-row items-end gap-2 max-w-full'>
                <div className='flex flex-grow overflow-x-hidden pb-2'>
                  <Select
                    labelPlacement="outside"
                    label="Symbole"
                    classNames={{
                      trigger: "!bg-default-300",
                      label: "top-[66%]",
                    }}
                    className='shadow-md rounded-xl'
                    selectionMode='single'
                    disallowEmptySelection={true}
                    defaultSelectedKeys={["all"]}
                    selectedKeys={[currentSymbol]}
                    multiple={false}
                    onSelectionChange={(key) => {
                      setCurrentSymbol(Array.from(key)[0]);
                    }}>
                    <SelectItem key="all">Tous</SelectItem>
                    {sortedSymbols.map((s) => (
                        <SelectItem key={s.crop_id}>
                          {s.name}
                        </SelectItem>
                      ))
                    }
                  </Select>
                </div>
                {currentSymbol !== "all" && 
                  <Button isIconOnly onPress={() => setCurrentSymbol("all")} className='shadow-lg mb-2'>
                    <Icon icon="lucide:filter-x" className='scale-[1.5]' />
                  </Button>
                }
              </div>
              <Select
                labelPlacement="outside"
                label="Zones à traiter"
                selectionMode="multiple"
                classNames={{
                  trigger: "!bg-default-300",
                  label: "top-[66%]"
                }}
                className='shadow-lg rounded-xl'
                disallowEmptySelection={true}
                defaultSelectedKeys={["all"]}
                selectedKeys={currentZones === undefined || currentZones.length === 0 ? ["none"] : (currentZones.length === zones.length ? ["all", ...currentZones] : currentZones)}
                renderValue={(items) => {
                  return (
                    <div className="flex flex-wrap gap-2">
                      {items.filter(x=>x.key === "all").length !== 0 ?
                        <span>Toutes les zones</span>
                      : items.filter(x=>x.key === "none").length !== 0 ?
                        <span>Toute la page</span>
                      : items.map((item) => (
                        <Chip key={item.key} color='danger'>{item.textValue}</Chip>
                      ))}
                    </div>
                  );
                }}>
                <SelectItem key="all" onPress={handleSelectedItemClick}>Toutes les zones</SelectItem>
                <SelectItem key="none" onPress={handleSelectedItemClick}>Toute la page</SelectItem>
                {zones.map((s) => (
                  <SelectItem key={s.zone_id} onPress={handleSelectedItemClick}>
                    {s.name}
                  </SelectItem>
                ))}
              </Select>
              <Spacer y={4}/>
              {currentSymbol === "all" && <div className='flex flex-col overflow-hidden'>
                <div className='flex flex-col gap-4'>
                  <div className='flex flex-row mb-1 gap-2'>
                    <Button className='flex flex-grow shadow-lg' onPress={deleteItem} color={activeFunction === Functions.DELETE_ITEM ? "danger" : "default"}>
                      <Icon icon="jam:rubber" className='scale-[1.5] flex-5' />
                      <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer</span>
                    </Button>                        

                    <Button className='flex flex-grow shadow-lg' onPress={deleteAllItems} color={activeFunction === Functions.DELETE_ALL ? "danger" : "default"}>
                      <Icon icon="material-symbols:delete-outline" className='scale-[1.5] flex-5' />
                      <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer tout</span>
                    </Button>
                  </div>

                  {(!segmentationReady || segmentationReady === "ongoing" || segmentationReady === "not_started") && <div className="flex flex-row items-center">
                      <div className={`${styles.header} h5 subtitle !text-red-600`}>La fonction de comptage automatique sur l'ensemble des symboles sera disponible dans quelques minutes.</div>
                      <Button isIconOnly onPress={() => checkSegmentationReady({alert : true})} className='glass validation'>
                        <ActualizeIcon />
                      </Button>
                    </div>}
                  {(segmentationReady === "failed") && <div className={`${styles.header} h5 subtitle !text-red-600`}>La fonction de comptage automatique sur l'ensemble des symboles n'est pas disponible pour cette page.</div>}
                  <Button className='w-full shadow-lg glass validation' onPress={countAll} isDisabled={segmentationReady !== "ready"}>
                    <Icon icon="fluent:search-sparkle-16-filled" className='scale-[1.5] flex-5' />
                    <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Comptage automatique</span>
                  </Button>
                </div>

                <Spacer y={4} />
                <div className='flex flex-col overflow-hidden'>
                  <div className='flex flex-row items-center'>
                    <div className={`${styles.header} h4 mr-4`}>Symboles</div>
                    <Icon
                      onClick={() => changeVisibilityAllItems()}
                      className="scale-[1.3] cursor-pointer"
                      icon={(allSymbolsVisible) ? "codicon:eye" : "codicon:eye-closed"}
                    />
                  </div>
                  <div className='rounded-lg min-h-[300px] overflow-auto'>
                    <EditableTable
                      data={data}
                      filters={filters}
                      columns={columnsAll}
                      showTopRibbon={true}
                      groupable={false}
                      showColumns={false}
                      showTotal={false}
                      pagination={false}
                      removeWrapper
                      reduxTableSaveAction={updateSymbols}
                      className="overflow-auto"
                      emptyContent={"Aucun symbole"}
                      tableBackground=""
                      classNames={{
                        td: "px-2",
                        th: "px-2"
                      }}
                    />
                  </div>
                  <Spacer y={16} />
                </div>
              </div>}
              {currentSymbol !== "all" && <div className='flex flex-col overflow-y-auto overflow-x-hidden'>
                  <div className='flex flex-row gap-x-6 items-center justify-center mt-4'>
                      <Icon
                        onClick={() => dispatch(switchSymbolVisibility(currentSymbol))}
                        className={`scale-[2.5] cursor-pointer rounded-full p-1`}
                        style={{
                          backgroundColor: (currentSymbolData === undefined || currentSymbolData.isVisible) ? `rgba(${hexToRgb(currentSymbolData.color)}, ${currentSymbolData.opacity})` : "transparent"
                        }}
                        icon={(currentSymbolData === undefined || currentSymbolData.isVisible) ? "codicon:eye" : "codicon:eye-closed"}
                      />
                      <Image className='max-h-[100px] object-contain mx-auto' src={currentSymbolData.src} radius={"none"} />
                  </div>

                <Spacer y={4}/>
                <div className='flex flex-col'>
                  <div className={`${styles.header} h4 pb-2`}>Compter le symbole</div>
                  <div >
                    <div className='flex flex-row gap-2'>
                      <Tooltip placement='left' content="Placer manuellement votre symbole" color="danger" showArrow={true}>
                        <Button className='shadow-lg w-full' onPress={countManual} color={activeFunction === Functions.MANUAL ? "danger" : "default"}>
                          <Icon icon="wpf:cursor" className='scale-[1.4] flex-5' />Manuel
                        </Button>
                      </Tooltip>
                      <Tooltip placement='left' content="Supprimer un élément" color="danger" showArrow={true}>
                        <Button className='shadow-lg' isIconOnly onPress={deleteItem} color={activeFunction === Functions.DELETE_ITEM ? "danger" : "default"}>
                          <Icon icon="jam:rubber" className='scale-[1.5] flex-5' />
                        </Button>
                      </Tooltip>
                      <Tooltip placement='left' content="Supprimer tous les éléments" color="danger" showArrow={true}>
                        <Button className='shadow-lg' isIconOnly onPress={deleteAllItems} color={activeFunction === Functions.DELETE_ALL ? "danger" : "default"}>
                          <Icon icon="material-symbols:delete-outline" className='scale-[1.5] flex-5' />
                        </Button>
                      </Tooltip>
                    </div>
                    
                    <Spacer y={2} />
                    {/* <div className='grid grid-cols-2 mb-1 gap-2'> */}
                    
                    <div className='flex flex-row w-full mb-1 gap-2'>
                    <Tooltip placement='left' content="Rechercher à partir des géométries présentes sur le fichier" color="danger" showArrow={true}>
                        <Button className='shadow-lg flex flex-grow' onPress={() => {countAuto("vector", true)}} color={activeFunction === Functions.AUTO_VECTOR ? "danger" : "default"}>
                          <Icon icon="fluent:search-sparkle-16-filled" className='scale-[2.5] flex-5' /><span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Recherche IA (géométrie)</span>
                        </Button>
                      </Tooltip>
                      <Tooltip placement='left' content="Rechercher à partir de l'analyse d'image" color='danger' showArrow={true}>
                        <Button className='shadow-lg flex flex-grow' onPress={() => {
                          YesNoAlert({
                            title: <div className='flex items-center gap-x-4'><Icon icon="basil:sand-watch-solid" className="scale-150" /><span>Rotation</span></div>,
                            body: <><p>Voulez-vous compter le symbole avec rotations ?</p><p>(Cela peut prendre beaucoup plus de temps)</p></>,
                            yesText: "Oui",
                            noText: "Non",
                            size: "md",
                            onYes: () => {
                              countAuto("image", true);
                            },
                            onNo: () => {
                              countAuto("image", false);
                            }
                          })}} color={activeFunction === Functions.AUTO_IMAGE ? "danger" : "default"}>
                          <Icon icon="ic:outline-image-search" className='scale-[2.5] flex-5' /><span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Recherche IA (image)</span>
                        </Button>
                      </Tooltip>
                      {/* <Button className='shadow-lg' onPress={deleteItem} color={activeFunction === Functions.DELETE_ITEM ? "danger" : "default"}>
                        <Icon icon="jam:rubber" className='scale-[1.5] flex-5' />
                        <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer</span>
                      </Button>
                      <Button className='shadow-lg' onPress={deleteAllItems} color={activeFunction === Functions.DELETE_ALL ? "danger" : "default"}>
                        <Icon icon="material-symbols:delete-outline" className='scale-[1.5] flex-5' />
                        <span className='flex-2 text-[.8rem] leading-[.8rem] break-words whitespace-normal'>Supprimer tout</span>
                      </Button> */}
                    </div>
                  </div>
                </div>
                <Spacer y={4}/>
                <div className='flex flex-col w-full'>
                  <div className={`${styles.header} h4 pb-2`}>Paramètres</div>
                  <div className='w-full glass p-2 rounded-lg'>
                    <Slider 
                      label={
                        <Tooltip placement='left' 
                          content={
                            <>
                            <p>Chaque point est associé à un score de confiance.</p>
                            <p>Plus ce score est proche de 1, plus il est probable qu'il s'agisse bien du symbole recherché.</p>
                            <p>Seuls les éléments ayant un score au dessus du seuil de confiance sont comptés.</p>
                            </>
                          } 
                          color='danger' 
                          showArrow={true}>
                            Seuil de confiance
                        </Tooltip>}
                      step={0.01} 
                      size='sm'
                      maxValue={1} 
                      minValue={.3} 
                      defaultValue={confidenceRate}
                      value={confidenceRate}
                      className="max-w-md"
                      onChange={setConfidenceRate}
                    />
                    <Slider 
                      label="Taille des points" 
                      step={1} 
                      size='sm'
                      maxValue={80} 
                      minValue={1} 
                      defaultValue={inputPointSize}
                      value={inputPointSize}
                      className="max-w-md"
                      onChange={setInputPointSize}
                    />
                    <Slider 
                      label="Opacité des points" 
                      step={0.01} 
                      size='sm'
                      maxValue={1} 
                      minValue={0} 
                      defaultValue={alpha}
                      value={alpha}
                      className="max-w-md"
                      onChange={setAlpha}
                    />
                  </div>
                </div>
                <Spacer y={4}/>
                  <div className={`${styles.header} h4`}>Décompte</div>
                  <div className="rounded-lg min-h-[100px] overflow-auto glass">
                    <EditableTable
                      data={data}
                      filters={filters}
                      columns={columnsItem}
                      showTopRibbon={false}
                      pagination={false}
                      removeWrapper
                      isHeaderSticky={true}
                      reduxTableSaveAction={updateSymbols}
                      className="overflow-auto"
                      emptyContent={"Aucun symbole"}
                      tableBackground=""
                    />
                  </div>
                  <Spacer y={16} />
              
            </div>}
          </div>
        </div>
        {sidePanels.map(item => (<div style={{
          width:    item.opened ? "300px" : "",
          padding:  item.opened ? "" : "0",
          minWidth: item.opened ? "300px" : "",
          overflowY: "hidden",
          backgroundColor: "#f2f2f2"
        }} className='w-0 min-w-0 transition-all duration-700 linear h-full px-4 py-3'>
          <div className='flex flex-col h-full' style={{
            display: item.opened ? "" : "none"
          }}>
            {item.element}
          </div>
      </div>))}
    </div>
  </div>
  );
}
