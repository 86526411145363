import { Button, Divider, Textarea } from "@heroui/react";
import Alert from "../../../components/modals/Alert";
import TicketDetailCard from "./TicketDetailCard";

export default function ReportEditor({ ticket, setTicket, analyseTicket, isAnalyzingTicket, getBPU, bpu }) {

    const handleInputChange = (name) => (e) => {
        setTicket((prev) => ({ ...prev, [name]: e.target.value }));
    };

    const handleAnalyseTicket = async () => {

        if (!ticket && !ticket.ticketCode) {
            Alert("Erreur", "Veuillez charger un ticket avant de l'analyser.");
            return;
        }

        const companyCode = parseInt("95" + ticket.ticketCode[2] + ticket.ticketCode[3]);
        const newBPU = await getBPU(companyCode);
        if (!newBPU || newBPU.length === 0) return;

        const hasContractArticle = newBPU.some(article => article.value.code_contrat === ticket.contractCodexId)
        if (!hasContractArticle) {
            Alert("Attention", (`Aucun BPU relatif au contrat ${ticket.contractCodexName ? ticket.contractCodexName : ticket.contractCode} n'a été enregistré.\n`
                + "Veillez à bien les renseigner dans l'export."),
            { size: "lg" }
        );
        }
        analyseTicket(ticket);
    };

    return (
        <>
            <h2 className="text-2xl font-bold">Compte rendu</h2>
            <Divider />
            <TicketDetailCard ticket={ticket} />
            <Textarea
                placeholder="Compte rendu"
                value={ticket.ticketTechnicianRemarks}
                onChange={handleInputChange("ticketTechnicianRemarks")}
                minRows={10}
                maxRows={20}
                size="md"
            />
            <Button onPress={handleAnalyseTicket} variant="flat" isLoading={isAnalyzingTicket}>
                {isAnalyzingTicket ? "Analyse en cours..." : "Analyse IntelIA"}
            </Button>
        </>
    );
}
