import React, { useContext, useEffect, useMemo, useState } from "react";
import PDFEditor from "../../../../components/polygon-editor/pdf-editor";
import { useDispatch, useSelector } from "react-redux";
import SideMenu, { Functions } from "./sideMenu";
import { CanvasContext, CanvasProvider } from "../../../../components/polygon-editor/contexts/canvas-context";
import { LinearsProvider } from "./provider";
import { DrawShapeProvider, useDrawShape } from "../../../../components/polygon-editor/contexts/draw-context";
import { ShapeType } from "../../../../components/polygon-editor/hooks/draw";
import { setFillColor, setOpacity, setStrokeColor, setStrokeWidth } from "../../../../store/components/PolygonEditor/CanvasSlice";
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { useOutletContext } from "react-router-dom";
import { addMetre, removeMetre } from "../../../../store/features/Comptage/decompte";
import { setLinearLineWidth, setLinearOpacity } from "../../../../store/features/Comptage/configuration";
import { addObjectInLayer } from "../../../../store/components/PolygonEditor/ImageSlice";
import YesNoAlert from "../../../../components/modals/AlertYesNo";

const defaultLinearWidth = 20;
const defaultOpacity = 1;
const containerName = "comptagev2";

export default function Linears () {
  const {isLoading, setIsLoading, loadingMessage, setLoadingMessage, page, setPage} = useOutletContext();

  fabric.Group.prototype.hasControls = false;
  fabric.Group.prototype.lockMovementX = false;
  fabric.Group.prototype.lockMovementY = false;
  fabric.Group.prototype.lockRotation = false;

  return (
    <CanvasProvider className="overflow-hidden h-[99vh]">
      <LinearsComponent
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        loadingMessage={loadingMessage}
        setLoadingMessage={setLoadingMessage}
        page={page}
        setPage={setPage}
      />
    </CanvasProvider>
  );
};

export function LinearsComponent ({
  isLoading,
  setIsLoading,
  loadingMessage,
  setLoadingMessage,
  page,
  setPage
}) {
  const dispatch = useDispatch();
  const [activeFunction, setActiveFunction] = useState(Functions.NONE);
  const { angle, canvasRef, getAllObjects, setIsDrawing, setDrawMultipleShapes, setIsDeleting } = useContext(CanvasContext);
  const [currentLinear, setCurrentLinear] = useState("all");
  const linears = useSelector((state) => state.Comptage.Configuration.linears);
  const currentLinearData = useMemo(() => {
    let csd = linears.find(x => x.linear_id === currentLinear);
    if (csd) {
      return csd;
    }else {
      return {
        linear_id: -1,
        name: "inconnu"
      }
    }
  }, [currentLinear, linears]);
  const metreLayerId = useSelector((state) => {
    let metre = state.image.layers.find(x=>x.name === "linear");
    if (metre) {
      return metre.layerId;
    }
    else {
      return -1;
    }
  });

  const [inputLineWidth, setInputLineWidth] = useState(defaultLinearWidth);
  const [alpha, setAlpha] = useState(defaultOpacity);

  const updateShapes = (filterCallback, property, value) => {
    getAllObjects().filter(filterCallback).forEach(sh => {
      sh.set(property, value);
    });
    canvasRef.current.renderAll();
  };

  useEffect(() => {
    setIsDrawing(false);

    setIsDeleting(false);
    if (canvasRef?.current) {
      canvasRef.current.defaultCursor = 'default'; // Reset cursor to default
    }
    setActiveFunction(Functions.NONE);

    if (currentLinear === "all") {
      setInputLineWidth(defaultLinearWidth);
    } else {
      setAlpha(currentLinearData["opacity"] ?? defaultOpacity);
      setInputLineWidth(currentLinearData["width"] ?? defaultLinearWidth);
    }
  }, [currentLinear]);

  useEffect(() => {
    setActiveFunction(Functions.NONE);

    if (currentLinear !== "all") {
      dispatch(setLinearOpacity({
        linear_id: currentLinearData.linear_id,
        value: alpha
      }));
      updateShapes((sh) => sh.linear_id === currentLinearData.linear_id, "opacity", alpha);
    }
  }, [alpha]);

  useEffect(() => {
    setActiveFunction(Functions.NONE);

    if (currentLinear !== "all") {
      dispatch(setLinearLineWidth({
        linear_id: currentLinearData.linear_id,
        value: inputLineWidth
      }));
      updateShapes((sh) => sh.isType("polyline") && sh.linear_id === currentLinearData.linear_id, "strokeWidth", inputLineWidth);
    }
  }, [inputLineWidth]);

  useEffect(() => {
    if (activeFunction === Functions.MANUAL) {
      setDrawMultipleShapes(true);
    } else{
      setIsDrawing(false);
      if (canvasRef?.current) {
        canvasRef.current.defaultCursor = 'default'; // Reset cursor to default
      }
    }
    if (activeFunction !== Functions.DELETE) {
      setIsDeleting(false);
    }
  }, [activeFunction]);

  const addLinear = (shape) => {
    
    shape.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: false,
      br: false,
      tl: false,
      tr: false,
      mtr: false
    });        
    shape.lockRotation = true;
    shape.lockMovementX = true;
    shape.lockMovementY = true;
    shape.lockScalingX = true;
    shape.lockScalingY = true;
    shape.linear_id = currentLinear;

    let pts = shape.isType("polyline") ? shape.points : [];
    
    if (angle !== 0) {
      var [originX, originY] = [0, 0];

      pts = Array.from(pts).map(x=>{return {
          x: (x.x - originX) * Math.cos(-angle * Math.PI / 180) - (x.y - originY) * Math.sin(-angle * Math.PI / 180) + originX,
          y: (x.x - originX) * Math.sin(-angle * Math.PI / 180) + (x.y - originY) * Math.cos(-angle * Math.PI / 180) + originY
      }});
    }

    let linear = {
      "id": shape.id,
      "linear_id": currentLinear,
      "page": page,
      "points": pts,
      "color": shape.fill,
      "name": linears.find(x => x.linear_id == currentLinear)?.name || "Linear"
    };
    
    dispatch(addObjectInLayer({layerId: metreLayerId, object: shape.id})); // Add the object's ID to Redux store
    dispatch(addMetre(linear));

    //setActiveFunction(Functions.NONE);
  };

  const removeLinears = (shape) => {
    dispatch(removeMetre(shape.id));
  };

  return (
    <LinearsProvider
      addLinear={addLinear}
      removeLinear={removeLinears}
    >
      <DrawShapeProvider>
        <LinearsEditor
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          loadingMessage={loadingMessage}
          setLoadingMessage={setLoadingMessage}
          page={page}
          setPage={setPage}
          activeFunction={activeFunction}
          setActiveFunction={setActiveFunction}
          currentLinear={currentLinear}
          setCurrentLinear={setCurrentLinear}
          currentLinearData={currentLinearData}
          alpha={alpha}
          setAlpha={setAlpha}
          inputLineWidth={inputLineWidth}
          setInputLineWidth={setInputLineWidth}
        />
      </DrawShapeProvider>
    </LinearsProvider>
  );
};

const LinearsEditor = ({
  isLoading, setIsLoading,
  loadingMessage, setLoadingMessage,
  page, setPage,
  activeFunction, setActiveFunction,
  currentLinear, setCurrentLinear, currentLinearData,
  alpha, setAlpha,
  inputLineWidth, setInputLineWidth }) => {
  const projectFiles = useSelector((state) => state.Comptage.ProjectInfos.project_files);
  const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
  const currentFilePages = useSelector((state) => state.Comptage.Configuration.pages);
  const currentFileData = projectFiles.find(x =>x.name === currentFile);
  const selectedPages = useSelector((state) => state.Comptage.Configuration.pages.map((x, i)=>{ return [i, x.selected];}).filter(x=>x[1] === true).map(x=>x[0]));
  const { canvasRef, setIsDeleting, setShapeType, getAllObjects, removeObjects, rearrengeObjects } = useContext(CanvasContext);
  const { startDrawing, insertPolygon, insertPolyline } = useDrawShape();
  const layers = useSelector((state) => state.image.layers);
  const zones = useSelector((state) => state.Comptage.Configuration.zones);
  const linears = useSelector((state) => state.Comptage.Configuration.linears);
  const linearsVisibility = useSelector((state) => state.Comptage.Configuration.linears.map(x => ({
    linear_id: x.linear_id,
    isVisible: x.isVisible
  })));
  const metres = useSelector((state) => state.Comptage.Decompte.metres);

  const zoneLayerId = useSelector((state) => {
    let zone = state.image.layers.find(x=>x.name === "zone");
    if (zone) {
      return zone.layerId;
    }
    else {
      return -1;
    }
  });
  const dispatch = useDispatch();
  const metreLayerId = useSelector((state) => {
    let metre = state.image.layers.find(x=>x.name === "linear");
    if (metre) {
      return metre.layerId;
    }
    else {
      return -1;
    }
  });

  const updateShapes = (filterCallback, property, value) => {
    getAllObjects().filter(filterCallback).forEach(sh => {
      sh.set(property, value);
    });
    canvasRef.current.renderAll();
  };

  useEffect(() => {
    for (let state of linearsVisibility) {
      updateShapes((sh) => sh.linear_id === state.linear_id && sh.linear_id !== undefined, "visible", state.isVisible);
    }
  }, [linearsVisibility]);

  const restoreZones = (page) => {
    for(let zone of zones.filter(x=>x.page === page)) {
      insertPolygon(zone["points"], {
        id: zone["id"],
        zone_id: zone["zone_id"],
        stroke: zone["color"],
        fill: zone["color"],
        opacity: .4,
        strokeWidth: 2,
        objectCaching: false,
        visible: true,
        layer: zoneLayerId,
        fixed: true,
        selectable: false,
        deletable: false,
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true
      });
    }
  };
  const restoreLinears = (page) => {
    for(let metre of metres.filter(x=>x.page === page)) {
      let linear = linears.find(x=>x.linear_id === metre.linear_id);

      if (linear === undefined) {
        continue;
      };

      insertPolyline(metre["points"], {
        id: metre["id"],
        linear_id: metre["linear_id"],
        stroke: linear["color"],
        fill: "#00000000",
        opacity: linear["opacity"],
        selectable: false,
        strokeWidth: linear["width"] ?? inputLineWidth,
        objectCaching: false,
        visible: true,
        layer: metreLayerId,
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true
      });
    }
  };

  const drawManual = () => {
    if (activeFunction === Functions.MANUAL) {
      setActiveFunction(Functions.NONE);
    } else {
      setActiveFunction(Functions.MANUAL);
      setIsDeleting(false);
      dispatch(setStrokeColor(currentLinearData.color));
      dispatch(setStrokeWidth(currentLinearData.width ?? inputLineWidth));
      dispatch(setOpacity(currentLinearData.opacity));
      setShapeType(ShapeType.POLYLINE);
      startDrawing();
    }
  };

  const deleteMetre = () => {
    if (activeFunction === Functions.DELETE) {
      setActiveFunction(Functions.NONE);
    } else {
      setActiveFunction(Functions.DELETE);
      setIsDeleting(true);
    }
  };

  const deleteAllMetres = () => {
    setActiveFunction(Functions.DELETE_ALL);
    YesNoAlert({
      title: "Supprimer",
      body: `Voulez-vous vraiment supprimer tous les métrés ${currentLinear === "all" ? "" : `du type ${currentLinearData.name}`}?`,
      onYes: () => {
        setIsDeleting(false);
        let objs = [];
        if (currentLinear === "all") {
          objs = getAllObjects().filter(x=>x.layer === metreLayerId);
        } else {
          objs = getAllObjects().filter(x=>x.layer === metreLayerId && x.linear_id === currentLinear);
        }
        objs.forEach(sh => {
          sh.definitiveDelete = true;
        });
        removeObjects(objs.map(x => x.id));
        setActiveFunction(Functions.NONE);
      },
      onNo: () => {
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      },
      onClose: () => {
        setIsDeleting(false);
        setActiveFunction(Functions.NONE);
      }
    });
  };

  return (
          <PDFEditor
            initialLayers={["0", "background", "zone", "linear"]}
            page={page}
            setPage={setPage}
            showScale={false}
            toolsPanel={
              <div className="h-full top-0 absolute overflow-hidden right-0 z-50">
                <SideMenu
                  activeFunction={activeFunction}
                  drawManual={drawManual}
                  deleteMetre={deleteMetre}
                  deleteAllMetres={deleteAllMetres}
                  page={page}
                  currentLinear={currentLinear}
                  currentLinearData={currentLinearData}
                  setCurrentLinear={setCurrentLinear}
                  inputLineWidth={inputLineWidth}
                  setInputLineWidth={setInputLineWidth}
                  alpha={alpha}
                  setAlpha={setAlpha}
                />
              </div>}
            visiblePages={selectedPages}
            pageCount={currentFileData === undefined ? 1 : currentFileData.pages.length}
            getImages={(p) => {
              return currentFilePages[p]["patches"];
          } }
            restoreShapes={(page) => {
              restoreZones(page);
              restoreLinears(page);
            }}
          />);
}

