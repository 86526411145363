import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef,useState } from "react";
import { GeoJSON, MapContainer, TileLayer, useMap,Polyline,Popup } from "react-leaflet";
import "react-leaflet-fullscreen/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import Geoman from "./geoman";
import GeotiffLayer from "./geotif";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import Selection from "./selection";
import { useDarkMode } from '@rbnd/react-dark-mode';
import NomenclatureDrawer from './nomenclature_drawer'


const MapInit = ({
  dataPoints,
  show_roads,
  roadsData,
  show_tools,
  show_selection_tool=false,
  show_lights =true,
  lightsData,
  display_lights_mode ='',
  onSetRectangleCoords = () => {},
  onSetZoneCalcul = () => {},
  onSetRoads = () => {},
  onSelection = () => {}, // Fonction pour effacer les éléments
}) => {

  const map = useMap();
  const dispatch = useDispatch();
  const dataRoads = useSelector((state) => state.PYM.GeoData.selected_roads);
  const dataAllRoads = useSelector((state) => state.PYM.GeoData.roads_data);
  const colorsRoads = useSelector((state) => state.PYM.ProjectInfos.axes_colors);
  const source_color_table_link = useSelector((state) => state.PYM.ProjectInfos.sources_colors)
  const [displayLightsMode,setDisplayLightsMode] = useState(display_lights_mode)
  
  const [showSelection,setShowSelection] = useState(show_selection_tool);
  const [isNotClickSelectionMode,setIsNotClickSelectionMode] = React.useState(false);

  const { mode, setMode } = useDarkMode()
  const [roadTypes, setRoadTypes] = useState(new Set());
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [needStyleFilter,setNeedStyleFilter] = React.useState(false);  
  const [showModal,setShowModal] = React.useState(false);  
  const [key,setKey] =  useState(0);

  const rectangleRef = useRef(null);
  const roadsRef = useRef([]);

  const get_road_color = (roadType) => {
    const item = colorsRoads.find(element => element.category_name === roadType);
    return item ? item.color : '#2a9d8f';
  }

  const get_luminous_point_status_color = (status) => {
    return status.toLowerCase() ==='good' ? '#43aa8b':'#f94144';
  }
  const get_luminous_point_source_color = (source_type) => {
    const item = source_color_table_link[source_type];
    return item?.color;
  }
    const get_luminous_point_color = (properties) =>{
      switch(displayLightsMode){
        case 'status':
          return get_luminous_point_status_color(properties.status);
        case 'source_detail':
          return get_luminous_point_source_color(properties.source);
          default:
            return "#277da1"
      }
    }

  const getFeatureStyle = (feature) => {
      const originalColor = feature.properties.color || '#277da1'; 
      if (needStyleFilter){
          if (selectedFeature && selectedFeature.map(x=>x.id).includes(feature.id)) {
            return { fillColor: originalColor, fillOpacity: 1.0, color: 'none' }; 
          }
          return {fillColor: originalColor, fillOpacity: .2, color: 'none' }; 
      }
    };
    const getRoadsFeatureStyle = (feature) => {
      const originalColor = get_road_color(feature.properties.category); 

      if (needStyleFilter){        
        if (selectedFeature && selectedFeature.map(x=>x.properties.id).includes(feature.properties.id)) {
            return { fillColor: originalColor, fillOpacity: 1, opacity: 1,color: originalColor}; 
          }
          return {fillColor: originalColor, fillOpacity: 0.2,opacity: 0.2, color: originalColor}; 
      }
      else{
        return {
          color:get_road_color(feature.properties.category)
        }
      }
    };
    
  useEffect(() => {
    if (dataRoads && dataRoads.length > 0) {
      setRoadTypes(new Set(dataRoads.map((y) => y.tags.highway).flat()));
    } else if(dataAllRoads && dataAllRoads.features.length > 0) {
      const tmp = dataAllRoads.features.map(element => element.properties);
      setRoadTypes(new Set(tmp.map((y) => y.category).flat()));
    }
  }, [dataRoads, dataAllRoads]);

  const pointToLayer = (feature, latlng) => {
    return L.circleMarker(latlng, {
      id:feature.properties.id,
      radius: 2,
      color: get_luminous_point_color(feature.properties),
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
    });
  };
  function onEachFeature (feature,layer){
    layer.bindPopup(()=>{
      return ` <h5>${feature.properties.code}</h5>`
    });
    layer.on({
      click: () => {
        onSelection([feature.id]);
        setShowSelection(false);
        setNeedStyleFilter(true);
        setSelectedFeature([feature])
        setIsNotClickSelectionMode(true);
      },
    })
    }
  function onEachRoadsFeature (feature,layer){
    layer.bindPopup(()=>{
      return ` <h5>${feature.properties.id}</h5>`
    });

    layer.on({
      click: () => {
        onSelection([feature.properties.id]);
        setShowSelection(false);
        setNeedStyleFilter(true);
        setSelectedFeature([feature])
        setIsNotClickSelectionMode(true);
      },
    })
    }
  
  function getCoordinates(layer) {
    const bounds = layer.getBounds();
    const coords = [
      [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
      [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
    ];
    onSetRectangleCoords(coords);
    return coords;
  }
  const customOnSelection = (idx) =>{
    onSelection(idx)
    setSelectedFeature(lightsData.filter(x =>idx.includes(x.id)));
    if(idx.length >0){
      setNeedStyleFilter(true);
    }
    else{
      setNeedStyleFilter(false);
    }
  }
  const customRoadsOnSelection = (idx) =>{
    setSelectedFeature(roadsData.filter(x =>idx.includes(x.properties.id)));
    idx = idx.map(x=>x.toString())
    onSelection(idx)
    if(idx.length >0){
      setNeedStyleFilter(true);
    }
    else{
      setNeedStyleFilter(false);
    }
  }
  const handleCustomButtonPressed = () =>{
    setDisplayLightsMode('source_detail')
    setShowModal(true);
  }
  const handleCustomButtonStylePressed = () =>{
    setSelectedFeature([]);
    onSelection([]);
    setNeedStyleFilter(false);
    setIsNotClickSelectionMode(false);
    setShowSelection(true)
}
useEffect(() => {
    map.on("pm:create", function (e) {
      var layer = e.layer;
      rectangleRef.current = layer;
      const coords = getCoordinates(layer);
      onSetRectangleCoords(coords);
      layer.on("pm:update", function (e) {
        const updatedCoords = getCoordinates(e.layer);
        onSetRectangleCoords(updatedCoords);
      });
    });
  }, [map]);

  useEffect(()=>{
    setKey(prevKey => prevKey +1);
  },[lightsData,displayLightsMode,needStyleFilter])

  return (
    <>
      {mode==='' ? <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />:<TileLayer url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png" />}
      
      {(show_lights && lightsData) && 
        <GeoJSON key={key} data={lightsData} pointToLayer={pointToLayer} onEachFeature={onEachFeature} style={getFeatureStyle}/>
      }
      {(show_lights && lightsData) && (!(isNotClickSelectionMode) && showSelection  ) && <Selection onSelection={customOnSelection}/>}
      {/* {(show_roads && roadsData) && Array.from(roadTypes).map((roadType, index) => {
        const color = get_road_color(roadType);
        const filteredRoads = roadsData.filter((element) => element.properties.category === roadType);
        return filteredRoads.map((element) => (
          <Polyline
            key={`${element.id}}`}
            id={`${element.properties.id}`}
            positions={element.geometry.coordinates.map((p) => [p[1], p[0]])}
            color={color}
            eventHandlers={{ click: e => { "e.sourceTarget" }, }}>
          </Polyline>
        ));
      })} */}
    {(show_roads && roadsData) &&  <GeoJSON key={key} data={roadsData} onEachFeature={onEachRoadsFeature} style={getRoadsFeatureStyle}/>}
    {(show_roads && roadsData)&& (!(isNotClickSelectionMode) && showSelection  ) && <Selection onSelection={customRoadsOnSelection}/>}
    {show_tools && <Geoman />}
      
      {(needStyleFilter && !(showSelection)) && <CustomControl imagePath={"/images/solar--close.png"}  onClick={handleCustomButtonStylePressed}  />}
      {displayLightsMode ==='source' && <CustomControl imagePath={"/images/solar--list.png"} onClick={handleCustomButtonPressed} />}

      <FullscreenControl />
      <NomenclatureDrawer            
          showModal={showModal}
          setShowModal={setShowModal}
          setDisplayLightsMode={setDisplayLightsMode}
        />
    </>
  );
};

const LeafletMap = ({ onRoadsChange, onRectangleCoordsChange,show_roads=false,roadsData = null,show_tools=false,needPlot=false,plotPath="",show_lights=true,lightsData = null,show_selection_tool = false,display_lights_mode='',onSelection=()=>{}}) => {
  
  const dataPoints = useSelector((state) => state.PYM.GeoData.city_lights_positions);
  const zoom_point = useSelector((state) => state.PYM.GeoData.zoom_point);
  
  const location = useLocation();

  needPlot = location.state?.needPlot ?? needPlot;
  plotPath= location.state?.plotPath ?? plotPath;

    const handleMapCreated = (map) => {
      if (map) {
        map.on('load', function() {
          map.invalidateSize();
      });      }
    };
  
  return (
    <>        
      <div className="relative h-full w-full">
        <MapContainer
          ref={handleMapCreated}
          className="rounded-lg z-0"
          center={zoom_point}
          zoom={15}
          maxZoom={18}
          attributionControl={false}
          scrollWheelZoom={true}
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            zIndex: "0  !important",
          }}
        >
          <MapInit
            dataPoints={dataPoints}
            zoomPoint={zoom_point}
            onSetRectangleCoords={onRectangleCoordsChange}
            onSetRoads={onRoadsChange}
            show_roads={show_roads}
            roadsData={roadsData}
            show_lights ={show_lights}
            lightsData={lightsData}
            show_selection_tool ={show_selection_tool}
            display_lights_mode = {display_lights_mode}
            show_tools ={show_tools}
            onSelection ={onSelection}
          />
          {needPlot && <GeotiffLayer url ={plotPath}/>}
        </MapContainer>
      </div>
    </>
  );
};
export default LeafletMap;


function CustomControl({ imagePath,onClick,buttonPosition='topleft' }) {
  const map = useMap();
  
  useEffect(() => {
    const control = L.control({ position: buttonPosition });

    control.onAdd = () => {
      const selectionDiv = L.DomUtil.create("div", "leaflet-bar leaflet-control leaflet-control-custom bg-white h-[34px] w-[34px] ");
      selectionDiv.innerHTML = `<button class="leaflet-bar-part leaflet-bar-part-single"><img src=${imagePath} alt="Cursor" /></button>`;
      selectionDiv.addEventListener("click", onClick);

      return selectionDiv;
    };

    control.addTo(map);

    return () => {
      control.remove();
    };
  }, [map, onClick]);

  return null;
}
