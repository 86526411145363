import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Input, Checkbox, Autocomplete, AutocompleteItem, Pagination, Image, DropdownTrigger, Dropdown, Button, DropdownMenu, DropdownItem, DropdownSection} from "@heroui/react";
import {useAsyncList} from "@react-stately/data";
import { FilterTypeEnum } from "./filters/filters-types";
import { ChevronDownIcon, SearchIcon, SolarCloudDownloadBroken } from "../Icons";
import { useDispatch } from "react-redux";
import DelayedInput from "../inputs/delayed-input";

/*
  columns:[{
    uid (obligatoire)
    name (obligatoire)
    type ["text", "email", "number", "bool", "list", "image"];
    isEditable
    sortable
    disabled
    valueAccessor
    valueMutator
    renderCell
  }]
  Si l'uid de la colonne est "selected", les lignes pour lesquelles selected=true seront d'une couleur différente.
  Ne pas utiliser l'uid "selected" si ce comportement n'est pas recherché.
*/

const groupBy = (arr, properties, columns) => {
  const groups = [];
  const groupMap = new Map();

  arr.forEach(item => {
    let currentLevelMap = groupMap;

    properties.forEach((property, index) => {
      let column = columns.filter(x=> x["uid"] === property)[0];

      let key = (column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]]);

      let nextLevelMap = currentLevelMap.get(key);

      if (!nextLevelMap) {
        nextLevelMap = index === properties.length - 1 ? [] : new Map();
        currentLevelMap.set(key, nextLevelMap);
      }

      if (index === properties.length - 1) {
        nextLevelMap.push(item);
        // Only push to the final result array if it's the last property
        if (!groups.includes(nextLevelMap)) {
          groups.push(nextLevelMap);
        }
      } else {
        currentLevelMap = nextLevelMap;
      }
    });
  });

  return groups;
};
const allEqual = (arr) => new Set(arr).size === 1;

const allowedTypes = ["text", "email", "number", "bool", "list", "image"];
// list need listItems key

const LinesPerPage = ({
  nbItems,
  rowsPerPage
}) => {
  return (<div className="flex justify-between items-center">
      <span className="text-default-400 text-small">
        Total {nbItems} données
      </span>
      <label className="flex items-center text-default-400 text-small hidden">
        Lignes par pages
        <select
          className="bg-transparent outline-none text-default-400 text-small"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </label>
    </div>);
}

const Columns = ({
  columns,
  visibleColumns,
  setVisibleColumns
}) => {
  return (<Dropdown>
    <DropdownTrigger className="hidden sm:flex">
      <Button endContent={<ChevronDownIcon className="text-small" />} size="sm" variant="flat">Colonnes</Button>
    </DropdownTrigger>
    <DropdownMenu
      disallowEmptySelection
      aria-label="Table Columns"
      closeOnSelect={false}
      selectedKeys={visibleColumns}
      selectionMode="multiple"
      onSelectionChange={setVisibleColumns}
    >
      {columns.map((column) => (<DropdownItem key={column.uid} className="">{column.name}</DropdownItem>))}
    </DropdownMenu>
  </Dropdown>);
}

const Groups = ({
  columns,
  groupColumns,
  setGroupColumns
}) => {
  return (<Dropdown>
    <DropdownTrigger className="hidden sm:flex">
      <Button variant="flat" size="sm" endContent={<ChevronDownIcon className="text-small" />} className="">Grouper</Button>
    </DropdownTrigger>
    <DropdownMenu
      disallowEmptySelection
      aria-label="Table Columns"
      closeOnSelect={false}
      selectedKeys={groupColumns}
      selectionMode="multiple"
      onAction={(key)=>{setGroupColumns(gc => {
        if (gc.includes(key)) {
          return [...gc].filter(x => x !== key);
        } else{
          return [...gc, key];
        }
      })}}
    >
      {columns.map((column) => (<DropdownItem key={column.uid} className="">{column.name}</DropdownItem>))}
    </DropdownMenu>
  </Dropdown>);
}

export default function EditableTable({
  title="", 
  filterBarContent,
  columns=[],
  defaultGroupedColumns=[],
  data, // must be a state
  filters, // must be a state
  setData=undefined,
  reduxTableSaveAction=undefined,
  pagination=false,
  paginationProps={},
  rowsPerPage=20,
  downloadable=false,
  groupable=true,
  resizable=false,
  showColumns=true,
  showTotal=true,
  sortable=true,
  selectable=false,
  showTopRibbon=true,
  emptyContent=undefined,
  tableBodyProps={},
  onRowEdit=undefined,
  tableBackground="glass",
  headerButtons = undefined,
  functionsRef = undefined,
  minColumnWidth = 10,
  delayEdit = 10,
  ...props
}) {
  const dispatch = useDispatch();
  let useGlass = tableBackground.includes("glass");

  const [nbItems, setNbItems] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(data !== undefined ? Math.ceil(data.length / rowsPerPage) : 1); 
  const [filterValue, setFilterValue] = React.useState("");
  const [visibleColumns, setVisibleColumns] = React.useState("all");
  const [groupColumns, setGroupColumns] = React.useState(defaultGroupedColumns);
  const [allRowsSelected, setAllRowsSelected] = React.useState(false);
  const [someRowsSelected, setSomeRowsSelected] = React.useState(false);
  
  const [columnWidths, setColumnWidths] = useState({});

  /* Set visible columns */
  const activeColumns = React.useMemo(() => {
    if (visibleColumns === "all") return (columns ?? []);
    return columns === undefined ? [] : columns.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns, columns]);


  const handleResize = useCallback((colKey, newWidth) => {
    setColumnWidths((prev) => ({
      ...prev,
      [colKey]: {
        minWidth: prev[colKey]?.minWidth ?? minColumnWidth,
        maxWidth: prev[colKey]?.maxWidth ?? 100,
        width: newWidth ?? 100,
      },
    }));
  }, [minColumnWidth]);

  const handleMouseDown = useCallback((colKey, e) => {
    const startX = e.clientX;
    const startWidth = columnWidths[colKey]?.width ?? 100;

    const handleMouseMove = (e) => {
      const newWidth = Math.max(minColumnWidth, startWidth + (e.clientX - startX)); // Prevent very small widths
      handleResize(colKey, newWidth);
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  }, [columnWidths, handleResize, minColumnWidth]);


  useImperativeHandle(functionsRef, () =>({
    goToLastPage() {
      setPage(pages);
    }
  }));

  useEffect(() => {
    if (page > pages) {
      setPage(Math.max(pages, 1));
    }
  }, [page, pages]);

  /* Prepare visible data */
  const transformedData = React.useMemo(() => {
    let filteredData = data ?? [];

    // Apply filter text
    if (filterValue !== undefined) {
      const isFilteredItem = (column, item, text) => {
        let value = (column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]]);
        if (value === undefined) {
          return false;
        } else if(typeof value === 'string') {
          return value.toLowerCase().includes(text.toLowerCase());
        } else if (Array.isArray(value)) {
          return value.some(x => x.toLowerCase().includes(text.toLowerCase()));
        }
        return false;      
      };
      filteredData = filteredData.filter(item => columns.some(column => isFilteredItem(column, item, filterValue)));
    }

    // Apply external filters
    if (filters !== undefined) {
      for (let f of filters) {
        if (!columns.map(x=>x["uid"]).includes(f.key) && f.type !==  FilterTypeEnum.IDInList){
          continue;
        }

        let column = columns.filter(x=> x["uid"] === f.key)[0];
        switch (f.type) {
          case FilterTypeEnum.Tabs:
            filteredData = filteredData.filter(item => (column["filterValueAccessor"] !== undefined ? column["filterValueAccessor"](item) : column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]]) === f.value);
            break;
          case FilterTypeEnum.Range:
            if (column["type"] === "number") {
              filteredData = filteredData.filter(item => (column["filterValueAccessor"] !== undefined ? column["filterValueAccessor"](item) : column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]]) >= f.min && (column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]]) <= f.max);
            }
            break;
          case FilterTypeEnum.CheckboxGroup:
          case FilterTypeEnum.TagGroup:
            if (f.values.length > 0) {
              switch (column["type"]) {
                case "text":
                case "email":
                  filteredData = filteredData.filter(item => f.values.includes((column["filterValueAccessor"] !== undefined ? column["filterValueAccessor"](item) : column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]])));
                  break
                case "list":
                  filteredData = filteredData.filter(item => f.values.some(x => (column["filterValueAccessor"] !== undefined ? column["filterValueAccessor"](item) : column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]]).includes(x)));
                  break
                default:
                  break
              }
            }
            break;
          case FilterTypeEnum.Text:
            filteredData = filteredData.filter(item => (column["filterValueAccessor"] !== undefined ? column["filterValueAccessor"](item) : column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]]).toLowerCase().startsWith(f.value.toLowerCase()));
            break;
          case FilterTypeEnum.IDInList:
            if (f.values.length > 0) {
              filteredData = filteredData.filter(item => f.values.includes(item["id"].toString()));
            }
            break
          default:
            break;
        }
      }
    }

    if (filteredData.length === 0) {
      return [];
    }

    const aggregateData = (items, groupsProperties) => {
      if (groupsProperties.length === 0) {
        return items;
      }

      let groups = groupBy(items, groupsProperties, columns);
      
      let groupedData = groups.map(arr => {
        let newItem = {};
        columns.forEach(col => {
            let mutator = col.valueMutator;
            let newValue = "";
            if (col.type === "number" && col.agg && col.agg === "sum") {
              newValue = arr.map(item => (col["valueAccessor"] !== undefined ? col["valueAccessor"](item) : item[col["uid"]]))
                .map(x => {
                  let n = x.toString();
                  if (!isNaN(parseFloat(n)) && isFinite(n)) {
                    return parseFloat(n);
                  } else {
                    return 0;
                  }
                })
                .reduce((acc, currentValue) => {
                  return acc + currentValue
                }, 0);
            } else {
              newValue = allEqual(arr.map(item=>(col["valueAccessor"] !== undefined ? col["valueAccessor"](item) : item[col["uid"]]))) ? (col["valueAccessor"] !== undefined ? col["valueAccessor"](arr[0]) : arr[0][col["uid"]]) : "";
            }
            
            if (mutator !== undefined) {
              newItem = mutator(newItem, newValue);
            } else{
              newItem[col["uid"]] = newValue;
            }
        });
        newItem["groupIds"] = arr.map(i => i["id"])
        return newItem;
      });

      groupedData.forEach((x, idx) => {
        x["id"] = idx;
      });

      return groupedData;
    };
    let groupedData = aggregateData(filteredData, Array.from(groupColumns));
    
    setNbItems(groupedData.length);

    return groupedData;
  }, [data, filterValue, filters, groupColumns, columns]);
  
  /* Sort parametrization */
  let list = useAsyncList({
    async load() {
      return {
        items: transformedData,
      };
    },
    async reload() {
      return {
        items: transformedData,
      };
    },
    async sort({items, sortDescriptor}) {
      return {
        items: items.sort((a, b) => {
          let column = columns.find(x => x.uid === sortDescriptor.column);
          
          let first = column !== undefined && column["valueAccessor"] !== undefined ? column["valueAccessor"](a) : a[column["uid"]];
          let second = column !== undefined && column["valueAccessor"] !== undefined ? column["valueAccessor"](b) : b[column["uid"]];
          let cmp = first < second ? -1 : 1;

          if (sortDescriptor.direction === "descending") {
            cmp *= -1;
          }
          return cmp;
        }),
      };
    },
  });

  useEffect(() => {
    list.reload();
  }, [transformedData]);

  // useEffect(() => {
  //   if (reduxTableSaveAction !== undefined) {
  //     dispatch(reduxTableSaveAction(data));
  //   }
  // }, [data, dispatch, reduxTableSaveAction]);

  const paginatedData = React.useMemo(() => {
    if (!pagination){
      return list.items;
    }
    
    // Change pagination
    setPages(Math.ceil(list.items.length / rowsPerPage));
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    
    return list.items.slice(start, end);
  }, [pagination, list.items, rowsPerPage, page]);

  /* Columns minimum values checked or autocompletion */
  const updateColumns = useCallback(() => {
    columns.forEach(element => {
      if (!allowedTypes.includes(element["type"])) {
        element["type"] = "text";
      }

      if (element["type"] === "list" && !element.hasOwnProperty("listItems")) {
        element["listItems"] = [];
      }

      if (!element.hasOwnProperty("sortable")) {
        element["sortable"] = true;
      }

      if (setData === undefined) {
        element["isEditable"] = false;
      }

      if (element.hasOwnProperty("valueAccessor") && element["valueAccessor"] !== undefined && (!element.hasOwnProperty("valueMutator") || (element["valueMutator"] === undefined))) {
        throw new Error("Value accessor defined without value mutator");
      }
      if (element.hasOwnProperty("valueMutator") && element["valueMutator"] !== undefined && (!element.hasOwnProperty("valueAccessor") || (element["valueAccessor"] === undefined))) {
        throw new Error("Value mutator defined without value accessor");
      }
    });
  }, [columns, setData]);


  useEffect(() => {
    if (sortable) {
      updateColumns();
    }
  }, [])

  useEffect(() => {
    if (sortable) {
      updateColumns();
    }

    setColumnWidths(cw => {
      return Object.fromEntries(columns.map(x=> [x.uid, {
        minWidth: x.minWidth ?? minColumnWidth,
        maxWidth: x.maxWidth ?? 100,
        width: cw.hasOwnProperty(x.uid) ? cw[x.uid].width : (x.width ?? minColumnWidth),
      }]));
    });
  }, [columns, setData, updateColumns, sortable, minColumnWidth]);

  /* Update data on user edit */
  const updateValues = useCallback((itemId, columnId, newValue, groupIds) => {
    if (onRowEdit) {
      onRowEdit(data.find(x => x.id === itemId));
    }

    setData(fd => {
      let modifiedItems = [];
      let nfd = [...fd].map(d => {
        if (groupIds === undefined) {
          if (d["id"] === itemId) {
            let newItem = {...d}
            const column = columns.find(x => x["uid"] === columnId);
            let mutator = column.valueMutator;
            if (mutator !== undefined) {
              newItem = mutator(newItem, newValue);
            } else{
              newItem[columnId] = newValue;
            }

            let onValueChange = column.onValueChange;
            if (onValueChange !== undefined) {
              newItem = onValueChange(newItem);
            }

            modifiedItems.push(newItem);
            return newItem;
          }
        } else if (Array.isArray(groupIds)) {
          if (groupIds.includes(d["id"])) {
            let newItem = {...d};
            const column = columns.find(x => x["uid"] === columnId);
            let mutator = column.valueMutator;
            if (mutator !== undefined) {
              newItem = mutator(newItem, newValue);
            } else{
              newItem[columnId] = newValue;
            }

            let onValueChange = column.onValueChange;
            if (onValueChange !== undefined) {
              newItem = onValueChange(newItem);
            }

            modifiedItems.push(newItem);
            return newItem;
          }
        }

        return {...d};
      });

      if (reduxTableSaveAction !== undefined) {
        dispatch(reduxTableSaveAction(modifiedItems));
      }

      return nfd;
    });
  }, [columns, data, dispatch, onRowEdit, reduxTableSaveAction, setData]);

  const selectRow = useCallback((event) => {
    //To select a row if we click on a cell that is not editable (set "selected" as true if it was false and vice-versa).
    if (selectable) {
      let [columnKey, itemId] = event.target.closest("td").getAttribute("data-key").split(',');
      const notEditable = columns.filter(item => !item.isEditable).map(item => item.uid);
      if (notEditable.includes(columnKey)) {
        let item = data.find(x=>x.id === itemId);
        if (item) {
            updateValues(itemId, "selected", !item["selected"]);
        }
      }
    }
  }, [columns, data, selectable, updateValues]);

  /* Render cells as Editable or not */
  // Render cell value
  const defaultRenderCell = useCallback((item, column) => {
    switch (column["type"]) {
      case "text":
      case "email":
      case "number":
      case "bool":
      case "list":
        return (<p className="text-bold text-sm text-black" key={item} disabled={column["disabled"]}>
          {column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]]}
        </p>)
      case "image":
        return (<Image className="rounded-none" src={column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]]} height={column.hasOwnProperty("height") ? column["height"] : undefined}></Image>)
      default:
        return (item[column["uid"]]);
    }
  }, []);

  const inputRefs = useRef({});

  // Render editable cell
  const defaultRenderEditableCell = useCallback((item, column) => {
    let value = column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]];

    const handleNumberChange = (v) => {
      let clippedValue = v;  
      if (v && column["min"])
      {
        clippedValue = Math.max(column["min"], clippedValue);
      }
      
      if (v && column['max'])
      {
        clippedValue = Math.min(column["max"], clippedValue);
      }
          
      updateValues(item["id"], column["uid"], clippedValue, item["groupIds"]);
    };
    const defaultHandleChange = (v)=>{
      updateValues(item["id"], column["uid"], v, item["groupIds"])
    };

    
    switch (column["type"]) {
      case "image":
        return (<Image src={column["valueAccessor"] !== undefined ? column["valueAccessor"](item) : item[column["uid"]]} height={column.hasOwnProperty("height") ? column["height"] : undefined}></Image>)
      case "bool":
        return (<Checkbox isSelected={value} isDisabled={column["disabled"]} onValueChange={(v)=>{updateValues(item["id"], column["uid"], v, item["groupIds"])}}></Checkbox>)
      case "list":
        // if (typeof value === 'string') {
        //   value = [value];
        // }
        return (<Autocomplete
                  aria-label="Dropdown"
                  defaultItems={column["listItems"]}
                  defaultSelectedKey={value}
                  isDisabled={column["disabled"]} 
                  onSelectionChange={(v)=>{
                    updateValues(item["id"], column["uid"],v, item["groupIds"])}}
                >
                  {column["listItems"].map(x=>(<AutocompleteItem key={x} value={x}>{x}</AutocompleteItem>))}
                </Autocomplete>)
      case "number":
        if (delayEdit <= 10) {
            return <Input
                aria-label="Number"
                type={column["type"]}
                value={value}
                isDisabled={column["disabled"]}
                min={column["min"]}
                max={column["max"]}
                step={column["step"] ?? 1}
                onValueChange={handleNumberChange}>
            </Input>;
        }
        else {
          return <DelayedInput
                    key={`${item.id}-${column.uid}`} // Ensure React reuses the input
                    ref={(el) => (inputRefs.current[`${item.id}-${column.uid}`] = el)}
                    aria-label="Number"
                    type={column["type"]}
                    externalValue={value}
                    delay={delayEdit}
                    isDisabled={column["disabled"]}
                    min={column["min"]}
                    max={column["max"]}
                    step={column["step"] ?? 1}
                    onInputChanged={handleNumberChange}
                    classNames={{
                    inputWrapper: 'group-data-[focus-visible=true]:ring-0 group-data-[focus-visible=true]:ring-offset-0'
                  }}>
              </DelayedInput>;
        }
      case "text":
      case "email":
        if (delayEdit <= 10) {
            return <Input
                aria-label="Text"
                type={column["type"]}
                value={value}
                isDisabled={column["disabled"]}
                onValueChange={defaultHandleChange}>
            </Input>;
        }
        else {
          return <DelayedInput
                    key={`${item.id}-${column.uid}`} // Ensure React reuses the input
                    ref={(el) => (inputRefs.current[`${item.id}-${column.uid}`] = el)}
                    aria-label="Text"
                    type={column["type"]}
                    externalValue={value}
                    delay={delayEdit}
                    isDisabled={column["disabled"]}
                    onInputChanged={defaultHandleChange}
                    classNames={{
                    inputWrapper: 'group-data-[focus-visible=true]:ring-0 group-data-[focus-visible=true]:ring-offset-0'
                  }}>
              </DelayedInput>;
      }
      default:
        if (delayEdit <= 10) {
            return <Input
                aria-label="Text"
                type={column["type"]}
                value={value}
                isDisabled={column["disabled"]}
                onValueChange={defaultHandleChange}>
            </Input>;
        }
        else {
          return <DelayedInput
                    key={`${item.id}-${column.uid}`} // Ensure React reuses the input
                    ref={(el) => (inputRefs.current[`${item.id}-${column.uid}`] = el)}
                    aria-label="Text"
                    type={column["type"]}
                    externalValue={value}
                    delay={delayEdit}
                    isDisabled={column["disabled"]}
                    onInputChanged={defaultHandleChange}
                    classNames={{
                    inputWrapper: 'group-data-[focus-visible=true]:ring-0 group-data-[focus-visible=true]:ring-offset-0'
                  }}>
              </DelayedInput>;
        }
    }
  }, [delayEdit, updateValues]);

  // Render cell function choice
  const renderCell = useCallback((item, columnKey, columns) => {
    let column = columns.filter(c=>c["uid"] === columnKey)[0];
    if (column["renderCell"] !== undefined) {
      return column["renderCell"](item);
    }
    else {
      if (column["isEditable"]) {
        return defaultRenderEditableCell(item, column);
      }
      else {
        return defaultRenderCell(item, column);
      }
    }
  }, [defaultRenderCell, defaultRenderEditableCell]);

  /* Search bar */
  const onSearchChange = React.useCallback((value) => {
    if (value) {
        setFilterValue(value);
        setPage(1);
    } else {
        setFilterValue("");
    }
  }, []);

  const handleDownloadData = () => {
    console.log("Pas encore disponible");
  };

  useEffect(() => {
    if (selectable) {
      setAllRowsSelected(data.every(item => item.selected));
      setSomeRowsSelected(data.some(item => item.selected) && !data.every(item => item.selected));
  }}, [data, selectable]);

  const selectUnselect = useCallback((value) => {
    setAllRowsSelected(value);
    data.forEach(item => updateValues(item.id, "selected", value));
  }, [data, updateValues]);

  const topContent = React.useMemo(() => {
    return (
      <div className="mt-2 mr-2 ml-2 flex flex-col gap-4">
        {title}
        <div className="flex justify-between gap-3 items-end">
          <Input
                isClearable
                classNames={{
                  base: `w-full ${(downloadable || showColumns || groupable) ? "sm:max-w-[44%]" : ""}`,
                  inputWrapper: "border-1",
                }}
                placeholder="Rechercher ..."
                size="sm"
                startContent={<SearchIcon className="text-default-300" />}
                value={filterValue}
                variant="bordered"
                onClear={() => setFilterValue("")}
                onValueChange={(v) => onSearchChange(v)}
          />

          <div className="flex gap-3">
            {/* Download  */}
            {downloadable && <Button
              endContent={<SolarCloudDownloadBroken />}
              size="sm"
              variant="flat"
              onClick={(e) => handleDownloadData(e)}
            >
              Télécharger
            </Button>}
            {headerButtons}
            {showColumns && <Columns
              columns={columns}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              />}
            {groupable && <Groups 
              columns={columns}
              groupColumns={groupColumns}
              setGroupColumns={setGroupColumns}
              />}
            {filterBarContent}
          </div>

        </div>

        {showTotal && <LinesPerPage 
          nbItems={nbItems}
          rowsPerPage={rowsPerPage}
          />}
      </div>
    );
  }, [title, downloadable, showColumns, groupable, filterValue, headerButtons, columns, visibleColumns, groupColumns, filterBarContent, showTotal, nbItems, rowsPerPage, onSearchChange]);
  
  const bottomContent = React.useMemo(() => {
    return (
      <div className="flex w-full justify-center">
        <Pagination
          showControls
          showShadow
          color="default"
          page={page}
          total={pages}
          onChange={(page) => setPage(Math.max(page, 1))}
          {...paginationProps}
        />
      </div>
    );
  }, [page, pages, paginationProps]);

  return (
    <div className={props.removeWrapper ? "h-full w-full" : (`z-0 flex flex-col relative justify-between gap-4 rounded-large w-full ${tableBackground}` + (useGlass ? ' p-4' : ''))}>
      <div className={props.removeWrapper ? "h-full w-full" : "h-full w-full flex"}>
        {activeColumns !== undefined && activeColumns.length > 0 && <Table
          fullWidth={true}
          aria-label="Table with custom cells"
          topContent={showTopRibbon && topContent}
          bottomContent={pagination && bottomContent}
          topContentPlacement="outside"
          bottomContentPlacement="outside"
          sortDescriptor={list.sortDescriptor}
          onSortChange={list.sort}
          classNames={{
            wrapper: (useGlass ? "bg-transparent overflow-auto shadow-none pt-0 rounded-none": 'w-full'),
            th: (useGlass ? 'glass' : ''),
          }}
          {...props}
        >
          <TableHeader columns={activeColumns ?? []} className="select-none">
            {activeColumns.map((column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                onMouseDown={resizable ? (e) => handleMouseDown(column.uid, e) : undefined}
                width={columnWidths[column.uid]?.width ?? 100}
                minWidth={columnWidths[column.uid]?.minWidth ?? minColumnWidth}
                maxWidth={columnWidths[column.uid]?.maxWidth ?? 800}
                className="p-0"
                style={(resizable ? {
                  minWidth: columnWidths[column.uid]?.minWidth ?? minColumnWidth,
                  cursor: 'col-resize',
                  userSelect: "none"
                } : undefined)}
                allowsSorting={column["sortable"]}
                >
                {column.uid === "selected" ?
                  <Checkbox
                  defaultChecked={allRowsSelected}
                  checked={allRowsSelected}
                  isIndeterminate={someRowsSelected}
                  onChange={(e) => { setSomeRowsSelected(false); } }
                  onValueChange={(v) => selectUnselect(v)}>
                  </Checkbox>
                  : 
                  <div
                    onMouseDown={resizable ? (e) => handleMouseDown(column.uid, e) : undefined}
                    className={resizable ? "border-l-2 pl-3 border-default-300 h-[55%] w-fit align-middle items-center inline-flex cursor-col-resize" : "h-[55%] w-fit align-middle items-center inline-flex ml-3"}
                  >
                    <div>
                      {column.name}
                    </div>
                  </div>
                  } 
              </TableColumn>
            ))}
          </TableHeader>
          <TableBody items={paginatedData ?? []} emptyContent={emptyContent} {...tableBodyProps}>
            {(item) => (
                <TableRow
                  key={item.id}
                  style={{backgroundColor: item.isInvalid ? "rgb(255 150 64 / 0.4)" : item.selected ? "rgb(255 255 255 / 0.35)" : 'inherit'}}
                >
                  {(columnKey) => <TableCell onClick={selectRow} key={columnKey + ',' + item.id}>{renderCell(item, columnKey, columns)}</TableCell>}
                </TableRow>
            )}
          </TableBody>
        </Table>}
    </div>
    </div>
  );
}
