import React from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import L from "leaflet";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Luminaire = () => {

  return <div>coucou</div>
  // return (
  //   // <MapContainer
  //   //   style={{ height: "100vh", width: "100%" }}
  //   //   center={[51.505, -0.09]}
  //   //   zoom={13}
  //   // >
  //     {/* <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" /> */}
  //   //   <GeoJSON data={filteredData} pointToLayer={pointToLayer} />
  //   // </MapContainer>
  // );
};

export default Luminaire;
