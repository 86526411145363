import FiltersWrapper from '../../../../components/table/filters/filters-wrapper'
import {FilterTypeEnum} from "../../../../components/table/filters/filters-types";
import { useSelector,useDispatch } from "react-redux";
import {Icon} from "@iconify/react";

import React, { useState,useMemo,useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import EditableTable from '../../../../components/table/editable-table';
import { updateRoadsProperties,DeleteRoadsOnProperties } from "../../../../store/features/PYM/GeoSlice";
import LeafletMap from "../map";
import { Spacer,Button } from "@heroui/react";
import { useOutletContext } from "react-router-dom";
import config from "../../../../config";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import DeleteModal from "../components/delete-modal";
import { useSoluxMap } from '../side-bar/provider';
import { lightsCat } from '../utils/constants';

const surfaceType = [
  {
    "title": "Pavée",
    "value": "paved"
  },
  {
    "title": "Asphalte",
    "value": "asphalt"
  },
  {
    "title": "Béton",
    "value": "concrete"
  },
  {
    "title": "Gravier",
    "value": "gravel"
  },
  {
    "title": "Herbe",
    "value": "grass"
  },
  {
    "title": "Terre",
    "value": "dirt"
  },
  {
    "title": "Boue",
    "value": "mud"
  },
  {
    "title": "Sable",
    "value": "sand"
  }
]
const categoryType = [
  {
    title: "Axe principal",
    value: "main_axe",
    icon: "solar:video-frame-cut-line-duotone",
  },
  {
    title: "Axe secondaire",
    value: "secondary_axe",
    icon: "solar:branching-paths-down-line-duotone",
  },
  {
    title: "Axe tertiaire",
    value: "tertiaire_axe",
    icon: "solar:home-line-duotone",
  },
  {
    title: "Axe piéton",
    value: "walking_axe",
    icon: "solar:walking-line-duotone",
  },

]
const filterDataItems = [
    {
      type: FilterTypeEnum.TagGroup,
      key: "highway",
      title: "Type",
      defaultOpen: true,
      options: [
        {
          title: "Autoroute",
          value: "motorway",
          icon: "mdi:highway",
        },
        {
          title: "Voie rapide ou voie express",
          value: "trunk",
          icon: "fluent:road-24-filled",
        },
        {
          title: "Nationales",
          value: "primary",
          icon: "fluent:road-24-regular",
        },
        {
          title: "Départementales",
          value: "secondary",
          icon: "material-symbols-light:road",
        },
        {
          title: "Petites routes",
          value: "tertiary",
          icon: "healthicons:paved-road-alt-outline",
        },            {
          title: "Voie de desserte locale",
          value: "unclassified",
          icon: "healthicons:paved-road-outline",
        },            {
          title: "Zone résidentielle",
          value: "residential",
          icon: "fluent:building-townhouse-32-regular",
        },
      ],
    },
    {
      type: FilterTypeEnum.TagGroup,
      key: "category",
      title: "Catégorie",
      defaultOpen: true,
      options: categoryType,
    },
    {
      type: FilterTypeEnum.CheckboxGroup,
      key: "surface",
      title: "Surface",
      defaultOpen: false,
      options: surfaceType,
    },
  ];
const highwayType = [
  {
    title: "Autoroute",
    value: "motorway",
  },
  {
    title: "Voie rapide ou voie express",
    value: "trunk",
  },
  {
    title: "Nationales",
    value: "primary",
  },
  {
    title: "Départementales",
    value: "secondary",
  },
  {
    title: "Petites routes",
    value: "tertiary",
  },            {
    title: "Voie de desserte locale",
    value: "unclassified",
  },            {
    title: "Zone résidentielle",
    value: "residential",
  }];

export default function Voiries({needFilterSideBar=true,needAllRoads=true}) {
  // Déclaration des states 
  const [isLoading, setIsLoading, loadingMessage, setLoadingMessage] = useOutletContext();
  const { launch_request } = useAzureFuncApp();
  const projectInfos = useSelector((state) => state.PYM.ProjectInfos);
  const dispatch = useDispatch();

  const { roadsData, setRoadsData } = useSoluxMap();
  const roadsDataTable = useMemo(() => {
    return roadsData.map(x => x.properties);
  }, [roadsData]);
  const selected_roads = useSelector((state) => state.PYM.GeoData.selected_roads);
  const lightsCatKeys = Object.keys(lightsCat);
  
  const [filters, setFilters] = useState([]);
  const [subCategoryType, setSubCategoryType] = React.useState([]);
  
  const [wantDeleteFile, setWantDeleteFile] = React.useState(undefined);
  const [showDeleteModal,setShowDeleteModal] = useState(false);
  
  useEffect(() => {
    getRoadsProfile().then((result) => setSubCategoryType(result));
  }, [])
  
  
  var initial_visible_columns = useMemo(() => { 
    return [
      {
        name: "ID",
        uid: "id",
        valueAccessor: (item) => item.id,

        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem = { ...newItem, id: value };
          return newItem;
        },
        isEditable: false,
        type: 'number',
      },  
      {
        name: "Type",
        uid: "highway",
        filterValueAccessor: (item) => highwayType.find(x => x.value === item.highway)?.value,
        valueAccessor: (item) => highwayType.find(x => x.value === item.highway)?.title,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem = { ...newItem, highway: highwayType.find(x => x.title === value)?.value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
      },
      {
        name: "Catégorie",
        uid: "category",
        filterValueAccessor: (item) => categoryType.find(x => x.value === item.category)?.value,
        valueAccessor: (item) => categoryType.find(x => x.value === item.category)?.title,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem = { ...newItem, category: categoryType.find(x => x.title === value)?.value };
          return newItem;
        },
        isEditable: true,
        type: 'list',
        listItems: categoryType.map(item => item.title),
        width: "500px",
      },
      {
        name: "Sous catégorie",
        uid: "subcategory",
        valueAccessor: (item) => subCategoryType.find(x => x.name === item.subcategory)?.name,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem = { ...newItem, subcategory: subCategoryType.find(x => x.name === value)?.name };
          return newItem;
        },
        isEditable: true,
        type: 'list',
        listItems: subCategoryType.map(item => item.name),
        width: "500px",
      },
      {
        name: "Classe d'éclairage",
        uid: "class",
        valueAccessor: (item) => lightsCatKeys.find(x => x === item.class),
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem = { ...newItem, class: value };
          return newItem;
        },
        isEditable: true,
        type: 'list',
        listItems: lightsCatKeys.map(item => item),
        width: "500px",
      },
      {
        name: "Surface",
        uid: "surface",
        valueAccessor: (item) => item.surface,
        valueMutator: (item, value) => {
          let newItem = { ...item };
          newItem = { ...newItem, surface: value };
          return newItem;
        },
        isEditable: false,
        type: 'text',
      },
            {
              name: "",
              uid: "action",
              isEditable: false,
              type: 'text',
              renderCell: (item) => {
                return (
                  <div className="flex flex-row gap-1">
                    <Button isIconOnly variant="light" onClick={() => { handleDeleteFile(item) }}>
                      <Icon icon="solar:trash-bin-minimalistic-2-line-duotone" width={25} style={{ color: "#ff0000" }} />
                    </Button>
                  </div>
                )
              }
            }
      
    ].filter(x => x.type !== "list" || (x.type === "list" && x.listItems.length > 0));
  }, [subCategoryType]);

  async function getRoadsProfile() {
    const payload = {
      functionName: "getRoadsProfiles",
      projectPath: projectInfos.project_path || "",
    };

    setLoadingMessage("Récupération des profils de route...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    if (status_code === 200) {
      setIsLoading(false);
      return result.roadsList
    }
    setIsLoading(false);
  }
  const handleDeleteFile = (item) => {
    setWantDeleteFile(item);
    setShowDeleteModal(true);
  }

  const handleSelectedRoads = (idxSelected) =>{
    setFilters(() => {
      let newFilters = [];

      if (idxSelected.length > 0) {
        newFilters.push({
          key: "",
          values: idxSelected.map(x => x.toString()),
          type: FilterTypeEnum.IDInList
        });
      }

      return newFilters;
    });
  }
  async function handleSaveRoadType() {
    const payload = {
      functionName: "saveRoads",
      projectPath: projectInfos.project_path || "",
      roads: roadsData,
    };
    setLoadingMessage("Sauvegarde de la voirie...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    if (status_code === 200) {
      setIsLoading(false);
      return result.roadsList
    }
    setIsLoading(false);

  }
  async function deleteFile(item) {
    const payload = {
      functionName: "modifyRoadsInput",
      filePath:projectInfos.project_path,
      action:"delete",
      item: item
    };
    setLoadingMessage("Suppression de la route...");
    setIsLoading(true);
    let [status_code, result] = await launch_request(
      config.pymParseFile,
      payload
    );
    setIsLoading(false);
    if (status_code === 200) {
      setRoadsData(rd => {
        return [...rd].filter(x => x.properties.id !== item.id);
      });

    }
    setShowDeleteModal(false);
  }

  const headerButtons = <>
        <Button isIconOnly onClick={() => { handleSaveRoadType() }} className='bg-[#80ed99]' ><Icon icon="fluent:save-24-regular" width={55} height={55} style={{ 'color': 'white' }} /></Button>
  </>

  // Render
  return (
  <>
    {needFilterSideBar &&
    <div className='flex flex-col'>
      <FiltersWrapper
        items={filterDataItems}
        setFilters={setFilters}
        showTitle={false}
        title="Voiries"
        className="overflow-y-auto"
      /> 
      <div className='max-h-[500px] min-h-[300px] w-full pl-[1.5rem] pb-[1.5rem]'>
        <LeafletMap 
          show_roads={true} 
          roadsData={roadsData}
          show_lights={false} 
          onSelection={handleSelectedRoads}
          show_selection_tool={true}
        />
      </div>
    </div> 
    }
    <EditableTable
      pagination={true}
      columns={initial_visible_columns} 
      data={roadsDataTable}
      setData={(callback) => {
        let newData = callback(roadsDataTable);
        setRoadsData(rd => {
          return rd.map(r => {
            const update = newData.find(u => u.id === r.properties.id);
            if (update) {
                r = {...r, properties: { ...r.properties, ...update }};
            }
            return r;
          });
        });
      }}
      filters={filters}
      tableBackground='bg-white p-4'
      headerButtons={headerButtons}
      />
      <DeleteModal isOpen={showDeleteModal} onConfirm={deleteFile} onDecline={() =>{setShowDeleteModal(false)}} itemToDelete={wantDeleteFile} buttonText="Êtes-vous sûr de vouloir supprimer cette voie ?"/>
      
  </>)
}