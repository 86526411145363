import { Button, Input, Select, SelectItem, Spacer, Tooltip } from "@heroui/react";
import { useEffect, useState } from "react";
import { Functions } from "../count/functions";
import { Icon } from "@iconify/react/dist/iconify.js";
import useAzureFuncApp from "../../../../hooks/azure/useAzureFuncApp";
import config from "../../../../config";
import { useSelector } from "react-redux";

export var container_name = "comptagev2";
export default function Sidemenu ({
  setIsLoading,
  setLoadingMessage,
  activeFunction,
  setActiveFunction,
  page,
  nbRef,
  coverRef,
  setNumberResultComputations,
  setCoverResultComputations,
  openNumberResult=() => undefined,
  openCoverResult=() => undefined
}) {
  const { launch_request } = useAzureFuncApp();
  const user = useSelector((state) => state.Comptage.ProjectInfos.creator_name);
  const currentFile = useSelector((state) => state.Comptage.Configuration.current_file);
  const projectName = useSelector((state) => state.Comptage.ProjectInfos.project_name);
  const symbols = useSelector((state) => state.Comptage.Configuration.symbols);
  const hits = useSelector((state) => state.Comptage.Decompte.symbols);

  const [maxDistance, setMaxDistance] = useState(15);
  const [surface, setSurface] = useState(200);
  const [selectedSymbols, setSelectedSymbols] = useState([]);
  
  const extinctorsNumber = async () => {
    setActiveFunction(Functions.UXELLOGS_NUMBER_EXTINCTORS);

    setLoadingMessage("Calcul des quantités d'extincteurs...");
    setIsLoading(true);
    let payload = {
      "function": "check_quantity",
      "pdf_name": currentFile,
      "username": user,
      "project_name": projectName,
      "container_name": container_name,
      "hits": hits,
      "current_page": page,
      "max_range": maxDistance,
      "max_area_per_extinct": surface,
      "selected_symbols": Array.from(selectedSymbols)
    }

    let [status_code, result] = await launch_request(config.apiUxellOGSUri, payload);
    setIsLoading(false);

    if (status_code === 200) {
      setNumberResultComputations(result.result_zones);
      openNumberResult();
    }

    setActiveFunction(Functions.NONE);
  };
  
  const cover = async () => {
    setActiveFunction(Functions.UXELLOGS_COVER);
    
    setLoadingMessage("Calcul des couvertures des extincteurs...");
    setIsLoading(true);
    let payload = {
      "function": "check_cover",
      "pdf_name": currentFile,
      "username": user,
      "project_name": projectName,
      "container_name": container_name,
      "hits": hits,
      "current_page": page,
      "max_range": maxDistance,
      "max_area_per_extinct": surface,
      "selected_symbols": Array.from(selectedSymbols)
    }

    let [status_code, result] = await launch_request(config.apiUxellOGSUri, payload);
    setIsLoading(false);
    
    if (status_code === 200) {
      setCoverResultComputations(result.result_zones);
      openCoverResult();
    }

    setActiveFunction(Functions.NONE);
  };

  return (
    <div>
      <Select
        classNames={{
          trigger: "",
          popoverContent: ""
        }}
        label="Symboles"
        placeholder="Selectionner un ou plusieurs symboles"
        selectionMode="multiple"
        className="max-w-xs"
        disallowEmptySelection={true}
        defaultSelectedKeys="all"
        onSelectionChange={setSelectedSymbols}
      >
        {symbols.map((s) => (
          <SelectItem key={s.crop_id}>
            {s.name}
          </SelectItem>
        ))}
      </Select>
      <Input
        type="number"
        min={.1}
        step={.1}
        label="Distance maximale"
        placeholder="15"
        defaultValue={maxDistance}
        onValueChange={setMaxDistance}
        endContent={
          <div className="pointer-events-none flex items-center">
            <span className="text-default-400 text-small">m</span>
          </div>
        } />
      <Input
        type="number"
        label="Surface par élément"
        placeholder="200"
        min={.1}
        step={.1}
        defaultValue={surface}
        onValueChange={setSurface}
        endContent={
          <div className="pointer-events-none flex items-center">
            <span className="text-default-400 text-small">m²</span>
          </div>
        } />

      <Spacer y={4} />
      
      <h2>Nombre d'extincteurs</h2>
      <div className="flex flex-row align-middle items-center gap-4">
        <Button className="shadow-lg" onPress={extinctorsNumber} color={activeFunction === Functions.UXELLOGS_NUMBER_EXTINCTORS ? "danger" : "default"}>
          <Icon icon="ph:fire-extinguisher" className='scale-150' />Calculer
        </Button>
        <Tooltip placement="top" content="Afficher le résultat" color="success">
          <Button isIconOnly ref={nbRef} onPress={openNumberResult} color="success" variant="flat" className="shadow-md" style={{display: "none"}}>
            <Icon icon="fe:document" className='scale-150 cursor-pointer' />
          </Button>
        </Tooltip>
      </div>
      
      <Spacer y={4} />
      
      <h2>Couverture</h2>
      <div className="flex flex-row align-middle items-center gap-4">
        <Button className="shadow-lg" onPress={cover} color={activeFunction === Functions.UXELLOGS_COVER ? "danger" : "default"}>
          <Icon icon="material-symbols:activity-zone-outline" className='scale-150' />Calculer
        </Button>
        <Tooltip placement="top" content="Afficher le résultat" color="success">
          <Button isIconOnly ref={coverRef} onPress={openCoverResult} color="success" variant="flat" className="shadow-md" style={{display: "none"}}>
            <Icon icon="fe:document" className='scale-150 cursor-pointer' />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
