import { Autocomplete, AutocompleteItem, AutocompleteSection, Chip, Divider } from "@heroui/react";
import { useMemo, useState } from "react";

import { unitMapping } from "./const";

export default function BPUAutocomplete({ 
    bpu,
    code_contrat,
    handleAutocompleteChange, 
    isDisabled 
}) {
    const [value, setValue] = useState("");
    const [selectedKey, setSelectedKey] = useState(null);

    const contratBpu = useMemo(() => {
        if(!code_contrat) return [];
        return bpu.filter(article => article.value.code_contrat === code_contrat);
    }, [bpu, code_contrat]);

    const companyBpu = useMemo(() => {
        return bpu.filter(article => article.value.code_contrat === "ENTREPRISE");
    }, [bpu]);

    function getAutocompleteProps(){
        return {
            inputProps: { classNames: { inputWrapper: "shadow-md" } },
            size: 'sm',
            variant: 'bordered',
            labelPlacement: 'outside',
            scrollShadowProps: {
                isEnabled: false,
            },
            classNames: {
                popoverContent: "max-h-[400px] overflow-y-auto",
            }
        };
    }

    function getAutocompleteSectionProps(isContrat=true){
        return {
            title: <Chip
                size="sm"
                variant="flat"
                color={isContrat ? "primary" : "success"}
                className="font-bold"
              
            >
                {isContrat ? "BPU Contrat" : "BPU Entreprise"}
            </Chip>
        };
    }

    if(!contratBpu && !companyBpu) return null;
    return (
        <Autocomplete
        {...getAutocompleteProps()}
        isDisabled={isDisabled}
        label='Autres'
        placeholder='Entrez ou sélectionnez un article'
        emptyContent='Aucun article trouvé'
        selectedKey={selectedKey}
        onSelectionChange={(key) => {
            console.log('Autocomplete onSelectionChange:', key);
            setSelectedKey(key);
            
            // Only call handleAutocompleteChange if a key is selected
            if (key) {
                handleAutocompleteChange(key);
            }
        }}>
            {/* BPU Contrat */}
            {contratBpu?.length &&
            <AutocompleteSection {...getAutocompleteSectionProps()}>
                {contratBpu.map(article =>
                    <AutocompleteItem 
                        key={article.id} 
                        value={article.id} 
                        textValue={article.label}
                    >
                        <AutocompleteArticleContent article={article.value} />
                    </AutocompleteItem>
                )}
            </AutocompleteSection>}
            
            {/* BPU Entreprise */}
            {companyBpu?.length &&
            <AutocompleteSection {...getAutocompleteSectionProps(false)}>
                {companyBpu.map(item =>
                    <AutocompleteItem 
                        key={item.id} 
                        value={item.id} 
                        textValue={item.label}
                    >
                        <AutocompleteArticleContent article={item.value} />
                    </AutocompleteItem>
                )}
            </AutocompleteSection>}

        </Autocomplete>
    )
}

const AutocompleteArticleContent = ({ article }) => {
    const roundedMontant = Math.round((article.montant + Number.EPSILON) * 100) / 100;
    return (
        <>
         <div className="flex justify-between items-center">
            {/* Left Section */}
            <span className="text-sm font-medium text-content-primary truncate capitalize" title={article.description}>
                {article.description}
            </span>
            {/* Right Section */}
            <div className="flex items-center gap-4">
                <div className="flex items-center gap-1">   
                    <span className="text-md font-medium">
                        {roundedMontant} {article.devise || "EUR"}
                    </span>
                    <span className="text-sm text-gray-600">
                        / {unitMapping[article.unite]}
                    </span>
                    </div>
            </div>
        </div>
        {
            article.description_longue && (
                <>
                {/* <Divider /> */}
                <p className="text-xs text-default-400">
                    {article.description_longue}
                </p></>
            )
        }
        </>
    )
}