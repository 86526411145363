import React from "react";
import {
  ScrollShadow,
  Listbox,
  ListboxItem,
  Input,
  Tabs,
  Tab,
  Button,
  Tooltip,
} from "@heroui/react";
import {Icon} from "@iconify/react";

import FileExplorerHeader from "./file-explorer-header";
import {cn} from "../../../../utils/cn";
import { DeleteIcon } from "../../../../components/Icons";

const FilesDisplayList = React.forwardRef(({
  page,
  buttonAddFileText="Ajouter un fichier",
  onAddFile,
  onDeleteFile,
  onFileSelected,
  fileList,
  ...props}, ref) => {
  const [selectedKeys, setSelectedKeys] = React.useState('');
  const [filterValue, setFilterValue] = React.useState("");
  const hasSearchFilter = Boolean(filterValue);

  const filteredItems = React.useMemo(() => {
    let filteredFileList = [...fileList];
    if (hasSearchFilter) {
      filteredFileList = filteredFileList.filter((folder) =>
        folder.name.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return filteredFileList;
  }, [fileList, filterValue]);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
    } else {
      setFilterValue("");
    }
  }, []);

  return (
    <div ref={ref} {...props}>
        <FileExplorerHeader className="hidden sm:flex" page={page} />
        <div className="mb-6 flex flex-col gap-4 px-3 sm:px-6">
          <div>
            <div className="mb-4 lg:mb-4">
              <Input
                isClearable
                aria-label="Search"
                labelPlacement="outside"
                placeholder="Rechercher..."
                radius="md"
                startContent={
                  <Icon
                    className="text-default-500 [&>g]:stroke-[2px]"
                    icon="solar:magnifer-linear"
                    width={18}
                  />
                }
                variant="bordered"
                value={filterValue}
              onClear={() => setFilterValue("")}
              onValueChange={onSearchChange}
              />
            </div>
            <Button
              className="min-w-[10%] flex flex-row w-full"
              onPress={onAddFile}
            >
                <Icon icon="ic:baseline-plus" />
                <span>{buttonAddFileText}</span>
            </Button>
          </div>
        </div>
        <ScrollShadow hideScrollBar  className="flex h-full max-h-[calc(100vh-196px)] flex-col gap-6 overflow-y-auto px-3">
          <Listbox
            classNames={{
              base: "p-0",
            }}
            items={filteredItems}
            variant="flat"
            emptyContent={"Aucun fichier disponible."}
            selectionMode="single"
            selectedKeys={selectedKeys}
            onSelectionChange={setSelectedKeys}
            hideSelectedIcon
          >
            {(item) => (
              <ListboxItem
                key={item.id}
                className={cn("mb-2 px-2 w-full", {
                  "bg-default-100": item.active,
                })}
                endContent={<div className="text-small text-default-400">{item.time}</div>}
                textValue={item.name}
                onPress={onFileSelected}
              >
                <div className="mx-2 min-w-0 flex-1 grid grid-cols-5 align-middle items-center ">
                  <div className="text-small font-semibold text-default-foreground col-span-3 overflow-hidden">
                    {item.name}
                  </div>
                  <div className="text-small text-default-500 text-center">
                    {item.type}
                  </div>
                  <Tooltip placement="top" content="Supprimer" closeDelay={0} showArrow>
                    <Button isIconOnly variant="bordered" className="border-none">
                      <DeleteIcon className="scale-150" onClick={() => {onDeleteFile(item)}} />
                    </Button>
                  </Tooltip>
                </div>
              </ListboxItem>
            )}
          </Listbox>
        </ScrollShadow>
    </div>
  );
});

FilesDisplayList.displayName = "FilesDisplayList";

export default FilesDisplayList;
