import React, { createContext, useCallback, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { setImagePreview } from '../../../store/components/PolygonEditor/ImageSlice';
import { useDispatch } from 'react-redux';
import { ShapeType } from '../hooks/draw';

// Create the canvas context with initial default values
const CanvasContext = createContext({
  canvasRef: null,
  getObjectById: () => undefined,
  getAllObjects: () => [],
  removeObjects: ([]) => {},
  removeSelectedObjects: () => {},
  makeObjectsInvisible: ([]) => {},
  makeObjectsVisible: ([]) => {},
  makeObjectsGroup: ([]) => {
    return '';
  },
  makeObjectsUngroup: ([]) => {
    return [];
  },
  rearrengeObjects: ([]) => {},
  lockObjects: () => {},
  unlockObjects: () => {},
  clearCanvas: (definitiveDelete=true) => {},
  selectedShapes: [],
  setSelectedShapes: () => {},
  getCanvasAtResolution: () => {},
  createDataUrl: () => {
    return Promise.resolve(null);
  },
});

// CanvasProvider component manages the Fabric.js canvas and provides context to its children
const CanvasProvider = ({ children }) => {
  const canvasRef = useRef(null); // Reference to the Fabric.js canvas
  const [selectedShapes, setSelectedShapes] = useState(null); // State to hold selected objects
  const dispatch = useDispatch();
  

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteFilterCallback, setDeleteFilterCallback] = useState(undefined);
  // State variables to manage drawing process
  const [isAskTooltip, setIsAskTooltip] = useState(false);
  const [isEditingTooltip, setIsEditingTooltip] = useState(false);
  const [tooltip, setTooltip] = useState(null);
  const [tooltipShape, setTooltipShape] = useState(null);
  const [currentTooltipObjectId, setCurrentTooltipObjectId] = useState(null);
  const [currentTooltipMessage, setCurrentTooltipMessage] = useState("");

  const [isDrawing, setIsDrawing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragPos, setDragPos] = useState({ x: 0, y: 0 });
  const [shape, setShape] = useState(null);
  const [polygonPoints, setPolygonPoints] = useState([]);
  const [cancelDraw, setCancelDraw] = useState(false);
  const [lines, setLines] = useState([]);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [originX, setOriginX] = useState(0);
  const [originY, setOriginY] = useState(0);
  const [angle, setAngle] = useState(0);
  const [shapeType, setShapeType] = useState(ShapeType.LINE);
  const [drawMultipleShapes, setDrawMultipleShapes] = useState(false);

  // State variables to manage prompting process
  const [isPrompting, setIsPrompting] = useState(false);
  const [maxPoints, setMaxPoints] = useState(Number.MAX_SAFE_INTEGER);
  const [cancelPrompt, setCancelPrompt] = useState(false);

  const [currentScale, setCurrentScale] = useState(1);
  const [displayScale, setDisplayScale] = useState(true);
  
  const [removedItems, setRemovedItems] = useState([]);

  // Function to get Fabric.js object by its ID
  const getObjectById = useCallback((id) => {
    return canvasRef.current?.getObjects().find((obj) => obj.id === id);
  }, []);

  const getAllObjects = useCallback(() => {
    return canvasRef.current?.getObjects() ?? [];
  }, []);

  // Function to remove objects from canvas by their IDs
  const removeObjects = useCallback((ids) => {
    ids.forEach((id) => {
      const object = getObjectById(id);
      if (object) {
        canvasRef.current?.remove(object);
      }
    });
  }, [getObjectById]);

  const createDataUrl = useCallback((
    imagePreview,
    imageWidth,
    imageHeight,
    imageType
  ) => {
    return new Promise((resolve, reject) => {
      let dataURL = null;
      const offScreenCanvas = new fabric.Canvas(null, {
        width: imageWidth,
        height: imageHeight
      });

      if (imagePreview) {
        // Set the original image as the background of the off-screen canvas
        fabric.Image.fromURL(
          imagePreview,
          (img) => {
            offScreenCanvas.setBackgroundImage(img, offScreenCanvas.renderAll.bind(offScreenCanvas), {
              top: 0,
              left: 0,
              originX: 'left',
              originY: 'top',
            }, { crossOrigin: 'anonymous' });

            const displayWidth = canvasRef.current?.width;
            const displayHeight = canvasRef.current?.height;
            const scaleX = imageWidth / (displayWidth ?? 1);
            const scaleY = imageHeight / (displayHeight ?? 1);

            // Copy all objects from the main canvas to the off-screen canvas
            canvasRef.current?.getObjects().forEach((obj) => {
              const clone = fabric.util.object.clone(obj);
              clone.set({
                scaleX: clone.scaleX * scaleX,
                scaleY: clone.scaleY * scaleY,
                left: clone.left * scaleX,
                top: clone.top * scaleY,
              });
              offScreenCanvas.add(clone);
            });

            // Render the off-screen canvas
            offScreenCanvas.renderAll();

            // Save the off-screen canvas as an image
            dataURL = offScreenCanvas.toDataURL({
              format: imageType,
              quality: 1.0,
            });

            resolve(dataURL); // Resolve the promise with the dataURL
          },
          {
            crossOrigin: 'Anonymous', // Add this if you are facing cross-origin issues
          }
        );
      }
    });
  }, []);

  // Function to remove currently selected objects from canvas
  const removeSelectedObjects = useCallback(() => {
    if (selectedShapes && canvasRef?.current) {
      selectedShapes.forEach((shape) => {
        canvasRef.current?.remove(shape);
      });
      setSelectedShapes(null);
      canvasRef.current.discardActiveObject();
      canvasRef.current.renderAll();
    }
  }, [selectedShapes]);

  // Function to rearrange objects based on layers configuration
  const rearrengeObjects = useCallback((layers) => {
    let layersList = Array.prototype.concat.apply([], [layers.filter(x => x.isBackground), layers.filter(x => !x.isForeground && !x.isBackground), layers.filter(x => x.isForeground)]);
    let objs = canvasRef.current.getObjects();
    for (let layer of layersList) {
      for (let object of objs.filter(x=>x.layer === layer.layerId)) {
        object.bringToFront();
      }
    }

    objs.filter(x => x.isForeground === true).forEach(o => {
      o.bringToFront();
    });

    canvasRef?.current?.renderAll();
  }, []);

  // Function to make objects with given IDs invisible
  const makeObjectsInvisible = useCallback((ids) => {
    ids.forEach((id) => {
      let object = getObjectById(id);
      if (object) {
        object.visible = false;
      }
    });
    canvasRef?.current?.renderAll();
  }, [getObjectById]);

  // Function to make objects with given IDs visible
  const makeObjectsVisible = useCallback((ids) => {
    ids.forEach((id) => {
      let object = getObjectById(id);
      if (object) {
        object.visible = true;
      }
    });
    canvasRef?.current?.renderAll();
  }, [getObjectById]);

  // Function to group objects with given IDs into a new group
  const makeObjectsGroup = useCallback((ids) => {
    let id = uuidv4();
    let objects = [];
    ids.forEach((id) => {
      let object = getObjectById(id);
      if (object) {
        objects.push(object);
      }
    });

    // Create a group with the objects
    const group = new fabric.Group(objects, { selectable: true, evented: true, id });

    // Remove individual objects from canvas and add the group
    objects.forEach((obj) => canvasRef?.current?.remove(obj));
    canvasRef?.current?.add(group);

    canvasRef?.current?.renderAll();
    return id;
  }, [getObjectById]);

  // Function to ungroup a group with given IDs into individual objects
  const makeObjectsUngroup = useCallback((ids) => {
    let objects = [];
    let resultIds = [];
    ids.forEach((id) => {
      let object = getObjectById(id);
      if (object) {
        objects.push(object);
      }
    });

    objects.forEach((object) => {
      if (object.type === 'group') {
        object = object.destroy();
        const groupObjects = object._objects.slice();

        // Remove the group from the canvas
        canvasRef.current?.remove(object);

        // Add individual objects back to the canvas with adjusted positions
        groupObjects.forEach((obj) => {
          canvasRef.current?.add(obj);
          resultIds.push(obj.id ?? '');
        });
      }
    });

    canvasRef?.current?.renderAll();
    return resultIds;
  }, [getObjectById]);

  // Function to lock (disable interaction) all objects on canvas
  const lockObjects = useCallback(() => {
    canvasRef?.current?.getObjects().forEach((obj) => {
      obj.selectable = false;
      obj.evented = false;
    });
    canvasRef?.current?.renderAll();
  }, []);

  // Function to unlock (enable interaction) all objects on canvas
  const unlockObjects = useCallback(() => {
    canvasRef?.current?.getObjects().forEach((obj) => {
      obj.selectable = !obj.fixed;
      obj.evented = true;
    });
    canvasRef?.current?.renderAll();
  }, []);

  // Function to clear all objects from the canvas
  const clearCanvas = useCallback((definitiveDelete=true) => {
    let color="#FFFFFF";
    if (canvasRef.current) {
      color = canvasRef.current.backgroundColor;
      canvasRef.current?.getObjects().forEach(x => {
        x.definitiveDelete = definitiveDelete;
      })
      canvasRef.current?.clear();
      canvasRef.current.backgroundColor = color;
    }
    dispatch(setImagePreview(''));
    canvasRef?.current?.renderAll();
  }, [dispatch]);

  // Function to resize canvas and objects based on new width and height
  const getCanvasAtResolution = useCallback((newWidth, newHeight) => {
    if (canvasRef?.current) {
      let canvas = canvasRef.current;
      canvas.setWidth(newWidth);
      canvas.setHeight(newHeight);
      canvas.renderAll();
      canvas.calcOffset();
    }
  }, []);

  // Provide the context value to the children components
  const contextValue = {
    canvasRef,
    isDeleting,
    setIsDeleting,
    deleteFilterCallback,
    setDeleteFilterCallback,
    getObjectById,
    getAllObjects,
    removeObjects,
    makeObjectsInvisible,
    makeObjectsVisible,
    rearrengeObjects,
    lockObjects,
    unlockObjects,
    makeObjectsGroup,
    makeObjectsUngroup,
    clearCanvas,
    selectedShapes,
    setSelectedShapes,
    removeSelectedObjects,
    getCanvasAtResolution,
    createDataUrl,
    isDrawing,
    setIsDrawing,
    isAskTooltip,
    setIsAskTooltip,
    isEditingTooltip,
    setIsEditingTooltip,
    tooltip,
    setTooltip,
    angle,
    setAngle,
    tooltipShape,
    setTooltipShape,
    polygonPoints,
    setPolygonPoints,
    lines,
    setLines,
    currentTooltipObjectId,
    shapeType,
    setShapeType,
    drawMultipleShapes,
    setDrawMultipleShapes,
    cancelDraw,
    setCancelDraw,
    setOriginX,
    setOriginY,
    shape,
    setShape,
    isDragging,
    setIsDragging,
    setDragPos,
    setStartPos,
    originX,
    originY,
    startPos,
    setCurrentTooltipObjectId,
    currentTooltipMessage, setCurrentTooltipMessage,
    dragPos,
    isPrompting,
    setIsPrompting,
    maxPoints,
    setMaxPoints,
    cancelPrompt,
    setCancelPrompt,
    currentScale,
    setCurrentScale,
    displayScale, setDisplayScale,
    removedItems, setRemovedItems
  };

  // Render the context provider with the context value and children components
  return <CanvasContext.Provider value={contextValue}>{children}</CanvasContext.Provider>;
};

// Export both the context and provider for use in other components
export { CanvasContext, CanvasProvider };