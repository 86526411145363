import { Button, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, PaginationItemType, cn } from "@heroui/react";
import EditableTable from "../../../../components/table/editable-table";
import { useCallback, useMemo, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ChevronIcon } from "../../../../components/Icons";

const CoverModal = ({
    resultComputations,
    ...props
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const renderItem = useCallback(({
        ref,
        key,
        value,
        isActive,
        onNext,
        onPrevious,
        setPage,
        className,
      }) => {
        if (value === PaginationItemType.NEXT) {
          return (
            <button key={key} className={cn(className, "bg-transparent min-w-8 w-8 h-8 shadow-none active:bg-transparent")} onClick={onNext}>
              <ChevronIcon className="rotate-180" />
            </button>
          );
        }
    
        if (value === PaginationItemType.PREV) {
          return (
            <button key={key} className={cn(className, "bg-transparent min-w-8 w-8 h-8 shadow-none active:bg-transparent")} onClick={onPrevious}>
              <ChevronIcon />
            </button>
          );
        }
    
        if (value === PaginationItemType.DOTS) {
          return <button key={key} className={cn(className, "rounded-full bg-transparent")}>...</button>;
        }
    
        // cursor is the default item
        return (
          <button
            key={key}
            ref={ref}
            className={cn(
              className,
              isActive &&
              "bg-danger",
              "rounded-full"
            )}
            onClick={() => setPage(value)}
          >
          </button>
        );
      }, []);

    return <Modal 
        {...props}
    >
        <ModalContent>
        {(onClose) => (
            <>
            <ModalHeader className="flex flex-col gap-1">Couverture des zones</ModalHeader>
            <ModalBody>
                {resultComputations.map((x, idx) => {
                  return (<>
                    {(currentPage - 1) === idx && <div className="flex flex-col w-full items-center">
                      <Image src={x.image_path} />
                      <p>Distance maximale entre deux éléments : {x.max_range.toFixed(2)} m</p>
                      <p>Surface maximale par élément : {x.max_area_per_extinct.toFixed(2)} m²</p>
                      <p>Pourcentage de couverture : {x.score_cover.toFixed(2)} %</p>
                      <p>Surface restante : {x.missing_cover_area.toFixed(2)} m²</p>
                      <p>Nombre d'éléments dessinés / Nombre d'éléments requis : {x.nb_items}/{x.nb_min_items}</p>
                    </div>}
                  </>);
                })}
                <Pagination
                    loop
                    disableCursorAnimation
                    showControls
                    renderItem={renderItem}
                    dotsJump={5}
                    siblings={5}
                    boundaries={5}
                    total={resultComputations ? resultComputations.length : 0}
                    initialPage={1}
                    onChange={setCurrentPage}
                    className="mx-auto"
                />

            </ModalBody>
            <ModalFooter>
                <Button color="danger" onPress={onClose}>Quitter</Button>
            </ModalFooter>
            </>
        )}
        </ModalContent>
    </Modal>
};

export default CoverModal;
