import { Button,  Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,Image} from "@heroui/react";
import { Icon } from "@iconify/react";
import FileLoader from "../../../../components/file/FileLoader";
import { useSelector } from "react-redux";

const containerName = "pym";
const service = "Diane";
export default function AddFileModal({ isOpen,onConfirm,onDecline,nameModal="",blobPath, addFilesInList }) {
    const pdfFiles = useSelector((state) => state.file.files);

    return (
        <Modal 
        isOpen = {isOpen}
        onClose={onDecline}
        backdrop='blur'
        size="2xl"
        placement = "center"> 
        <ModalContent>
            <ModalHeader className="flex items-center gap-1">
                {/* <Icon icon="solar:magnifer-zoom-in-broken"  style={{ color: '#8ecae6' }} /> */}
                {nameModal}
            </ModalHeader>
            <ModalBody>
                <FileLoader
                    fileType=".ies, .ldt"
                    multiple={true}
                    title="Fichiers de luminaires"
                    containerName={containerName}
                    service={service}
                    blobNamePrefix={blobPath}
                    className="mx-auto"
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onPress={() => {
                    addFilesInList(pdfFiles);
                    onDecline();
                }}>
                    Valider
                </Button>
            </ModalFooter>
    </ModalContent>
    </Modal>
    )
}