import { Button, Card, CardBody, CardFooter, CardHeader, Checkbox, CheckboxGroup, Image, Radio, RadioGroup, ScrollShadow, VisuallyHidden, tv, useCheckbox } from "@heroui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import CheckableImage from "../inputs/checkable-image";

const PageSelector = ({pdfName, pagePreviews, selectedPages, setSelectedPages, ratioWidthPage = 1, maxHeight=undefined, onSelectionChanged=(pages)=>{}}) => {
  ratioWidthPage = Math.max(Math.min(ratioWidthPage, 1), 0);
  // const [allPagesSelected, setAllPagesSelected] = useState(false);
  const allPagesSelected = useMemo(() => {
    return pagePreviews.length === selectedPages.length;
  }, [selectedPages, pagePreviews]);

  // const [somePagesSelected, setSomePagesSelected] = useState(false);
  const somePagesSelected = useMemo(() => {
    return pagePreviews.length !== selectedPages.length && selectedPages.length !== 0;
  }, [selectedPages, pagePreviews]);

    const handleCheckboxClick = (isSelected, index) => {
      if (isSelected) {
        setSelectedPages(p => {
          let newP = [...p, index];
          return newP;
        });
        onSelectionChanged([...selectedPages, index]);
      } else{
        setSelectedPages(p => {
          let newP = [...p].filter(x => x !== index);
          return newP;
        });
        onSelectionChanged([...selectedPages].filter(x => x !== index));
      }
    };

    const selectUnselect = (value) => {
      if (value) {
        setSelectedPages(sp => pagePreviews.map((_, index) => index));
        onSelectionChanged(pagePreviews.map((_, index) => index));
      } else {
        setSelectedPages(sp => []);
        onSelectionChanged([]);
      }
    };

    const getPagesDiv = useCallback(() => {
      const small_grid_class = `grid-cols-${Math.max(Math.min(pagePreviews.length, Math.floor(3*ratioWidthPage)), 1)}`;
      const medium_grid_class = `md:grid-cols-${Math.max(Math.min(pagePreviews.length, Math.floor(5*ratioWidthPage)), 1)}`;
      const large_grid_class = `lg:grid-cols-${Math.max(Math.min(pagePreviews.length, Math.floor(7*ratioWidthPage)), 1)}`;

      return <div className={`grid ${small_grid_class} ${medium_grid_class} ${large_grid_class} gap-2`}>
            {pagePreviews.map((imageSrc, index) => (
              <div key={index} className="page-preview min-w-[100px] max-w-[15vw] p-4">
                <CheckableImage
                  key={index}
                  src={imageSrc}
                  alt={`Page ${index + 1}`}
                  text={index + 1}
                  isSelected={selectedPages.includes(index)}
                  onValueChange={(isSelected) => { handleCheckboxClick(isSelected, index) }}
                  className="h-[21vh] md:h-[23vh] lg:h-[25vh]"
                />
              </div>
            ))}
          </div>;
    }, [pagePreviews.length, pagePreviews, selectedPages]);
    

    return (
      <div className={`flex flex-col px-4 h-full w-full`}>
        <h3 className="text-center text-xl h3">{pdfName}</h3>
        <div className="mt-4 mx-auto flex flex-row justify-center gap-x-[10px] min-w-[50%] max-w-[400px]">
          <Checkbox color="danger" size="sm" defaultSelected={allPagesSelected} isSelected={allPagesSelected} onValueChange={(v) => selectUnselect(v)}>
            {allPagesSelected ? "Désélectionner tout" : "Sélectionner tout"}
          </Checkbox>
        </div>
        <ScrollShadow hideScrollBar className={`py-2 pr-6 ${maxHeight === undefined ? "" : `max-h-[${maxHeight}]`} w-full flex flex-row justify-around`}>
          {getPagesDiv()}
        </ScrollShadow>
        <div className="text-center h4 subtitle">{selectedPages.length} {selectedPages.length <= 1 ? ' page sélectionnée' : 'pages sélectionnées'}</div>
      </div>
    );
  };

  

export default PageSelector;
