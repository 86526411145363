"use client";

import React, { useEffect } from "react";
import {RadioGroup} from "@heroui/react";
import {cn} from "@heroui/react";

import FeedbackRatingItem, {RatingValueEnum} from "./feedback-rating-item";

export default function Component({classNames, setRatingValue, ...props}) {
  const [value, setValue] = React.useState(RatingValueEnum.GREAT);

  useEffect(() => {
    if (setRatingValue) {
      setRatingValue(value);
    }
  }, [value, setRatingValue])

  return (
    <RadioGroup
      value={value}
      {...props}
      classNames={{
        ...classNames,
        base: cn(classNames?.base, "max-w-fit"),
        wrapper: cn(classNames?.wrapper, "gap-3"),
      }}
      defaultValue="1"
      orientation="horizontal"
      size="lg"
      onValueChange={setValue}
    >
      <FeedbackRatingItem value={RatingValueEnum.BAD} />
      <FeedbackRatingItem value={RatingValueEnum.NEUTRAL} />
      <FeedbackRatingItem value={RatingValueEnum.GOOD} />
      <FeedbackRatingItem value={RatingValueEnum.GREAT} />
    </RadioGroup>
  );
}
