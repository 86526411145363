import React, { Suspense } from "react";
import { Spinner } from "@heroui/react";

import ProtectedRoute from "../components/login/protected-route";
import Files from "../pages/services/Comptage/files";
import Pages from "../pages/services/Comptage/pages";
import Nomenclature from "../pages/services/Comptage/nomenclature";
import Verification from "../pages/services/Comptage/verification";
import Linears from "../pages/services/Comptage/linears";
import Count from "../pages/services/Comptage/count";
import UxellOGS from "../pages/services/Comptage/uxellogs";
import Symbols from "../pages/services/Comptage/symbols";
import Zones from "../pages/services/Comptage/zones";
import Tutorials from "../pages/services/Comptage/tutos";

const ProjectsList = React.lazy(() => import("../pages/services/Comptage/index"));
const Comptage = React.lazy(() => import("../pages/services/Comptage/comptage"));

const router = {
  path: "/",
  element: "",
  children: [
    {
      path: "/comptage",
      element: (
        <ProtectedRoute roles={["baseauth", "Plateforme.Service1", "Plateforme.FullAccess"]}>
          <ProjectsList />
        </ProtectedRoute>
      )
    },
    {
      path: "/comptage/:id",
      element: (
        <ProtectedRoute roles={["baseauth", "Plateforme.Service1", "Plateforme.FullAccess"]}>
          <Comptage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "fichiers",
          element: <Files />
        },
        {
          path: "pages",
          element: <Pages />
        },
        {
          path: "zones",
          element: <Zones />
        },
        {
          path: "symboles",
          element: <Symbols />
        },
        {
          path: "denombrement",
          element: <Count />
        },
        {
          path: "uxellogs",
          element: <UxellOGS />
        },
        {
          path: "metre",
          element: <Linears />
        },
        {
          path: "nomenclature",
          element: <Nomenclature />
        },
        {
          path: "verification",
          element: <Verification />
        },
        {
          path: "tutos",
          element: <Tutorials />
        }
      ]
    }
  ],
};

export default router;
