import { Button,  Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from "@heroui/react";
import { Icon } from "@iconify/react";

export default function OptimisiationChoiceModal({ isOpen,onConfirm,onDecline }) {

    return (
        <Modal 
        isOpen = {isOpen}
        backdrop='blur'
        size="s"
        placement = "center"> 
        <ModalContent>
        <ModalHeader className="flex items-center gap-1">
            <Icon icon="solar:calculator-line-duotone"  style={{ color: '#006FE' }} />
            Optimisation
        </ModalHeader>
        <ModalBody>
            <p className="text-center"> 
                Souhaitez vous egalement optimiser l'interdistance entre luminaires ? 
            </p>
        </ModalBody>
        <ModalFooter>
            <Button color="danger" variant="light" onClick={onDecline}>
                Non
            </Button>
            <Button color="primary" onClick={onConfirm} >
                Oui
            </Button>
        </ModalFooter>
    </ModalContent>
    </Modal>
    )
}