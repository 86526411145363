import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { addObject, addObjectInLayer, removeObject, setRequestUngroupActiveGroup } from '../../../store/components/PolygonEditor/ImageSlice';
import { CanvasContext } from '../contexts/canvas-context';
import {
  selectFontSize,
  selectTextColor,
  selectFillColor,
  selectOpacity,
  selectStrokewidth,
  selectStrokeColor,
  selectPointSize,
  setStrokeColor,
  setStrokeWidth,
  setFillColor,
  setOpacity,
  setFontSize,
  setTextColor,
  selectStyleChanged,
  setPointSize,
  selectImageUrl,
  selectSVGUrl,
} from '../../../store/components/PolygonEditor/CanvasSlice';
import { CanvasEventContext } from '../contexts/canvas-event-context';

export const ShapeType = {
  ELLIPSE: 'Ellipse',
  LINE: 'Line',
  RECT: 'Rectangle',
  TEXT: 'Text',
  TRIANGLE: 'Triangle',
  POLYGON: 'Polygon',
  CIRCLE: 'Circle',
  SQUARE: 'Square',
  POLYLINE: 'Polyline',
  POINT: 'Point',
  SQUAREPOINT: 'Square Point',
  SVG: 'SVG',
  IMAGE: 'Image',
}
const deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
var deleteImg = undefined;

export const useDrawShapeUtils = () => {
  const {
    canvasRef, removeObjects, setRemovedItems
  } = useContext(CanvasContext);
  function deleteObject(_eventData, transform) {
    setRemovedItems(r => [...r, transform.target]);
    removeObjects([transform.target.id]);
  }

  function renderIcon(icon) {
    return function (ctx, left, top, _styleOverride, fabricObject) {
      const size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
  }

  function addDeleteControl(shape) {
    shape.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -16,
      offsetX: 16,
      cursorStyle: 'pointer',
      mouseUpHandler: deleteObject,
      render: renderIcon(deleteImg),
      cornerSize: 24,
    });

    canvasRef?.current?.requestRenderAll();
  }

  return { addDeleteControl };
}

/**
 * Custom hook for drawing various shapes on a canvas.
 *
 * @param shapeType - Type of shape to draw (from ShapeType enum).
 * @returns Object with methods and state related to drawing shapes.
 */
const useDrawShape = () => {
  const {
    canvasRef,
    cancelDraw, setCancelDraw,
    isDrawing, setIsDrawing,
    isDragging, setIsDragging,
    polygonPoints, setPolygonPoints, 
    originX, setOriginX,
    originY, setOriginY,
    dragPos, setDragPos,
    startPos, setStartPos,
    setCurrentTooltipObjectId,
    currentTooltipMessage, setCurrentTooltipMessage,
    isEditingTooltip, setIsEditingTooltip,
    isAskTooltip, setIsAskTooltip,
    selectedShapes, setSelectedShapes,
   
    currentScale,
    displayScale,
    
    shapeType,
    drawMultipleShapes,
    shape, setShape,
    lines, setLines,
    angle, setAngle,
    isDeleting,
    deleteFilterCallback, setDeleteFilterCallback,
    tooltip, setTooltip,
    tooltipShape, setTooltipShape,
    currentTooltipObjectId,
    getObjectById,
    removeObjects, removeSelectedObjects,
    lockObjects, unlockObjects,
    rearrengeObjects, getAllObjects,
    maxPoints,
    removedItems, setRemovedItems
  } = useContext(CanvasContext);
  const layers = useSelector((state) => state.image.layers);
  const backgroundLayer = useSelector((state) => state.image.layers.find(x=>x.name === "background"));
  const activeLayerId = useSelector((state) => state.image.activeLayer);
  const activeLayer = useSelector((state) => state.image.layers.find(x=>x.id === activeLayerId));

  const scaleLayerId = useSelector((state) => {
    let scale = state.image.layers.find(x=>x.name === "scale_foreground_vpf");
    if (scale) {
      return scale.layerId;
    }
    else {
      return -1;
    }
  });
  
  const { onShapeAdded, onShapeRemoved, onShapeSelected, onShapeDeselected, onShapeClicked, onShapeModified } = useContext(CanvasEventContext);
  const dispatch = useDispatch();

  // Selectors to get current style settings from Redux store
  const strokeColor = useSelector(selectStrokeColor);
  const strokeWidth = useSelector(selectStrokewidth);
  const fillColor = useSelector(selectFillColor);
  const opacity = useSelector(selectOpacity);
  const fontSize = useSelector(selectFontSize);
  const textColor = useSelector(selectTextColor);
  const pointSize = useSelector(selectPointSize);
  const styleChanged = useSelector(selectStyleChanged);
  const imageUrl = useSelector(selectImageUrl);
  const svgUrl = useSelector(selectSVGUrl);

  // Function to initiate drawing mode
  const startDrawing = useCallback(() => {
    setIsAskTooltip(false);
    setIsEditingTooltip(false);
    setPolygonPoints([]);
    setShape(null);
    dispatch(setRequestUngroupActiveGroup(true)); // Request ungrouping active group if any
    setIsDrawing(true); // Set drawing mode to true
  }, [dispatch, setIsAskTooltip, setIsDrawing, setIsEditingTooltip, setPolygonPoints, setShape]);

  useEffect(() => {
    if (isDrawing) {
      if (canvasRef?.current) {
        canvasRef.current.defaultCursor = 'crosshair'; // Change cursor to crosshair
        canvasRef.current.hoverCursor = 'crosshair'
        lockObjects(); // Lock objects on canvas
      }
    } else {
      if (canvasRef?.current) {
        canvasRef.current.defaultCursor = 'default'; // Change cursor to crosshair
        canvasRef.current.hoverCursor = 'default'
        unlockObjects(); // Unlock objects on canvas
      }
    }
  }, [isDrawing, canvasRef, lockObjects, unlockObjects]);

  useEffect(() => {
    if (isDeleting) {
      if (canvasRef?.current) {
        canvasRef?.current.renderAll();
        unlockObjects(); // Unlock objects on canvas
      }
    } 
  }, [isDeleting, canvasRef, unlockObjects]);

  const startEditingTooltip = useCallback(() => {
    setIsAskTooltip(true);
  }, [setIsAskTooltip]);

  const handleTextChanged = useCallback((event) => {
    if (event.target?.type === "tooltip") {
      if (tooltip !== null && tooltip !== undefined) {
        let pos = tooltipShape.top;
        let height = tooltipShape.height;
        tooltipShape._objects[0].set("height", tooltip.height + 20);
        tooltipShape.set("height", tooltip.height + 40);
        tooltip.top = pos - tooltip.height/2 - 20 - 10 ;
        tooltipShape.top = pos;
        tooltipShape._objects[0].top = tooltipShape._objects[0].height/2 - 10;
        tooltipShape._objects[1].top = tooltipShape._objects[0].height/2 - 10;
        tooltipShape._objects[2].top = tooltipShape._objects[0].height/2 - 10 - 2* 2^.5;
        getObjectById(currentTooltipObjectId).tooltipMessage = event.target.text;
        canvasRef.current.renderAll(); // Render canvas with updated styles
        setCurrentTooltipMessage(event.target.text);
      }
    }
  }, [canvasRef, currentTooltipObjectId, getObjectById, setCurrentTooltipMessage, tooltip, tooltipShape]);

  const handleObjectAdded = useCallback((event) => {
    rearrengeObjects(layers);
  }, [layers, rearrengeObjects]);

  const handleObjectRemoved = useCallback((event) => {
    setCurrentTooltipObjectId(undefined);
    dispatch(removeObject(event.target.id));
    if (event.target.definitiveDelete) {
      onShapeRemoved?.call(this, event.target);
    }
  }, [dispatch, onShapeRemoved, setCurrentTooltipObjectId]);

  const handleObjectModified = useCallback((event) => {
    onShapeModified?.call(this, event.target);
  }, [onShapeModified]);

  useEffect(()=>{
    updateDisplayCalibration();
  }, [displayScale, currentScale, scaleLayerId]);


  const updateDisplayCalibration = useCallback(() => {
    removeObjects(getAllObjects().filter(x=>!x.isBackground && x.layer === scaleLayerId && x.calibration_line !== true).map(x=>x.id));
    if (scaleLayerId === -1 || currentScale === undefined) {return;}
    const zoom = canvasRef.current.getZoom();
    let arrow_width = 7;
    let scale_width = 150;
    let scale_height = 20;
    let font_size = 16;
      
    let tmp = scale_width * currentScale / zoom;
    let pow = Math.floor(Math.log10(tmp));
    let value = Math.floor(tmp / (10 ** pow));
    scale_width = value * (10 ** pow) * canvasRef.current.getZoom() / currentScale;
    let text = "1 m";

    switch (pow) {
        case 0:
            text = `${value} m`;
            break;
        case 3:
            text = `${value} km`;
            break;
        case -1:
            text = `0,${value} m`;
            break;
        case -2:
            text = `${value} cm`;
            break;
        case -3:
            text = `${value} mm`;
            break;
        case -4:
            text = `0,${value} mm`;
            break;
        default:
            if (pow < 0) {
                text = `${value}.10e${pow} m`;
            } else if (pow < 3) {
                text = `${value * (10**pow)} m`;
            } else {
                text = `${value * (10**(pow-3))} km`;
            }
            break;
    }

    let arrow_line = new fabric.Line([0, 0, scale_width, 0], {
        fill: 'black',
        stroke: 'black',
        strokeWidth: 1,
        originX: 'center',
        originY: 'center',
        id: uuidv4(),
    });
    arrow_line.layer = scaleLayerId;
    let left_seg_line = new fabric.Line([arrow_line.x1, arrow_line.y1 - scale_height / 2, arrow_line.x1, arrow_line.y1 + scale_height / 2], {
        stroke: 'black',
        strokeWidth: 1,
        id: uuidv4()
    });
    left_seg_line.layer = scaleLayerId;
    let right_seg_line = new fabric.Line([arrow_line.x2, arrow_line.y1 - scale_height / 2, arrow_line.x2, arrow_line.y1 + scale_height / 2], {
        stroke: 'black',
        strokeWidth: 1,
        id: uuidv4()
    });
    right_seg_line.layer = scaleLayerId;
    let triangleLeft = new fabric.Triangle({
        left: arrow_line.x1,
        top: arrow_line.y1,
        originX: 'center',
        width: arrow_width,
        height: arrow_width,
        angle: 270,
        stroke: 'black',
        strokeWidth: 1,
        fill: 'black',
        id: uuidv4()
    });
    triangleLeft.layer = scaleLayerId;
    let triangleRight = new fabric.Triangle({
        left: arrow_line.x2,
        top: arrow_line.y2,
        originX: 'center',
        width: arrow_width,
        height: arrow_width,
        angle: 90,
        stroke: 'black',
        strokeWidth: 1,
        fill: 'black',
        id: uuidv4()
    });
    triangleRight.layer = scaleLayerId;
    let text_echelle = new fabric.Text(text, {
        originX: 'center',
        originY: 'center',
        fontWeight: 'bold',
        fontSize: font_size,
        textAlign: 'center',
        centeredRotation: false,
        id: uuidv4()
    });
    text_echelle.top = -font_size / 2;

    let viewportBoundaries = canvasRef.current.calcViewportBoundaries();
    let group = new fabric.Group([arrow_line, left_seg_line, right_seg_line, triangleLeft, triangleRight], {
        originX: 'left',
        originY: 'bottom',
        hasControls: false,
        hasBorders: false,
        lockRotation: true,
        lockMovementX: true,
        lockMovementY: true,
        id: uuidv4()
    });
    let zoomFactor = 1;
    group.layer = scaleLayerId;
    group.annotation = true;
    group.relativeToViewport = true;
    group.offsetX = 0;
    group.offsetY = 0;
    group.left = group.offsetX + viewportBoundaries.bl['x'];
    group.top = group.offsetY + viewportBoundaries.bl['y'];
    group.scaleX = zoomFactor / zoom;
    group.scaleY = zoomFactor / zoom;
    group.zoomFactor = 1;
    group.add(text_echelle);
    canvasRef.current.add(group);
    canvasRef.current.requestRenderAll();
  }, [canvasRef, currentScale, getAllObjects, removeObjects, scaleLayerId]);
  
  const changeZoom = useCallback((delta, offsetX, offsetY) => {
    if (canvasRef?.current) {
      var zoom = canvasRef.current.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvasRef.current.zoomToPoint({
        x: offsetX,
        y: offsetY
      }, zoom);
      canvasRef.current.renderAll();
    }

    updateDisplayCalibration();
  }, [canvasRef, updateDisplayCalibration])

  const getOverallBoundingBox = useCallback((objects) => {
    if (objects.length === 0) return null; // No objects, no bounding box
  
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
  
    objects.forEach((obj) => {
      let width = (angle + 360) % 180 === 0 ? obj.width : obj.height;
      let height = (angle + 360) % 180 === 0 ? obj.height : obj.width;
      const left = obj.getCenterPoint().x - width/2;
      const top = obj.getCenterPoint().y - height/2;

      // Update the min/max coordinates
      minX = Math.min(minX, left);
      minY = Math.min(minY, top);
      maxX = Math.max(maxX, left + width);
      maxY = Math.max(maxY, top + height);
    });
  
    // Return the overall bounding box
    return {
      left: minX + (maxX - minX)/2,
      top: minY + (maxY - minY)/2,
      width: maxX - minX,
      height: maxY - minY,
    };
  }, [angle]);

  const zoomObjects = useCallback((objectIds, zoom_factor = 1.05) => {
    if (canvasRef?.current) {
      let objects = objectIds.map(i => getObjectById(i)).filter(x=>x !== undefined);

      if (objects.length === 0) {
        return;
      }

      canvasRef?.current.setZoom(1);
      
      const bbox = getOverallBoundingBox(objects);
      let zoom = Math.min(canvasRef?.current.width / (bbox.width * zoom_factor), canvasRef?.current.height / (bbox.height * zoom_factor));
      let vpw = canvasRef?.current.width / zoom;
      let vph = canvasRef?.current.height / zoom;
      let x = (bbox.left - vpw/2);
      let y = (bbox.top - vph/2);
      canvasRef?.current.absolutePan({
          x: x,
          y: y
      });
      canvasRef?.current.setZoom(zoom);
      canvasRef.current.requestRenderAll();
    }
  }, [canvasRef, getObjectById, getOverallBoundingBox]);
  
  const zoomBackground = useCallback((zoom_factor = 1.05) => {
    if (canvasRef?.current) {
      let obj = canvasRef?.current.backgroundImage;
      if (obj) {
        canvasRef?.current.setZoom(1);
        let zoom = Math.min(canvasRef?.current.width / (obj.width * zoom_factor), canvasRef?.current.height / (obj.height * zoom_factor));
        let vpw = canvasRef?.current.width / zoom;
        let vph = canvasRef?.current.height / zoom;
        let angle = obj.get("angle");
        let objx = (obj.left + obj.width/2) * Math.cos(angle * Math.PI / 180) - (obj.top + obj.height/2) * Math.sin(angle * Math.PI / 180);
        let objy = (obj.left + obj.width/2) * Math.sin(angle * Math.PI / 180) + (obj.top + obj.height/2) * Math.cos(angle * Math.PI / 180);
        
        let x = (objx - vpw / 2);
        let y = (objy - vph / 2);
        canvasRef?.current.absolutePan({
            x: x,
            y: y
        });
        canvasRef?.current.setZoom(zoom);
        canvasRef.current.renderAll();
      }
      else{
        let backgroundObjs = getAllObjects().filter(x=>x.isBackground);
        if (backgroundObjs && backgroundObjs.length > 0) {
          zoomObjects(backgroundObjs.map(x => x.id));
        } else {
          return;
        }
      }
    }

    updateDisplayCalibration();
  }, [canvasRef, getAllObjects, updateDisplayCalibration, zoomObjects]);

  const centerViewportOnPoint = useCallback((new_x, new_y) => {
    if (canvasRef?.current) {
      let zoom = canvasRef?.current.getZoom();
      canvasRef?.current.setZoom(1);
      let vpw = canvasRef?.current.width / zoom;
      let vph = canvasRef?.current.height / zoom;
      let x = new_x - vpw/2;
      let y = new_y - vph/2;
      canvasRef?.current.absolutePan({
        x: x,
        y: y
      });
      canvasRef?.current.setZoom(zoom);
    }
  }, [canvasRef]);

  const rotateObject = useCallback((obj, rotate_angle, originX, originY) => {
    var angleval = obj.get('angle');
    var val = angleval + rotate_angle;
    obj.set('angle', val);

    var posval = {
        top: obj.get('top'),
        left: obj.get('left')
    };

    var tl = {
        x: posval.left,
        y: posval.top
    };
    let tlX = (tl.x - originX) * Math.cos(rotate_angle * Math.PI / 180) - (tl.y - originY) * Math.sin(rotate_angle * Math.PI / 180) + originX;
    let tlY = (tl.x - originX) * Math.sin(rotate_angle * Math.PI / 180) + (tl.y - originY) * Math.cos(rotate_angle * Math.PI / 180) + originY;

    var newleft = tlX;
    var newtop = tlY;

    obj.set('top', newtop);
    obj.set('left', newleft);
    obj.setCoords();
    return obj;
  }, []);

  const rotate = useCallback((newAngle) => {
    setCancelDraw(true);
    
    // rotate
    if (canvasRef?.current) {
      setAngle((angle + newAngle + 360) % 360);
      var [originX, originY] = [0, 0];

      let center = new fabric.Point((canvasRef?.current.calcViewportBoundaries().tl.x + canvasRef?.current.calcViewportBoundaries().br.x)/2, (canvasRef?.current.calcViewportBoundaries().tl.y+canvasRef?.current.calcViewportBoundaries().br.y)/2);
      let newCenterX = (center.x - originX) * Math.cos(newAngle * Math.PI / 180) - (center.y - originY) * Math.sin(newAngle * Math.PI / 180) + originX;
      let newCenterY = (center.x - originX) * Math.sin(newAngle * Math.PI / 180) + (center.y - originY) * Math.cos(newAngle * Math.PI / 180) + originY;
      centerViewportOnPoint(newCenterX, newCenterY);

      let objs = canvasRef?.current.getObjects();
      objs.forEach((obj) => {
          obj = rotateObject(obj, newAngle, originX, originY);
        });
      canvasRef?.current.requestRenderAll();
    }
  }, [angle, canvasRef, centerViewportOnPoint, rotateObject, setAngle, setCancelDraw]);

  const rotateLeft = useCallback(() => { rotate(-90); }, [rotate]);
  const rotateRight = useCallback(() => { rotate(90); }, [rotate]);

  const insertImage = useCallback((url, x, y, {
    originX,
    originY,
    scale=undefined,
    id=undefined,
    layer=undefined,
    ...props
  }) => {
    if (!id) {
      id = uuidv4();
    }

    fabric.Image.fromURL(
      url,
      function (newImg) {
        newImg.set("left", x);
        newImg.set("top", y);
        newImg.set("originX", originX);
        newImg.set("originY", originY);
        newImg.set("id", id);
        newImg.set("objectCaching", false);
        newImg.scaleX = scale !== undefined ? scale : 2 * pointSize / ((newImg.width + newImg.height) / 2);
        newImg.scaleY = scale !== undefined ? scale : 2 * pointSize / ((newImg.width + newImg.height) / 2);
        newImg.type = "image";
        newImg.layer = layer;
        
        setOriginX(x);
        setOriginY(y);
        for (const property in props) {
          newImg.set(property, props[property]);
        }
        
        canvasRef?.current.add(newImg);
        if (newImg.isBackground) {
          newImg.sendToBack();
        }

        onShapeAdded?.call(this, newImg);
        if (layer !== undefined) {
          dispatch(addObjectInLayer({layerId: layer, object: newImg.id})); // Add the object's ID to Redux store
        } else {
          dispatch(addObject(newImg.id)); // Add the object's ID to Redux store
        }
      }, { crossOrigin: 'anonymous' });
  }, [canvasRef, dispatch, onShapeAdded, pointSize, setOriginX, setOriginY]);

  const insertPolygon = useCallback((points, {
    id=undefined,
    layer=undefined,
    ...props
  }) => {
    if (!id) {
      id = uuidv4();
    }

    const polygon = new fabric.Polygon(points);
    polygon.set("id", id);
    polygon.layer = layer;
    for (const property in props) {
      polygon.set(property, props[property]);
    }

    if (layer !== undefined) {
      dispatch(addObjectInLayer({layerId: layer, object: polygon.id})); // Add the object's ID to Redux store
    } else {
      dispatch(addObject(polygon.id)); // Add the object's ID to Redux store
    }

    canvasRef?.current.add(polygon);
    return polygon;
  }, [canvasRef, dispatch]);

  const insertPolyline = useCallback((points, {
    id=undefined,
    layer=undefined,
    ...props
  }) => {
    if (!id) {
      id = uuidv4();
    }

    const polyline = new fabric.Polyline(points);
    polyline.set("id", id);
    polyline.layer = layer;

    for (const property in props) {
      polyline.set(property, props[property]);
    }

    if (layer !== undefined) {
      dispatch(addObjectInLayer({layerId: layer, object: polyline.id})); // Add the object's ID to Redux store
    } else {
      dispatch(addObject(polyline.id)); // Add the object's ID to Redux store
    }

    canvasRef?.current.add(polyline);
    return polyline;
  }, [canvasRef, dispatch]);

  const insertPoint = useCallback((x, y, {
    radius=1,
    id=undefined,
    layer=undefined,
    ...props
  }) => {
    if (!id) {
      id = uuidv4();
    }

    const newPoint = new fabric.Circle({
      left: x,
      top: y,
      originX: "center",
      originY: "center",
      radius: radius,
      fill: fillColor,
      opacity: opacity,
      type: 'point',
    });

    newPoint.set("id", id);
    newPoint.layer = layer;
    for (const property in props) {
      newPoint.set(property, props[property]);
    }

    if (layer !== undefined) {
      dispatch(addObjectInLayer({layerId: layer, object: newPoint.id})); // Add the object's ID to Redux store
    } else {
      dispatch(addObject(newPoint.id)); // Add the object's ID to Redux store
    }

    canvasRef?.current.add(newPoint);
  }, [canvasRef, dispatch, fillColor, opacity]);

  const insertSquarePoint = useCallback((x, y, {
    radius=1,
    id=undefined,
    layer=undefined,
    ...props
  }) => {
    if (!id) {
      id = uuidv4();
    }

    const newPoint = new fabric.Rect({
      left: x,
      top: y,
      originX: "center",
      originY: "center",
      width: radius*2,
      height: radius*2,
      fill: fillColor,
      opacity: opacity,
      type: 'sqpoint',
    });

    newPoint.set("id", id);
    newPoint.layer = layer;
    for (const property in props) {
      newPoint.set(property, props[property]);
    }

    if (layer !== undefined) {
      dispatch(addObjectInLayer({layerId: layer, object: newPoint.id})); // Add the object's ID to Redux store
    } else {
      dispatch(addObject(newPoint.id)); // Add the object's ID to Redux store
    }

    canvasRef?.current.add(newPoint);
    return newPoint;
  }, [canvasRef, dispatch, fillColor, opacity]);

  const insertSvg = useCallback((url, x, y, {
    originX,
    originY,
    scale=undefined,
    id=undefined,
    layer=undefined,
    ...props
  }) => {
    if (!id) {
      id = uuidv4();
    }

    fabric.loadSVGFromURL(
      url,
      function (objects, options) {
        var newSvg = fabric.util.groupSVGElements(objects, options);
        newSvg.set("left", x);
        newSvg.set("top", y);
        newSvg.set("originX", originX);
        newSvg.set("originY", originY);
        newSvg.set("id", id);
        newSvg.set("objectCaching", false);
        newSvg.scaleX = scale !== undefined ? scale : 2 * pointSize / ((newSvg.width + newSvg.height) / 2);
        newSvg.scaleY = scale !== undefined ? scale : 2 * pointSize / ((newSvg.width + newSvg.height) / 2);
        newSvg.type = "svg";
        newSvg.layer = layer;

        setOriginX(x);
        setOriginY(y);
        for (const property in props) {
          newSvg.set(property, props[property]);
        }
        
        canvasRef?.current.add(newSvg);

        onShapeAdded?.call(this, newSvg);
        if (layer !== undefined) {
          dispatch(addObjectInLayer({layerId: layer, object: newSvg.id})); // Add the object's ID to Redux store
        } else {
          dispatch(addObject(newSvg.id)); // Add the object's ID to Redux store
        }
      });
  }, [canvasRef, dispatch, onShapeAdded, pointSize, setOriginX, setOriginY]);

  useEffect(() => {
    const newTooltipText = new fabric.Textbox('', {
      left: 0,
      top: 0,
      fill: "#000000",
      padding: 5,
      fontSize: fontSize,
      perPixelTargetFind: false,
      editable: true,
      selectable: false,
      fixed: true,
      visible: true,
      hasControls: true,
      originX: "center",
      originY: "center",
      width: 180,
      breakWords: true,
      splitByGrapheme :true,
      lockRotation: true,
      id: -1,
      type: "tooltip",
      evented: false
    });
    const newTooltipRect = new fabric.Rect({
      left: 0,
      top: 0,
      fill: "#FFBBFF",
      stroke: "#000000",
      strokeWidth: 2,
      selectable: false,
      fixed: true,
      visible: true,
      hasControls: false,
      originX: "center",
      originY: "bottom",
      width: 180 + 20,
      height: newTooltipText.height + 20,
      rx: 10,
      ry: 10,
      lockRotation: true,
      id: -1,
      objectCaching: false,
      type: "tooltip",
      evented: false
    });
    const newTooltipTriangle = new fabric.Triangle({
      left: 0,
      top: -1,
      fill: "#000000",
      selectable: false,
      fixed: true,
      visible: true,
      hasControls: false,
      originX: "center",
      originY: "bottom",
      angle: 180,
      width: 20,
      height: 20,
      rx: 10,
      ry: 10,
      lockRotation: true,
      id: -1,
      objectCaching: false,
      type: "tooltip",
      evented: false
    });
    const newTooltipTriangle2 = new fabric.Triangle({
      left: 0,
      top: -2*2^.5,
      fill: "#FFBBFF",
      selectable: false,
      fixed: true,
      visible: true,
      hasControls: false,
      originX: "center",
      originY: "bottom",
      angle: 180,
      width: 20,
      height: 20,
      rx: 10,
      ry: 10,
      lockRotation: true,
      id: -1,
      objectCaching: false,
      type: "tooltip",
      evented: false
    });

    var newTooltip = new fabric.Group([newTooltipRect, newTooltipTriangle, newTooltipTriangle2], {
      left: 0,
      top: 0,
      originX: "center",
      originY: "bottom",
      visible: false,
      fixed: true,
      type: "tooltip",
      evented: false
    });

    canvasRef?.current?.add(newTooltip);
    canvasRef?.current?.add(newTooltipText);
    setTooltipShape(newTooltip)
    setTooltip(newTooltipText);

    deleteImg = document.createElement('img');
    deleteImg.src = deleteIcon;
    deleteImg.style.display = "none";
  }, []);

  // Effect to add the drawn shape to Redux store when `shape` changes
  useEffect(() => {
    if (shape) {
      dispatch(addObject(shape.id)); // Add the object's ID to Redux store
    }
  }, [shape]);

  // Function to add a point for polygon and polyline drawing
  const addPoint = useCallback((opt) => {
    const pointer = canvasRef?.current?.getPointer(opt.e);
    let newPoint = { x: pointer?.x ?? 0, y: pointer?.y ?? 0 };
    const points = [...polygonPoints, newPoint]; // Add new point to points array
    setPolygonPoints(points); // Update state with new points

    // Create a new line object for drawing
    const newLine = new fabric.Line([newPoint.x, newPoint.y, newPoint.x, newPoint.y], {
      stroke: strokeColor,
      strokeWidth: strokeWidth,
      fill: fillColor,
      opacity: opacity,
      lockRotation: false,
      selectable: true,
      hasControls: true,
      isForeground: true,
    });
    
    canvasRef?.current?.add(newLine); // Add line to canvas
    setLines([...lines, newLine]); // Update lines state with new line
    newLine.bringToFront();
    canvasRef?.current?.requestRenderAll(); // Render canvas
  }, [canvasRef, fillColor, lines, opacity, polygonPoints, setLines, setPolygonPoints, strokeColor, strokeWidth]);

  useEffect(() => {
    if (!cancelDraw) {
      return;
    }

    if (shapeType === ShapeType.POLYGON || shapeType === ShapeType.POLYLINE) {
      if (polygonPoints.length >= 2) {
        const id = uuidv4();

        const polygon = shapeType === ShapeType.POLYGON
          ? new fabric.Polygon(polygonPoints.map(x => ({x: x.x + strokeWidth/2, y: x.y + strokeWidth/2})), {
            fill: fillColor,
            opacity: opacity,
            stroke: strokeColor,
            strokeWidth: strokeWidth,
            selectable: true,
            hasControls: true,
            lockRotation: false,
            layer: activeLayer.id,
            id,
            objectCaching: false,
          })
          : new fabric.Polyline(polygonPoints.map(x => ({x: x.x + strokeWidth/2, y: x.y + strokeWidth/2})), {
            fill: "#00000000",
            stroke: strokeColor,
            opacity: opacity,
            strokeWidth: strokeWidth,
            selectable: true,
            hasControls: true,
            lockRotation: false,
            layer: activeLayer.layerId,
            id,
            objectCaching: false,
          });
  
        canvasRef?.current?.add(polygon); // Add polygon or polyline to canvas
        
        onShapeAdded?.call(this, polygon);
        canvasRef.current?.setActiveObject(polygon);
        dispatch(addObject(id)); // Add object ID to Redux store
        setPolygonPoints([]); // Clear polygon points
        dispatch(setRequestUngroupActiveGroup(false)); // Reset ungroup request
        if (!drawMultipleShapes) {
          setIsDrawing(false); // Exit drawing mode
        }
        unlockObjects(); // Unlock objects on canvas

        setLines(l => {
          // Reset current shape
          setShape(null);

          for (let line of l) {
            canvasRef?.current?.remove(line); // Remove temporary lines
          }

          if (canvasRef?.current && !drawMultipleShapes) {
            canvasRef.current.defaultCursor = 'default';
            canvasRef.current.hoverCursor = 'default';
          }
          
          return [];
        }); // Clear lines state
      }
    }
    setCancelDraw(false);
  }, [cancelDraw]);

  useEffect(() => {
    if (!isDeleting) {
      setDeleteFilterCallback(undefined);
    }
  }, [isDeleting, setDeleteFilterCallback]);

  const handleMouseDownBefore = useCallback((options) => {
    let evt = options.e;
    if (evt.button === 1) {
        setIsDragging(true);
        setDragPos({
          x: evt.clientX,
          y: evt.clientY
        });
    }
  }, [setDragPos, setIsDragging]);

  // Event handlers for mouse actions on canvas
  const handleMouseDown = useCallback((event) => {
    if (isDeleting) {
      let obj = event.target;
      if (obj !== null && obj !== undefined && !obj.isBackground && obj.deletable !== false) {
        if (deleteFilterCallback === undefined || deleteFilterCallback(obj)) {
          obj.definitiveDelete = true;
          removeObjects([obj.id]);
        }
      }

      return;
    }

    if (event.target && onShapeClicked) {
      onShapeClicked?.call(this, event.target);
    }

    if (isDrawing && canvasRef?.current) {
      const id = uuidv4(); // Generate unique ID for new shape
      const pointer = canvasRef.current.getPointer(event.e);

      // Determine shape type and create corresponding fabric.js object
      if (shapeType === ShapeType.ELLIPSE) {
        const newEllipse = new fabric.Ellipse({
          left: pointer.x,
          top: pointer.y,
          originX: 'center',
          originY: 'center',
          rx: 0,
          ry: 0,
          fill: fillColor,
          opacity: opacity,
          stroke: strokeColor,
          strokeWidth: strokeWidth,
          lockRotation: false,
          selectable: true,
          hasControls: true,
          layer: activeLayer.layerId,
          id,
        });
        canvasRef.current.add(newEllipse);
        setShape(newEllipse);
      } else if (shapeType === ShapeType.RECT || shapeType === ShapeType.SQUARE) {
        setOriginX(pointer.x);
        setOriginY(pointer.y);
        const newRectangle = new fabric.Rect({
          left: pointer.x,
          top: pointer.y,
          originX: 'left',
          originY: 'top',
          width: 0,
          height: 0,
          angle: 0,
          fill: fillColor,
          opacity: opacity,
          stroke: strokeColor,
          strokeWidth: strokeWidth,
          lockRotation: false,
          selectable: true,
          hasControls: true,
          layer: activeLayer.layerId,
          id,
        });

        canvasRef.current.add(newRectangle);
        setShape(newRectangle);
      } else if (shapeType === ShapeType.TRIANGLE) {
        setStartPos({ x: pointer.x, y: pointer.y });
        const newTriangle = new fabric.Triangle({
          left: pointer.x,
          top: pointer.y,
          width: 0,
          height: 0,
          fill: fillColor,
          opacity: opacity,
          stroke: strokeColor,
          strokeWidth: strokeWidth,
          lockRotation: false,
          selectable: true,
          hasControls: true,
          layer: activeLayer.layerId,
          id,
        });
        canvasRef.current.add(newTriangle);
        setShape(newTriangle);
      } else if (shapeType === ShapeType.POLYGON || shapeType === ShapeType.POLYLINE) {
        addPoint(event); // Add initial point for polygon or polyline
        if (polygonPoints.length+1 === maxPoints) {
          setCancelDraw(true);
        }
      } else if (shapeType === ShapeType.CIRCLE) {
        const newCircle = new fabric.Circle({
          left: pointer.x,
          top: pointer.y,
          originX: 'center',
          originY: 'center',
          radius: 0,
          fill: fillColor,
          opacity: opacity,
          stroke: strokeColor,
          strokeWidth: strokeWidth,
          lockRotation: false,
          selectable: true,
          hasControls: true,
          layer: activeLayer.layerId,
          id,
        });
        setOriginX(pointer.x);
        setOriginY(pointer.y);
        canvasRef.current.add(newCircle);
        setShape(newCircle);
      } else if (shapeType === ShapeType.LINE) {
        const newLine = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
          stroke: strokeColor,
          strokeWidth: strokeWidth,
          fill: fillColor,
          opacity: opacity,
          lockRotation: false,
          selectable: true,
          hasControls: true,
          layer: activeLayer.layerId,
          id,
        });
        canvasRef.current.add(newLine);
        setShape(newLine);
      } else if (shapeType === ShapeType.TEXT) {
        const newText = new fabric.Textbox('Your text here', {
          left: pointer.x,
          top: pointer.y,
          fill: textColor,
          fontSize: fontSize,
          editable: true,
          selectable: true,
          hasControls: true,
          lockRotation: false,
          layer: activeLayer.layerId,
          id,
        });

        canvasRef.current.add(newText);
        setShape(newText);
      } else if (shapeType === ShapeType.POINT) {
        const newPoint = new fabric.Circle({
          left: pointer.x,
          top: pointer.y,
          originX: 'center',
          originY: 'center',
          radius: pointSize,
          fill: fillColor,
          opacity: opacity,
          stroke: "#00000000",
          strokeWidth: 0,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          selectable: true,
          hasControls: false,
          layer: activeLayer.layerId,
          id,
          type: 'point',
          objectCaching: false,
        });
        canvasRef.current.add(newPoint);
        onShapeAdded?.call(this, newPoint);
        dispatch(addObject(newPoint.id)); // Add the object's ID to Redux store
        setOriginX(pointer.x);
        setOriginY(pointer.y);
        
      } else if (shapeType === ShapeType.SQUAREPOINT) {
        const newPoint = new fabric.Rect({
          left: pointer.x,
          top: pointer.y,
          originX: 'center',
          originY: 'center',
          width: pointSize*2,
          height: pointSize*2,
          fill: fillColor,
          opacity: opacity,
          stroke: "#00000000",
          strokeWidth: 0,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          selectable: true,
          hasControls: false,
          layer: activeLayer.layerId,
          id,
          type: 'sqpoint',
          objectCaching: false,
        });
        canvasRef.current.add(newPoint);
        onShapeAdded?.call(this, newPoint);
        dispatch(addObject(newPoint.id)); // Add the object's ID to Redux store
        setOriginX(pointer.x);
        setOriginY(pointer.y);
        
      } else if (shapeType === ShapeType.SVG) {
        fabric.loadSVGFromURL(
          svgUrl,
          function (objects, options) {
            var newSvg = fabric.util.groupSVGElements(objects, options);
            newSvg.set("top", pointer.y);
            newSvg.set("left", pointer.x);
            newSvg.set("originX", 'center');
            newSvg.set("originY", 'center');
            newSvg.set("id", id);
            newSvg.set("objectCaching", false);
            newSvg.layer = activeLayer.layerId;
            newSvg.scaleX = 2 * pointSize / ((newSvg.width + newSvg.height)/2);
            newSvg.scaleY = 2 * pointSize / ((newSvg.width + newSvg.height)/2);
            newSvg.type = "svg";

            canvasRef.current.add(newSvg);
            onShapeAdded?.call(this, newSvg);
            dispatch(addObject(newSvg.id)); // Add the object's ID to Redux store
            setOriginX(pointer.x);
            setOriginY(pointer.y);
          });
      } else if (shapeType === ShapeType.IMAGE) {
        const url = imageUrl;
        insertImage(url, pointer.x, pointer.y, {
          originX: 'center',
          originY: 'center',
          id: id,
          layer: activeLayer.layerId
        });
      }
    }

    if (event.target !== null && event.target !== undefined && event.target.tooltipable && tooltip !== null && tooltip !== undefined && isAskTooltip) {
      setIsEditingTooltip(true);
      tooltip.enterEditing();
      tooltip.hiddenTextarea.focus();
      
      canvasRef.current.renderAll(); // Render canvas with updated styles
    }
  }, [activeLayer.layerId, addPoint, canvasRef, dispatch, fillColor, fontSize, imageUrl, insertImage, isAskTooltip, isDeleting, isDrawing, maxPoints, onShapeAdded, onShapeClicked, opacity, pointSize, polygonPoints.length, removeObjects, setCancelDraw, setIsEditingTooltip, setOriginX, setOriginY, setShape, setStartPos, shapeType, strokeColor, strokeWidth, svgUrl, textColor, tooltip, deleteFilterCallback]);

  const handleMouseMoveBefore = useCallback((options) => {
    if (isDragging) {
      var evt = options.e;
      var vpt = canvasRef.current.viewportTransform;
      vpt[4] += (evt.clientX - dragPos.x)/4;
      vpt[5] += (evt.clientY - dragPos.y)/4;
      canvasRef?.current?.renderAll();
      updateDisplayCalibration();
      setDragPos({
        x: evt.clientX,
        y: evt.clientY
      });
    }
  }, [canvasRef, isDragging, setDragPos, updateDisplayCalibration, dragPos.x, dragPos.y]);

  // Handler for mouse move events during drawing
  const handleMouseMove = useCallback((event) => {
    const pointer = canvasRef.current.getPointer(event.e);
    if (isDrawing && canvasRef?.current && (shape || polygonPoints.length > 0)) {

      // Update shape properties based on mouse movement
      if (shapeType === ShapeType.ELLIPSE) {
        const rx = Math.abs(pointer.x - (shape.left ?? 0));
        const ry = Math.abs(pointer.y - (shape.top ?? 0));
        shape.set({ rx, ry });
      } else if (shapeType === ShapeType.RECT || shapeType === ShapeType.SQUARE) {
        // Adjust left and top position based on mouse movement direction
        if (originX > pointer.x) {
          shape.set({ left: pointer.x });
        }
        if (originY > pointer.y) {
          shape.set({ top: pointer.y });
        }

        // Update rectangle dimensions
        shape?.set({
          width: Math.abs(originX - pointer.x),
          height: shapeType !== ShapeType.SQUARE ? Math.abs(originY - pointer.y) : Math.abs(originX - pointer.x),
        });
      } else if (shapeType === ShapeType.TRIANGLE) {
        const width = Math.abs(pointer.x - startPos.x);
        const height = Math.abs(pointer.y - startPos.y);
        shape.set({
          width,
          height,
          left: Math.min(pointer.x, startPos.x),
          top: Math.min(pointer.y, startPos.y),
        });
      } else if (shapeType === ShapeType.LINE) {
        shape.set({ x2: pointer.x, y2: pointer.y });
      } else if (shapeType === ShapeType.CIRCLE) {
        const radius = Math.hypot(pointer.x - originX, pointer.y - originY);
        shape.set({ radius });
      } else if (shapeType === ShapeType.POLYGON || shapeType === ShapeType.POLYLINE) {
        lines[lines.length - 1].set({ x2: pointer.x, y2: pointer.y });
      }

      canvasRef.current.renderAll(); // Render canvas with updated shapes
    }

    if (!isDrawing && tooltip !== null) {
      if (!isEditingTooltip) {
        tooltipShape.left = pointer.x;
        tooltipShape.top = pointer.y;
        tooltip.left = pointer.x;
        tooltip.top = pointer.y - tooltip.height/2 - 20 - 10;
      }
      
      
      if (tooltip.visible) {
        if (currentTooltipObjectId) {
          tooltip.text = getObjectById(currentTooltipObjectId).tooltipMessage;
        }
        
        canvasRef.current.bringToFront(tooltipShape);
        canvasRef.current.bringToFront(tooltip);
        canvasRef.current.renderAll(); // Render canvas with updated styles
      }
    }
  }, [canvasRef, currentTooltipObjectId, getObjectById, isDrawing, isEditingTooltip, lines, originX, originY, polygonPoints.length, shape, shapeType, startPos.x, startPos.y, tooltip, tooltipShape]);

  // Handler for double-click event to finish drawing polygon or polyline
  const handleDoubleClick = useCallback(() => {
    setCancelDraw(true);
  }, [setCancelDraw]);

  const handleMouseUpBefore = useCallback((options) => {
    setIsDragging(false);
  }, [setIsDragging]);
  
  // Handler for mouse up event to finish drawing other shapes
  const handleMouseUp = useCallback(() => {
    if (shape && shapeType !== ShapeType.POLYGON && shapeType !== ShapeType.POLYLINE) {
      dispatch(setRequestUngroupActiveGroup(false)); // Reset ungroup request
      shape.set("objectCaching", false);
      onShapeAdded?.call(this, shape);
      if (!drawMultipleShapes) {
        setIsDrawing(false); // Exit drawing mode
      }
      unlockObjects(); // Unlock objects on canvas
      setShape(null); // Reset current shape
      if (canvasRef?.current && !drawMultipleShapes) {
        canvasRef.current.defaultCursor = 'default'; // Reset cursor to default
        canvasRef.current.hoverCursor = 'default';
      }
    }
  }, [canvasRef, dispatch, drawMultipleShapes, onShapeAdded, setIsDrawing, setShape, shape, shapeType, unlockObjects]);

  const handleMouseWheel = useCallback((opt) => {
    const offsetX = opt.e.offsetX;
    const offsetY = opt.e.offsetY;
    var delta = opt.e.deltaY;
    if (canvasRef?.current) {
      changeZoom(delta, offsetX, offsetY);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    }
  }, [canvasRef, changeZoom]);

  const handleMouseOver = useCallback((event) => {
    if (event.target?.tooltipable) {
      if (tooltip !== null && event.target !== null && event.target !== undefined) {
        setCurrentTooltipObjectId(event.target.id);
        tooltipShape.visible = true;
        tooltip.visible = true;
        let tooltipMessage = event.target.tooltipMessage;
        tooltipMessage = tooltipMessage !== undefined ? tooltipMessage : "";
        tooltip.text = tooltipMessage;
        canvasRef.current.hoverCursor  = 'help';
        canvasRef.current.renderAll(); // Render canvas with updated styles
      }
    }
  }, [canvasRef, setCurrentTooltipObjectId, tooltip, tooltipShape]);

  const handleMouseOut = useCallback((event) => {
    if (tooltip !== null) {
      if (event.target !== null && event.target !== undefined && event.target.tooltipable) {
        setIsAskTooltip(false);
        setIsEditingTooltip(false);

        tooltipShape.visible = false;
        tooltip.visible = false;
        
        tooltip.exitEditing();
        canvasRef.current.hoverCursor  = 'default';
        canvasRef.current.renderAll(); // Render canvas with updated styles
      }
    }
  }, [canvasRef, setIsAskTooltip, setIsEditingTooltip, tooltip, tooltipShape]);

  const setObjectsSelected = useCallback((event) => {
    if (isDeleting) {
      removeObjects(event.selected);
    } else {
      setSelectedShapes(event.selected ?? null);
    }
  }, [isDeleting, removeObjects, setSelectedShapes]);

  // Handler for object selected event to update style settings
  const handleObjectSelected = useCallback((event) => {
    if (!isDeleting) {setObjectsSelected(event);} // Update selected shapes state
    if (event.selected && event.selected.length > 0) {
      if (isDeleting) {
        let selectedObjs = event.selected;
        selectedObjs.forEach(obj => {
          if (obj !== null && obj !== undefined && !obj.isBackground && obj.deletable !== false) {
            if (deleteFilterCallback === undefined || deleteFilterCallback(obj)) {
              obj.definitiveDelete = true;
              removeObjects([obj.id]);
            }
          }
        })
        setSelectedShapes(null)
        return;
      }
      onShapeSelected?.call(this, event.selected);
    }
  }, [deleteFilterCallback, isDeleting, onShapeSelected, removeObjects, setObjectsSelected, setSelectedShapes]);

  const handleObjectUpdated = useCallback((event) => {
    setObjectsSelected(event); // Update selected shapes state

    if (event.selected && event.selected.length > 0) {
      onShapeSelected?.call(this, event.selected);
    }

    if (event.deselected && event.deselected.length > 0) {
      onShapeDeselected?.call(this, event.deselected);
    }
  }, [onShapeDeselected, onShapeSelected, setObjectsSelected]);

  // Handler for selection cleared event to reset selected shapes state
  const handleSelectionCleared = useCallback((event) => {
    if (event.deselected && event.deselected.length > 0) {
      onShapeDeselected?.call(this, event.deselected);
    }

    setSelectedShapes(null); // Clear selected shapes state
  }, [onShapeDeselected, setSelectedShapes]);


  useEffect(() => {
    // Add event listeners for canvas events
    canvasRef?.current?.on('mouse:down:before', handleMouseDownBefore);
    canvasRef?.current?.on('mouse:down', handleMouseDown);
    canvasRef?.current?.on('mouse:move:before', handleMouseMoveBefore);
    canvasRef?.current?.on('mouse:move', handleMouseMove);
    canvasRef?.current?.on('mouse:up:before', handleMouseUpBefore);
    canvasRef?.current?.on('mouse:up', handleMouseUp);
    canvasRef?.current?.on('mouse:wheel', handleMouseWheel);
    canvasRef?.current?.on('selection:created', handleObjectSelected);
    canvasRef?.current?.on('selection:updated', handleObjectUpdated);
    canvasRef?.current?.on('selection:cleared', handleSelectionCleared);
    canvasRef?.current?.on('mouse:dblclick', handleDoubleClick);
    canvasRef?.current?.on('mouse:over', handleMouseOver);
    canvasRef?.current?.on('mouse:out', handleMouseOut);
    canvasRef?.current?.on('text:changed', handleTextChanged);
    canvasRef?.current?.on('object:added', handleObjectAdded);
    canvasRef?.current?.on('object:removed', handleObjectRemoved);
    canvasRef?.current?.on('object:modified', handleObjectModified);


    // Clean up event listeners when component unmounts or dependencies change
    return () => {
      canvasRef?.current?.off('mouse:down:before', handleMouseDownBefore);
      canvasRef?.current?.off('mouse:down', handleMouseDown);
      canvasRef?.current?.off('mouse:move:before', handleMouseMoveBefore);
      canvasRef?.current?.off('mouse:move', handleMouseMove);
      canvasRef?.current?.off('mouse:up:before', handleMouseUpBefore);
      canvasRef?.current?.off('mouse:up', handleMouseUp);
      canvasRef?.current?.off('mouse:wheel', handleMouseWheel);
      canvasRef?.current?.off('selection:created', handleObjectSelected);
      canvasRef?.current?.off('selection:updated', handleObjectUpdated);
      canvasRef?.current?.off('selection:cleared', handleSelectionCleared);
      canvasRef?.current?.off('mouse:dblclick', handleDoubleClick);
      canvasRef?.current?.off('mouse:over', handleMouseOver);
      canvasRef?.current?.off('mouse:out', handleMouseOut);
      canvasRef?.current?.off('text:changed', handleTextChanged);
      canvasRef?.current?.off('object:added', handleObjectAdded);
      canvasRef?.current?.off('object:removed', handleObjectRemoved);
      canvasRef?.current?.off('object:modified', handleObjectModified);
    };
  }, [
    canvasRef,

    isDrawing,
    isDragging,
    isDeleting,
    isAskTooltip,
    isEditingTooltip,

    dragPos,
    originX,
    originY,
    shape,
    lines,
    polygonPoints,
    angle,

    cancelDraw,

    strokeColor,
    strokeWidth,
    fontSize,
    fillColor,
    opacity,
    textColor,
    shapeType,

    displayScale, currentScale, scaleLayerId,

    onShapeClicked,
    handleMouseDown,
    handleMouseDownBefore,
    handleDoubleClick,
    handleMouseMove,
    handleMouseMoveBefore,
    handleMouseOut,
    handleMouseOver,
    handleMouseUp,
    handleMouseUpBefore,
    handleMouseWheel,
    handleObjectAdded,
    handleObjectRemoved,
    handleObjectModified,
    handleObjectSelected,
    handleObjectUpdated,
    handleSelectionCleared,
    handleTextChanged
  ]);

  // Effect to update selected shapes' styles when related state changes
  useEffect(() => {
    if (selectedShapes && canvasRef?.current) {
      selectedShapes.forEach((shape) => {
        if (shape.type === 'point') {
          shape.set('fill', fillColor);
          shape.set('opacity', opacity);
          shape.set('stroke', "#00000000");
          shape.set('strokeWidth', 0);
          shape.set('radius', pointSize);
        }
        else if (shape.type !== 'textbox') {
          if (shape.type !== "image" && shape.type !== "svg") {
            shape.set('stroke', strokeColor);
            shape.set('strokeWidth', strokeWidth);
          }
          if (shape.type === "image" || shape.type === "svg") {
            shape.set('stroke', 0);
            shape.set('strokeWidth', 0);
            shape.set('scaleX', 2 * pointSize / ((shape.width + shape.height)/2));
            shape.set('scaleY', 2 * pointSize / ((shape.width + shape.height)/2));
          }
          if (shape.type !== 'line' && shape.type !== "image" && shape.type !== "svg") {
            shape.set('fill', fillColor);
            shape.set('opacity', opacity);
          }
        } else {
          shape.set('fill', textColor);
          shape.set('fontSize', fontSize);
        }
      });

      canvasRef.current.renderAll(); // Render canvas with updated styles
    }
  }, [styleChanged]);

  useEffect(() => {
    if (removedItems !== undefined && removedItems.length > 0) {
      for (let item of removedItems) {
        onShapeRemoved?.call(this, item);
      }
    }
  }, [onShapeRemoved, removedItems]);

  // Effect to handle keyboard events for deleting selected objects
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete') {
        if (selectedShapes) {
          selectedShapes.forEach(sh => {
            sh.definitiveDelete = true;
          });
          setSelectedShapes(selectedShapes.filter(x => x.type !== "tooltip"));
        }
        removeSelectedObjects(); // Remove selected objects from canvas
        if (tooltipShape) {
          tooltipShape.visible = false;
          tooltip.visible = false;
        }
        canvasRef.current.renderAll(); // Render canvas with updated styles
      }

      if ((event.key === 'Escape') || (event.key === 'Enter')) {
        setCancelDraw(true);
      }
    };

    document.addEventListener('keydown', handleKeyDown); // Add event listener for key down

    // Clean up event listener when component unmounts or dependencies change
    return () => {
      document.removeEventListener('keydown', handleKeyDown); // Remove event listener
    };
  }, [selectedShapes, canvasRef, removeSelectedObjects, tooltipShape, setSelectedShapes, tooltip, setCancelDraw]);


  // Return methods and state variables for drawing shapes
  return { startDrawing, startEditingTooltip, setCancelDraw, changeZoom, zoomBackground, zoomObjects, rotateLeft, rotateRight, insertImage, insertPolygon, insertPolyline, insertPoint, insertSquarePoint, insertSvg, updateDisplayCalibration };
};

export default useDrawShape;

