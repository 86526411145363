// project imports
import { lazy } from "react";
import ProtectedRoute from "../components/login/protected-route";

// project imports
const TextComparison = lazy(() => import("../pages/services/TextComparison/index"));
const BlueprintComparison = lazy(() => import("../pages/services/BlueprintComparison/index"));

const ClauseControl = lazy(() =>
  import("../pages/services/ClauseControl")
);
const ClauseControlAnalysis = lazy(() =>
  import("../pages/services/ClauseControl/analysis")
);
const ClauseControlClauses = lazy(() =>
  import("../pages/services/ClauseControl/clauses")
);

const FileAnalysisRoutes = {
  path: "/",
  element: "",
  children: [
    {
      path: "/comparaison",
      element: "",
      children: [
        {
          path: "/comparaison/text",
          element:(
            <ProtectedRoute roles={["baseauth", "Plateforme.Service12", "Plateforme.FullAccess"]}>
              <TextComparison />
            </ProtectedRoute>
          )
        },
        {
          path: "/comparaison/blueprint",
          element: (
            <ProtectedRoute roles={["baseauth", "Plateforme.Service2", "Plateforme.FullAccess"]}>
              <BlueprintComparison />
            </ProtectedRoute>
          )
        }
      ]
    },
    {
      path: "/ClauseControl",
      element: (
        <ProtectedRoute roles={["baseauth", "Plateforme.Service18", "Plateforme.FullAccess"]}>
          <ClauseControl />
        </ProtectedRoute>
      ),
    },
    {
      path: "/ClauseControl/analyses",
      element: (
        <ProtectedRoute roles={["baseauth", "Plateforme.Service18", "Plateforme.FullAccess"]}>
          <ClauseControlAnalysis />
        </ProtectedRoute>
      ),
    },
    {
      path: "/ClauseControl/clauses",
      element: (
        <ProtectedRoute roles={["baseauth", "Plateforme.Service18", "Plateforme.FullAccess"]}>
          <ClauseControlClauses />
        </ProtectedRoute>
      ),
    },
  ],
};
export default FileAnalysisRoutes;
