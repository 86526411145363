import { createSlice } from "@reduxjs/toolkit";

const ProjectInfoSlice = createSlice({
  name: "project_infos",
  initialState: {
    project_id:"",
    project_path:"",
    project_name: "",
    creator_name: "",
    creation_date: "",
    modification_date: "",
    project_files: []
  },
  reducers: {
    setProjectInfos: (state, action) => {
      const { project_id, project_path, project_name, creator_name, creation_date, modification_date, project_files } = action.payload;
      state.project_id = project_id;
      state.project_path = project_path;
      state.project_name = project_name;
      state.creator_name = creator_name;
      state.creation_date = creation_date;
      state.project_files = Object.entries(project_files).map((x) => ({name: x[0], ...x[1]}));
      state.modification_date = modification_date;
    },
    addProjectFile: (state, action) => {
      const project_file = action.payload;
      if (state.project_files === undefined) {
        state.project_files = [project_file];
      } else {
        state.project_files.push(project_file);
      }
    },
    changeStatus: (state, action) => {
      const {filename, status, pages} = action.payload;
      let project_file = state.project_files.find((pf) => pf.name === filename);
      if (project_file) {
        project_file.pages = pages;
        project_file.status = status;
      }
    },
    changeSegmentationStatus: (state, action) => {
      const {filename, segmentationStatus} = action.payload;
      let project_file = state.project_files.find((pf) => pf.name === filename);
      if (project_file) {
        project_file.segmentationStatus = segmentationStatus;
      }
    },
    deleteFile: (state, action) => {
      const filename = action.payload;
      state.project_files = state.project_files.filter((pf) => pf.name !== filename);
    },
    setCurrentFile: (state, action) => {
      const filename = action.payload;
      state.project_files.forEach(x => x.is_current = false);
      let project_file = state.project_files.find((pf) => pf.name === filename);
      if (project_file) {
        project_file.is_current = true;
      }
    },
    setCurrentSelectedPages: (state, action) => {
      const {filename, selectedPages } = action.payload;
      let project_file = state.project_files.find((pf) => pf.name === filename);
      if (project_file) {
        for (let i=0;i<project_file.pages.length;i++) {
          project_file.pages[i].selected = selectedPages.includes(i);
        }
      }
    },
    setSasPdf: (state, action) => {
      const {filename, url, previews } = action.payload;
      let project_file = state.project_files.find((pf) => pf.name === filename);
      if (project_file) {
        project_file.url_pdf = url;
        project_file.previews = previews;
      }
    }
  },
});

export const { setProjectInfos, addProjectFile, changeStatus, changeSegmentationStatus, setSasPdf, deleteFile, setCurrentFile, setCurrentSelectedPages } = ProjectInfoSlice.actions;
export default ProjectInfoSlice.reducer;
